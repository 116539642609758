import axios from 'axios';
import React,{useState, useEffect} from 'react'
import {useParams,useNavigate, Link} from 'react-router-dom';
import MaterialTable from '@material-table/core'


function ParentInfo() {
    const { id } = useParams();
    const [studentData, setStudentData] = useState(null);
    const [studNotify, setStudNotify] = useState();
    const [noStud, setNoStud] = useState();
    const [wallet, setWallet] = useState(0);
    const [totalExp, setTotalExp] = useState(0);
    const [parentName,setParentName] = useState('');
    const [parentEmail,setParentEmail] = useState('');
    const [parentPhone,setParentPhone] = useState('');
    const [parentID,setParentID] = useState('');
    const [parentAddress,setParentAddress] = useState('');
    const [regDate, setRegDate] = useState('');
    const history = useNavigate();
    const [walletData, setWalletData] = useState(null);
    const [walletNotify, setWalletNotify] = useState(null);
    const [load, setLoad] = useState(false);
    const [fund, setFund] = useState('');
    const [notifund, setNotifund] = useState(null)
    const [susLoad, setSusLoad] = useState(false);
    const [susNote, setSusNote] = useState(null);
    const [susState, setSusState] = useState();
    const [studentInfo, setStudentInfo] = useState(false);
    const [mregion, setMRegion] = useState(null);
    const [loadRegion, setLoadRegion] = useState();

     const [fname, setFname] = useState('');
     const [lname, setLname] = useState('');
     const [phone, setPhone] = useState('');
     const [email, setEmail] = useState('');
     const [address, setAddress] = useState('');
     const [studID, setStudentID] = useState('');

     const [region, setRegion] = useState('');
     const [race, setRace] = useState('');
     const [gender, setGender] = useState('');
     const [birth, setBirth] = useState('');
     const [age, setAge] = useState('');
     const [hlang, setHlang] = useState('');
     const [otherLang, setOtherLang] = useState('');
     const [langInstruct, setLangInstruct] = useState('');
     const [academicYear, setAcademicYear] = useState('');

     const [notiStud, setNotiStud] = useState(null);
     const [uParent, setUParent] = useState(null);
     const [repNotis, setRepNotis] = useState();
     const [tass, setTass] = useState(null);
     const [fundType, setFundType] = useState('');
     const [reason, setReason] = useState('');






     const updatePassword = () =>{
            axios.post('/update-parent-passdata',{id:id},{
              headers: { 'Content-Type': 'application/json'} 
            }).then((res)=>{
                 setTass('tap')
                if(res.data.status === false){
                     setRepNotis(res.data.message)
                      
                }else{
                  setRepNotis(res.data.message)
                }
            })   
     }

     const updateParentInfoData = () =>{
               if(parentAddress === '' || parentEmail === '' || parentID === '' || parentPhone === '' || parentName===''){
                      setUParent(<div className='alert alert-info text-center'>make sure all form field are fill correctly</div>);
               }else
               {
                  let dataDet = { address:parentAddress,email:parentEmail,pID:parentID,phone:parentPhone,name:parentName,userid:id,utype:'parent'}
                   axios.put('/update-parent-data-details',dataDet,{
                    headers: { 'Content-Type': 'application/json'} 
                   }).then((res)=>{
                    setUParent(<div className='alert alert-info text-center'>{res.data.ustatus}</div>);
                   })
               }
     }


    const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }


      const addStudentData = () =>{
          if(fname === '' || lname === '' || phone === '' || email === '' || address === '' || studID===''
          || region==='' || race === '' || gender ==='' || birth === '' 
          || age==='' || hlang==='' || otherLang ==='' || langInstruct === '' || academicYear===''){
                  setNotiStud(<div className="alert alert-info text-center">Please all student information must be fill</div>)
          }else
          {

             setLoad(true)
                setNotiStud(null)
           const data = {
                 fname:fname, lname:lname, 
                 phone:phone, email:email, 
                 address:address, studID:studID,
                 region:region, race:race, 
                 gender:gender, birth:birth,
                 age:age, hlang:hlang, otherLang:otherLang,
                 langInstruct:langInstruct,academicYear:academicYear,
                 userid:id
           }

           axios.post('/add-student-admin',data,{
            headers: { 'Content-Type': 'application/json'} 
           }).then((response)=>{
                  setTimeout(()=>{
                    setLoad(false)

                    if(response.data.status === false){
                      setNotiStud(<div className="alert alert-info text-center">Error occur when processing your data</div>)
       
                     }else
                     {
                      setNotiStud(<div className="alert alert-info text-center">{ response.data.status }</div>)
                      window.location.reload();
                     }

                  },2000)
                  
           })




          }


      }


      const addFundWallet = () =>{
            
        const funder ={
          fund:fund,
          current_amount:wallet,
          userid:id,
          reason:reason,
          fundType:fundType
      }
           if(fundType === ''){
            setNotifund(<div className="alert alert-info text-center">Select Option of Operation</div>);
           }else{

              switch(fundType){
                  case'fund':
                  if(fund ===''){
                    setNotifund(<div className="alert alert-info text-center">Amount field is required</div>);
               }else{
                setLoad(true);
                setNotifund(null)
            axios.post('/admin-addfund',funder,{
              headers: { 'Content-Type': 'application/json'}   
          }).then((res)=>{
                   setTimeout(()=>{
                      setLoad(false);
                      setNotifund(<div className="alert alert-info text-center">{res.data.message}</div>);
                        if(res.data.total === false){

                        }else{
                              setWallet(res.data.total);
                        }
                   },2000);
                  
          })
                    
               }
                  break;
                  case 'Deduct':

                    if(fund === '' || reason === ''){
                      setNotifund(<div className="alert alert-info text-center">Make all form input are not empty</div>);
                    }else
                    {
                      setLoad(true);
                      setNotifund(null)

                      axios.post('/admin-addfund',funder,{
                        headers: { 'Content-Type': 'application/json'}   
                    }).then((res)=>{
                             setTimeout(()=>{
                                setLoad(false);
                                setNotifund(<div className="alert alert-info text-center">{res.data.message}</div>);
                                  if(res.data.total === false){
          
                                  }else{
                                        setWallet(res.data.total);
                                  }
                             },2000);
                            
                    })
                         
                    }
                    break;
              }

           }
      }

      const SuspendAcct = (status) =>{
                    setSusState(status);
                    setSusLoad(true);
                const spend = {
                     status:status,
                     parentID:id
                }

                axios.post('/suspend-users',spend,{
                    headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{
                       setTimeout(()=>{
                        setSusLoad(false);
                                if(res.data.motify === false){
                                    setSusNote( <div className="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show" role="alert">
                                    
                                  Server error occur when trying to perform your request
                                </div>)
                                }else{
                                    setSusNote( <div className="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show" role="alert">
                                    
                                    { status === 1 ? 'Suspension of this account was successful' : 
                                    'Suspension on this account was remove successfully' }
                                </div>)
                                }


                              
                       },2000);
                })
      }

      const fetchRegion = () =>{
        axios.get('/all-region',{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response)=>{
             if(response.data.allRegion === false){
                setMRegion(<option disabled>No data available</option>)
             }else{
               
              setLoadRegion(response.data.allRegion)
             }
        })
    } 


    const removeStudentData = (studID) =>{
          axios.post('/admindelete-studentdata',{
              studID:studID
          },{
            headers: { 'Content-Type': 'application/json'}
          }).then((res)=>{
                if(res.data.status === false){
                     alert('Unable to remove student at this moment');
                }else
                {
                    alert('Student was removed successfully')
                }
          })
    }
    

    useEffect(()=>{

          fetchRegion();

         axios.get('/get-parentdata-walletdata/'+id,{
            headers: { 'Content-Type': 'application/json'}
         }).then((res)=>{
                if(res.data.studData === false){
                    setStudNotify(<div className="alert alert-info text-center">No registered student</div>);
                     setStudentData(null)
                }else
                {
                     setStudentData(res.data.studData);
                }
                    setNoStud(res.data.studentNo);

                    setWallet(res.data.parentData[0].wallet);

                    setParentName(res.data.parentData[0].fnames);
                    setParentEmail(res.data.parentData[0].email);
                    setParentPhone(res.data.parentData[0].phone);
                    setParentID(res.data.parentData[0].id_number);
                    setParentAddress(res.data.parentData[0].address);
                    setParentAddress(res.data.parentData[0].address);
                    setRegDate(res.data.parentData[0].date_reg);

                    if(res.data.walletData === false){
                         setWalletNotify(<div className="alert alert-info text-center">No transaction has occur</div>)
                          setWalletData(null)
                    }else
                    {
                          setWalletData(res.data.walletData)
                    }
                    
                    setSusState(res.data.generalUser[0].status);
                  

                       if(res.data.walletData === false){

                        setTotalExp(0);

                       }else{

                    let resp = res.data.walletData;
                    
                    let total_exp = 0;
                     resp.map((amt)=>(
                             
                            total_exp = parseInt(total_exp) + parseInt(amt.amount)
                     ));

                       setTotalExp(total_exp);

                     }
                        



         })  
    },[])

    const passwordReset = () =>{
         return (
 
          <div id="password-reset" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="modal-dialog modal-sm">
                  <div class="modal-content modal-filled bg-danger">
                      <div class="modal-body p-4">
                          
                          <div class="text-center">
                              <i class="dripicons-wrong h1 text-white"></i>

                              <h4 class="mt-2 text-white">Oh snap!</h4>
                              
                              <p class="mt-3 text-white">
                                { tass === null ?
                                <div>
                                You are about to reset the password of this user account profile.
                               New password will be sent to this user login email address
                                </div>
                                : <div> { repNotis } </div> }
                            
                              </p> 

                              <button type="button" class="btn btn-light btn-sm waves-effect waves-light" onClick={()=> updatePassword()} >Comfirm Reset</button>

                              <button type="button" className="btn btn-danger waves-effect btn-sm waves-light" data-dismiss="modal">  Cancel</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


        
         )
    }


    const UserInfoEdit = () =>{
         return (

      <div className="modal fade" id="editUserInfo" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myCenterModalLabel">Profile Update</h4>
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div className="modal-body p-4">
         { uParent === null ? null : uParent }
       
        <div className="row">
         
          <div className="col-md-12">
            <div className="form-group no-margin">
              <label htmlFor="field-7" className="control-label">Full-Name</label><br></br>
              <input type="text" className="form-control" id="field-7" value={parentName} onChange={(e)=>setParentName(e.target.value)}  placeholder="fullname"  />
            </div>
          </div>


          <div className="col-md-6">
            <div className="form-group no-margin">
              <label htmlFor="field-7" className="control-label">Email</label><br></br>
              <input type="email" className="form-control"  id="field-7" value={parentEmail} onChange={(e)=>setParentEmail(e.target.value)} placeholder="Email"  />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group no-margin">
              <label htmlFor="field-7" className="control-label">Phone</label><br></br>
              <input type="number" className="form-control" id="field-7" value={parentPhone} onChange={(e)=>setParentPhone(e.target.value)} placeholder="Phone"  />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group no-margin">
              <label htmlFor="field-7" className="control-label">ID-Number</label><br></br>
              <input type="number" className="form-control" id="field-7" value={parentID} onChange={(e)=>setParentID(e.target.value)} placeholder="ID-Number"  />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group no-margin">
              <label htmlFor="field-7" className="control-label">Address</label><br></br>
              <input type="text" className="form-control" id="field-7" value={parentAddress} onChange={(e)=>setParentAddress(e.target.value)} placeholder="Address"  />
            </div>
          </div>


        </div>
      

      
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
       
        
        <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> updateParentInfoData()} >Submit</button>
  


      </div>
        </div>{/* /.modal-content */}
      </div>{/* /.modal-dialog */}
    </div>
         )
    }



    return (
        <div id="wrapper">
        <div className="content-page">
    <div className="content">
      {/* Start Content*/}
      <div className="container-fluid">

      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                  <button type="button" className="btn btn-warning waves-effect btn-sm waves-light" data-toggle="modal" data-target="#addStudent" data-backdrop="static"> Add Student</button>
                     &nbsp;&nbsp;

                     <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centermodal" data-backdrop="static"> <i className="mdi mdi-wallet-plus" /> Fund / Deduct From Wallet</button>
                     &nbsp;&nbsp;
                        { susLoad === false ? 
                         susState === null ?
                     <button type="button" className="btn btn-danger waves-effect btn-sm waves-light" onClick={()=>SuspendAcct(1)}>  Suspend Account</button>
                     :
                     <button type="button" className="btn btn-success waves-effect btn-sm waves-light" onClick={()=>SuspendAcct(null)}>  Unsuspend Account</button>
                  
                                :
                     <button className="btn btn-danger waves-effect btn-sm waves-light" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Please wait...
                                                    </button> 
}
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Parent Dashboard</h4>
          </div>
        </div>
      </div>

            { susNote === null ? null : 
     
                                       susNote 
                                   
}

      <div className="row">
          
        <div className="col-md-6 col-xl-4">
          <div className="card-box">
            <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title data-original-title="More Info" />
            <h4 className="mt-0 font-16">Wallet</h4>
            <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ wallet === null ? 'R0.00' : convertCurrency(parseInt(wallet)) }</span></h2>
            </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="card-box">
            <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title data-original-title="More Info" />
            <h4 className="mt-0 font-16">Total Transaction</h4>
            <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ totalExp === null ? 'R0.00' : convertCurrency(parseInt(totalExp)) }</span></h2>
           </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="card-box">
            <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title data-original-title="More Info" />
            <h4 className="mt-0 font-16">Total Student</h4>
            <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ noStud }</span></h2>
           </div>
        </div>
     
      </div>
            


      <div className="row">
        
      <div className="col-4">
        <div className="card-box">
          <div className="media mb-3">
          
            <div className="media-body">

         
              <h4 className="mt-0 mb-1 text-uppercase">{ parentName }</h4>
              <p className="text-muted">ID No.# { parentID }</p>
              <p className="text-muted"></p>
            
            
            </div>
          </div>
          <h5 className="mb-3 mt-4 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1" /> Personal Information</h5>
          <div>
            
            <h4 className="font-13 text-muted text-uppercase mb-1">Email :</h4>
            <p className="mb-3"> { parentEmail }</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Phone :</h4>
            <p className="mb-3">{ parentPhone }</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Address :</h4>
            <p className="mb-3"> { parentAddress }</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Date Registered:</h4>
            <p className="mb-0"> { regDate }</p>
          </div>
          <br></br> <br></br>
          <div>
          <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#editUserInfo" data-backdrop="static"> Edit Information</button>&nbsp;&nbsp;&nbsp;
            <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#password-reset"> Reset user Password</button>
           
          </div>
           
        </div> {/* end card-box*/}
      </div>
        <div className="col-8">
          {/* Portlet card */}
          
            
           
                    { studentData === null ? studNotify :


studentData && <MaterialTable
            title="Registered Student"
            columns={[
              { title: 'First Name', field: 'firstname' },
              { title: 'Last Name', field: 'lastname' },
              { title: 'Grade', field: 'grade' },
              { title: 'Race', field: 'student_race' },
              { title: 'Gender', field: 'gender' }
              
            ]}
            data= {
              studentData
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => history('/student-data/details/'+rowData.student_id+'/'+id)
              },
              rowData => ({
                icon: 'delete',
                tooltip: 'Delete student',
                onClick: (event, rowData) => removeStudentData(rowData.student_id),
               
              })
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />


}
             
            
             
             
      
        </div> {/* end col*/}
      </div>




      <div className="row">
        <div className="col-12">
          {/* Portlet card */}
          <div className="card">
            <div className="card-body">
              <div className="card-widgets">
                <Link to="#" data-toggle="reload"><i className="mdi mdi-refresh" /></Link>
               
              </div>
              <h4 className="header-title mb-0">Wallet Activity</h4>
              <div id="cardCollpase4" className="collapse pt-3 show">

                <div className="table-responsive">
                     { walletData === null ? walletNotify : 
                  <table className="table table-centered table-borderless mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Amount</th>
                        <th>Memo</th>
                        <th>Date</th>
                        <th>Ref</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                            { walletData && walletData.map(({amount,details,date_trans,ref},index)=>{
                                 return(
                                    <tr key={index}>
                                        <td>{1+index}</td>
                                    <td>{ convertCurrency(parseInt(amount)) }</td>
                                    <td>{ details }</td>
                                    <td>{date_trans}</td>
                                  
                                    <td>{ref}</td>
                                  </tr>
                                 )
                            })}
                     
                      </tbody>
                  </table>
}
                </div> {/* .table-responsive */}
              </div> {/* end collapse*/}
            </div> {/* end card-body*/}
          </div> {/* end card*/}
        </div> {/* end col*/}
      </div>



      <div className="modal fade" id="addStudent" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Add Student</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">
            { notiStud === null ? null : notiStud }


          
            <div className="studentInfo">
         
          <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">First Name</label><br></br>
                
                <input type="text" className="form-control" id="field-7" placeholder="First Name"  onChange={(e)=>setFname(e.target.value)}  />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Last Name</label><br></br>
                
                <input type="text" className="form-control" id="field-7" placeholder="Last Name" onChange={(e)=>setLname(e.target.value)}  />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Email</label><br></br>
                
                <input type="email" className="form-control" id="field-7" placeholder="Email" onChange={(e)=>setEmail(e.target.value)}  />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Phone</label><br></br>
                
                <input type="tel" className="form-control" id="field-7" placeholder="Phone" onChange={(e)=>setPhone(e.target.value)}  />
              </div>
            </div>
          </div>



          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Student ID / Passport ID </label><br></br>
                
                <input type="number" className="form-control" id="field-7" placeholder="Student ID" onChange={(e)=>setStudentID(e.target.value)}  />
              </div>
            </div>

           
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Address </label><br></br>
                
                <textarea className="form-control" id="field-7" onChange={(e)=> setAddress(e.target.value)} placeholder="Address"   />
              </div>
            </div>

           
          </div>
        
          </div>
             
             
              <div className="studentData">

        <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Region</label><br></br>
                
                <select className="custom-select " id="status-select" onChange={(e)=> setRegion(e.target.value)}>
                { mregion !== null ? mregion :
                        loadRegion && loadRegion.map(yu =>(
                          <option key={yu.id} defaultValue={yu.location}>{ yu.location }</option>
                        ))
                    }
                  </select>

              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Race</label><br></br>
                <select className="custom-select " id="status-select" onChange={(e)=> setRace(e.target.value)}>
                <option value="">Select Race</option>
                              <option value="Black African">Black African	</option>
                              <option value="Colored">Colored</option>
                              <option value="White">White</option>
                              <option value="Indian/Asian">Indian/Asian</option>
                  </select>
               
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Gender</label><br></br>
                
                <select className="custom-select " id="status-select" onChange={(e)=> setGender(e.target.value)}>
                <option value="">Gender</option>
                              <option value="Male">Male	</option>
                              <option value="Female">Female</option>
                           
                  </select>
             
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Date of Birth</label><br></br>
                
                <input type="date" className="form-control" id="field-7" placeholder="Amount" onChange={(e)=>setBirth(e.target.value)} />
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Age</label><br></br>
                
                <input type="number" className="form-control" id="field-7" placeholder="Amount"  onChange={(e)=>setAge(e.target.value)}  />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Home Language</label><br></br>
                
                <input type="text" className="form-control" id="field-7" placeholder="Amount"  onChange={(e)=>setHlang(e.target.value)}  />
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Other Language</label><br></br>
                
                <input type="text" className="form-control" id="field-7" placeholder="" onChange={(e)=>setOtherLang(e.target.value)}  />
             
             
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Language Instruction</label><br></br>
                
                <select className="custom-select " id="status-select" onChange={(e)=> setLangInstruct(e.target.value)}>
               
                <option value="">Select Language</option>
                              <option value="Afrikaans">Afrikaans</option>
                              <option value="English">English</option>
                           
                  </select>
             
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Academic Year</label><br></br>
                
                <select className="custom-select " id="status-select" onChange={(e)=> setAcademicYear(e.target.value)}>
                    <option value="">Select Academic Year</option>
                              <option value={2021}>2021</option>
                              <option value={2022}>2022</option>
                              <option value={2023}>2023</option>
                              <option value={2024}>2024</option>
                              <option value={2025}>2025</option>
                              <option value={2026}>2026</option>
                              <option value={2027}>2027</option>
                              <option value={2028}>2028</option>
                              <option value={2029}>2029</option>
                              <option value={2030}>2030</option>
                           
                  </select>
             
              </div>
            </div>

       
          </div>



                </div>

        
        </div>

        
        <div className="modal-footer">

 
           { load === false ?
          <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> addStudentData()} >Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> 
}

        </div>


          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}



      <div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Fund Wallet or Deduct From Wallet</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">
            { notifund === null ? null : notifund }
         
          <div className="row">

          <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">I want to </label><br></br>
              
                <select className="custom-select" id="status-select" onChange={(e)=>setFundType(e.target.value)}>
                    <option value="">Option</option>
                              <option value="fund">Fund Wallet</option>
                              <option value="Deduct">Deduct From Wallet</option>
                           
                  </select>
              </div>
            </div>
            
              { fundType === '' ? null :
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Amount</label><br></br>
                <small>Enter the amount without adding dot example 2000, 3000</small>
                <input type="number" className="form-control" id="field-7" placeholder="Amount" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() } onChange={(e)=>setFund(e.target.value)}  />
              </div>
            </div>
              }

              { fundType === 'Deduct' ?

            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Reason </label><br></br>
                
                <textarea className="form-control" id="field-7" onChange={(e)=>setReason(e.target.value)} placeholder="Address"   />
              </div>
            </div>
            : null

            }


          </div>
        
  
        
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
         
           { load === false ?
          <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> addFundWallet()} >Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> 
}

        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


      { passwordReset() }

      { UserInfoEdit() }


        </div>
        </div>
        </div>
        </div>
    )
}

export default ParentInfo
