import React,{useState, useEffect} from 'react'
import axios from 'axios'
import {useParams, Link, withRouter} from 'react-router-dom'


function StudentInfo() {
  const currencyFormat = (num) => {
    return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }
     const { id, user } = useParams();
     const [studentNames, setStudentNames] = useState();
     const [studentAge, setStudentAge] = useState('');
     const [studentDob, setStudentDob] = useState('');
     const [studentRace, setStudentRace] = useState('');
     const [studentID, setStudentID] = useState('');
     const [studentPhone, setStudentPhone] = useState('');
     const [studentOtherLang, setStudentOtherLang] = useState('');
     const [studentGender, setStudentgender] = useState('');
     const [studentGrade, setStudentGrade] = useState('');
     const [langInstruct, setLangInstruct] = useState('');
     const [yearApplying, setYearApply] = useState('');
     const [billCycle, setBillCycle] = useState();
     const [studentRegion, setStudentRegion] = useState('');
     const [parentName, setParentName] = useState();
     const [studentEmail, setStudentEmail] = useState('');
      const [studentHomeAddress, setStudentHomeAddress] = useState('');
      const [studentHomeLang, setStudentHomeLang] = useState('');
      const [subjectData, setSubjectData] = useState(null);
      const [notify, setNotify] = useState(null);
      const [subNotify, setSubNotify] = useState(null);
      const [subjectActivity, setSubjectActivity] = useState(null);
      const [generalSubject,setGeneralSubject] = useState();
      const [noti, setNoti] = useState(null);
      const [offsubject, setOffSubject] = useState(null);
      const [loadam, setLoadAm] = useState(false);
      const [notSub, setNotSub] = useState(null);
      const [notes, setNotes] = useState();

      const [editinfo, setEditInfo] = useState(false);

      const [changeSub, setChangeSub] = useState(null);
      const [subjectIDData, setSubjectIDData] = useState();
      const [subjectChange, setSubjectChange] = useState();

      const [parentID, setParentID] = useState();
      const [removeStudentStatus, setRemoveStudentStatus] = useState(false);


      const [schooloption, setSchoolOption] = useState('');

    const [total, setTotal] = useState(0);
    const [duration, setDuration] = useState('');
 
 

    const [durationOption, setDurationOption] = useState(false);
    const [listofCurrentSubject, setListofCurrentSubject] = useState();

    const [subjectStatus, setSubjectStatus] = useState(null);
    const [msybjectStatus, setMySubjectStatus] = useState(false);
    const [overallTotal, setOverallTotal] = useState(0);
     const [isChecked, setIsChecked] = useState(false);
     const [amtSub, setAmtSub] = useState(0);
     const [totalsubTerm, setTotalSubTerm] = useState(0);

     const [moreSubject, setMoreSubject] = useState(false);
     const [displaymoreSubject, setDisplayMoreSubject] = useState(false);
     const [sub_note, setSub_Note] = useState(null);
     const [mregion, setMRegion] = useState(null);
     const [loadRegion, setLoadRegion] = useState();
     const [fname, setFname] = useState('');
     const [lname, setLname] = useState('');
     const [tutorName, setTutorName] = useState();
      const [noteCentre, setNoteCentre] = useState(null);
      const [centreData, setCentreData] = useState(null);

    
      const [tutnote, setTutNote] = useState(false);
     const [editNotify, setEditNotify] = useState(null);



      const [addingSub, setAddingSubject] = useState(false);

      const [statusM, setStatusM] = useState(false);
      const [loaderStatus, setLoaderStatus] = useState(false)
      const [centreID, setCentreID] = useState();

      const [addingState, setAddingState] = useState();
      const [cid, setCid] = useState('');

       

    const  updateStudentInfo = () =>{
      if(fname === '' || lname ==='' || studentID ==='' || studentGender ===''
      || studentDob === '' || studentAge ==='' || studentEmail==='' || studentGrade==='' 
      || studentPhone ==='' || studentRegion === '' || studentHomeAddress ==='' || studentHomeLang ===''
      || studentOtherLang ==='' || studentRace === '' || langInstruct === '' || yearApplying === ''){
              setEditNotify(<div className="alert alert-info text-center">Check if all form field are fill </div>)
      }else
      {
             const Data = {
              editFname:fname,editLname:lname, editstudID:studentID,
              editGender:studentGender,editDOB:studentDob,editAge:studentAge,editEmail:studentEmail,
              editGrade:studentGrade, editPhone:studentPhone, editRegion:studentRegion, 
              editAddress:studentHomeAddress, editHomeLang:studentHomeLang,
              editOtherLang:studentOtherLang,
              editRace:studentRace,editInstruct:langInstruct,editYearApply:yearApplying, studID:id
             }

             axios.post('/update-admin-studentinfo',Data,{
              headers: { 'Content-Type': 'application/json'}
             }).then((res)=>{
                    setEditNotify(<div className="alert alert-info text-center">{ res.data.studUp }</div>);
             })
      }
    }

      const handleBackSubject = ()=>{
        setStatusM(false);
      }

      const gradeData = [
      
        {grade:'R',id:1},
       {grade:'1',id:3},
       {grade:'2',id:4},
       {grade:'3',id:5},
       {grade:'4',id:6},
       {grade:'5',id:7},
       {grade:'6',id:8},
       {grade:'7',id:9},
       {grade:'8',id:10},
       {grade:'9',id:11},
       {grade:'10',id:12},
       {grade:'11',id:13},
       {grade:'12',id:14}   
 ];
 


     


        const checkSelectedGrade = (e) =>{
          setSchoolOption(e.target.value);
          setDuration('');
          setTotal(0)
          setMySubjectStatus(false)
          let gradeData = e.target.value;
          if(gradeData === ''){
            setDurationOption(false);
          }else{
            setDurationOption(true);
          }
        

        }

        const removeStudent = () => {
          setRemoveStudentStatus(true)
        axios.post('/remove-student-center',{studID:id},{
          headers: {'Content-Type': 'application/json'}
        }).then((resp)=>{
             setTimeout(()=>{
              setRemoveStudentStatus(false)
                if(resp.data.mstatus === 'allow'){
                   window.location ="";
                }
             },500)
        }).catch(err=>{
           console.log(err);
        })
    }


        const handlePrice = (e) =>{
          setTotal(0)
          setDuration(e.target.value);
             let dura = e.target.value;
              console.log(dura)
          axios.post("/school-price",
          { userduration:dura,
            schooloption:schooloption,
            lang:langInstruct
          
          },{
            headers: { 'Content-Type': 'application/json'}
         })
          .then((response)=>{
              
                if(response.data.priceGrade === false){
                  setTotal(0);
                }else{
                     
                      setTotal(response.data.priceGrade);
                }

                if(response.data.msubject === false){
                     setSubjectStatus('No subject for the selected grade');
                     setMySubjectStatus(true)
                }else{
                     setSubjectData(response.data.msubject);
                     setMySubjectStatus(true);
                     
                  
                         
                }


          })
        


  }



 

      const handleActivity = (studentID,subjectID,subject) =>{
                          
                        setStatusM(true);
                        setGeneralSubject(subject)
                   
                axios.post('/student-get-aactivity',{
                  studentID:studentID,
                  subjectID:subjectID
              },{
                    headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{
                          
                        if(res.data.assessData === false){
                                setSubNotify(<div className="alert alert-info text-info">No activity found for {subject}</div>);
                                setSubjectActivity(null)
                        }else
                        {
                            setSubjectActivity(res.data.assessData)
                        }
                        
                })
      }


    const handleAddMoreSubject = (e,price) => {
           
            const checked = e.target.checked;
            const value = e.target.value;
            const subject = e.target.name;
              let subLink;
          
              if(checked){
                  subLink = 'add';
               
              }else
              {
                  subLink='remove';
                
              }
                

            axios.post('handle-more-subject',{
                  subjectID:value,
                   status:subLink,
                   grade:studentGrade,
                   studID:id,
                   parentID:parentID,
                   subject:subject,
                   price:price
              },
              {
                headers: { 'Content-Type': 'application/json'}
              }
              ).then((res)=>{
                setSub_Note(<div className="alert alert-info text-center">{ res.data.subStatus} </div>);
              })   
    }



    const handleSubjectCheck = (subjectID,price,subject) =>{
      setIsChecked(true)
       
          let mtotal;
           if(price === ''){
              mtotal = 0;
           }else
           if(price === null){
                mtotal = 0;
           }else
           {
                mtotal = price;
           }
      axios.post('/subject-booking',{
         subjectID:subjectID,
         price:price,
         subject:subject,
         student_id:id,
         grade:schooloption

      },{
        headers: { 'Content-Type': 'application/json'}
     }).then((response)=>{
           setAmtSub(response.data.len);
            if(response.data.totalSubject === false){
                    let rem = parseInt(mtotal);
                      let tum =parseInt(total) - rem
                      setTotalSubTerm(tum);
                     
                        
            }else{
                
              
                 let mprice=0;
                let subj = response.data.totalSubject;
                 
                    subj.map(tuk => (
                               mprice = tuk.price === ''? 0 : tuk.price===null ? 0 : tuk.price

                    ))
                        
                      setOverallTotal(mprice);
                       
                      switch(response.data.status){
                            case 'add':
                                let add;
                                  
                                    add = parseInt(mprice) + parseInt(total);
                                 
                                  
                                 setTotalSubTerm(add);
                              break;
                              case 'minus':
                                  let minus;
                                  if(parseInt(mtotal) > parseInt(totalsubTerm)){
                                    minus = parseInt(mtotal) - parseInt(total);
                                  }else{
                                     minus = parseInt(totalsubTerm) - parseInt(mtotal);
                                  }
                                
                                setTotalSubTerm(minus);
                                break;
                               

                      }
                   
                   
            }
      })
}

const getStudentSubjectAd = (grade) =>{
            setLoadAm(true);
              console.log(id+'-'+grade)
          axios.get('/get-admin-studentsubject/'+id+'/'+grade,{
            headers: { 'Content-Type': 'application/json'}
          }).then((res)=>{
                setTimeout(()=>{
                  setLoadAm(false);
                  if(res.data.subjectData === false){
                    setNotify(<div className="alert alert-info text-center">This student has no subject for grade {grade}</div>)
                      setOffSubject(null)
                }else
                {
                      setOffSubject(res.data.subjectData);
                }
                },2000)
           

          })
}

const valtub = () =>{
  let valid = true;
    if(!schooloption.trim()){
       valid = false;
       setNoti('Please select student grade');
    }else{
      valid = true;
      setNotify('');
    }

    if(!duration.trim()){
      valid = false;
      setNoti('Please select duration for billing circle');
    }else{
      valid = true;
      setNoti('');
    }

    return valid;
}

            const handlePriceSave = () =>{
                        

              if(!valtub()){}else{

                axios.get('/validate-user-subject/'+id+'/'+schooloption,{
                  headers: { 'Content-Type': 'application/json'}
                })
            .then((response)=>{
            //setValidData(response.data)

            if(response.data.valid === 'not valid'){
              setNoti('Please select student subject');
            }else{

              const registration_status = "save";
              axios.post("/complete-registration",
              {grade:schooloption,
                total:totalsubTerm,
                bill_cycle:duration,
                registration_status:registration_status,
                studentID:id
              },{
                headers: { 'Content-Type': 'application/json'}
            }).then((response)=>{
                  if(response.data.updateStatus === true){
                    setNoti('Subject was created successfully for this student');
              } 
                })



            }
                
              
            })



            }

            }


            const getAllSubject = (grade,instruct) =>{
                    axios.get('/student-subject-change/'+grade+'/'+instruct,{
                      headers: { 'Content-Type': 'application/json'}
                    }).then((res)=>{
                         
                       
                      if(res.data.gData === false){
                              
                                setChangeSub(null);
                                  
                          }else
                          {
                                
                                 setChangeSub(res.data.gData)
                          }
                          
                    })  
            }


            const fetchRegion = () =>{
              axios.get('/all-region',{
                headers: { 'Content-Type': 'application/json'}
             })
              .then((response)=>{
                   if(response.data.allRegion === false){
                      setMRegion(<option disabled>No data available</option>)
                   }else{
                     
                    setLoadRegion(response.data.allRegion)
                   }
              })
          } 
          
          const fetchTutorDat = ()=>{
               axios.get('/admin-getstudent-admin-tutor-data/'+id,{
                headers: { 'Content-Type': 'application/json'}
               }).then((res)=>{
                    if(res.data.tdata === false){
                          setTutNote(false)
                          setTutorName('Student not in centre');
                    }else{
                      setTutNote(true)
                          let mdata = res.data.tdata;
                            let tut = mdata.split('=>');
                              setTutorName(tut[0])
                    }
               })
          }

     useEffect(()=>{

          fetchRegion();
          fetchTutorDat();
            
            axios.get('/admin-getstudent-parent/'+user+'/'+id,{
                headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                    setFname(res.data.studentDetails[0].firstname);
                    setLname(res.data.studentDetails[0].lastname);
                    setStudentNames(res.data.studentDetails[0].firstname+' '+res.data.studentDetails[0].lastname);
                    setStudentGrade(res.data.studentDetails[0].grade);
                    setStudentDob(res.data.studentDetails[0].dob);
                    setStudentRace(res.data.studentDetails[0].student_race);
                    setStudentID(res.data.studentDetails[0].student_id_pass);
                    setStudentPhone(res.data.studentDetails[0].phone);
                    setStudentEmail(res.data.studentDetails[0].email);
                    setStudentHomeLang(res.data.studentDetails[0].home_lang);
                    setStudentOtherLang(res.data.studentDetails[0].other_lang);
                    setStudentgender(res.data.studentDetails[0].gender);
                    setStudentHomeAddress(res.data.studentDetails[0].home_address);
                    setLangInstruct(res.data.studentDetails[0].lang_intruct);
                    setYearApply(res.data.studentDetails[0].year_applying);
                    setStudentRegion(res.data.studentDetails[0].region);
                    setStudentAge(res.data.studentDetails[0].age);
                    setBillCycle(res.data.studentDetails[0].bill_cycle);
                    setParentName(res.data.parentdetails[0].fnames);
                    setParentID(res.data.parentdetails[0].userid)
                    setCentreID(res.data.studentDetails[0].student_centre_id);
                  

                    getAllSubject(res.data.studentDetails[0].grade,res.data.studentDetails[0].lang_intruct)

                   

            })
     
     },[])


     const getSubData = (subID) =>{
            setSubjectIDData(subID);
           
     }

     const changeSubjectAvailable = () =>{
             
               const data = {
                subjectid:subjectChange,
                prevSubjectID:subjectIDData,
                parentID:parentID,
                student_id:id,
                grade:studentGrade,
          }
                axios.post('/change-student-subject',data,
                {
                  headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{
                       
                      if(!res.data.status){
                         setNotes(<div className="alert alert-danger text-center">{ res.data.message }</div>)
                      }else
                      {
                        setNotes(<div className="alert alert-danger text-center">{ res.data.message }</div>)
                      }
                })
     }

     const returnBack = () =>{
          setAddingSubject(true);
          window.location.reload(false);
     }

     const removeSubject = () => {
                const subData = { subjectID:subjectIDData, studentID:id}
                axios.post('/remove-subject',subData,{
                  headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{
                       setNotSub(res.data.status);
                })
     }



     const addSubjectCheck = () =>{

     
        axios.get('/check-subject-availability/'+studentGrade+'/'+id,{
          headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
              
                
              if(res.data.mstatus === 'allow'){
                  setListofCurrentSubject(res.data.currentSubject);
                    
                 setAddingSubject(true);
                 setMoreSubject(true)
              }else
              {
                setAddingSubject(true);
                setMoreSubject(false)
              }
        });
   
         



            displaySubjectAdd();
     }

     const displaySubjectAdd = () => { 
              axios.get('/display-subject-add/'+studentGrade+'/'+langInstruct,{
                headers: { 'Content-Type': 'application/json'}
              }).then((res)=>{
                    if(res.data.msubjectData === false){
                            setDisplayMoreSubject(false);
                    }else
                    {
                            setDisplayMoreSubject(res.data.msubjectData);
                    }
              })

              
     }

     const completeSave = () => {
            const data = {
              grade:studentGrade, studID:id, parentID:parentID
            }
             axios.post('/complete-admin-add-subject',data,{
              headers: { 'Content-Type': 'application/json'}
             }).then((res)=>{
                
                setSub_Note(<div className="alert alert-info text-center">{res.data.comStatus}</div>);
                 setTimeout(()=>{
                      if(res.data.ustatus === false){}else{
                    window.location.reload(false)
                      }
                 },2000)
             })
     }


     const displayCentre = (region) =>{
            setLoaderStatus(true);
            setNoteCentre(null)
            axios.get('/admin-getallcentre-link/'+region,{
              headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                      setTimeout(()=>{
                         setLoaderStatus(false)
                        if(res.data.status === false){
                              setNoteCentre(<div className='alert alert-info text-center'>{res.data.tutDatas}</div>)
                              setCentreData(null)
                        }else{
                              
                              setCentreData(res.data.tutDatas);
                        }
                      },2000)
                   
            })
     }

     const notifyStud = (cent_id) => {
            if(cid === cent_id){
              return(<span>{ studentNames } has been Linked this centre </span> )
            }else{
                return('Link to centre')
            }
     }

     const AddStudentCentre = (cent_id,cname,tid) =>{
              let uData = {centID:cent_id,centerName:cname,tID:tid,pid:parentID,studentID:id}
              axios.post('/admin-adding-stud-cent',uData,{
                headers: { 'Content-Type': 'application/json'}
              }).then((res)=>{
                   if(res.data.status === false){
                       setNoteCentre(<div className='alert alert-danger text-center'>{ res.data.message }</div>)
                   }else{
                    setCid(cent_id);
                    setCentreID(cent_id);
                    setNoteCentre(<div className='alert alert-info text-center'>{ res.data.message }</div>)
                   
                   }
              })
             
     }
    

    return (
        <div id="wrapper">
        <div className="content-page">
                <div className="content">

                 
                    <div className="container-fluid">

                    <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">Registered User</li>
                <li className="breadcrumb-item">Parent</li>
                <li className="breadcrumb-item active">Student</li>
              </ol>
            </div>
            <h4 className="page-title">Student Details</h4>
          </div>
        </div>
      </div>


       
       


      <div className="row">
        <div className="col-lg-4">

          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">Student Information</h4>
            
              <div className="row">
                

                <div className="col-lg-6">
                  <div className="mb-4">
                    <h5 className="mt-0">Parent Name</h5>
                    <p> { parentName }</p>
                  </div>
                </div>
                 
                <div className="col-lg-6">
                  <div className="mb-4">
                    <h5 className="mt-0">Centre:</h5>
                     { tutnote !== false ?
                      <div>
                         <p> { tutorName}  </p>
                         <button type="button" onClick={()=>setAddingState('change')}  className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centerRegion" data-backdrop="static">  Change Centre</button>
                       
                         { removeStudentStatus === true ? 
                         <button type="button"  className="btn btn-blue waves-effect btn-sm waves-light" disabled> Removing...</button>
                          :
                          <button type="button"  className="btn btn-blue waves-effect btn-sm waves-light" onClick={() => removeStudent()}>  Remove From Centre</button>
                         }
                        </div>
                        :
                        <div>
                            <button type="button" onClick={()=>setAddingState('new')} className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centerRegion" data-backdrop="static">  Link to Centre</button>
               
                        </div>
                    }
                   
                  </div>
                </div>
              </div>

              <div className="row">


                { editinfo !== false ? 
                <div className="col-lg-12">
                    <button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=>setEditInfo(false)} style={{float:'right'}}>Close</button>
                <br /><br />
                { editNotify === null ? null : editNotify }
                                      <div className="row">


                                      <div class="form-group mb-3 col-6">
                                                        <label for="simpleinput">First Name</label>
                                                        <input type="text" id="simpleinput" class="form-control" onChange={(e)=>setFname(e.target.value)} defaultValue={fname} />
                                                    </div>

                                                    <div class="form-group mb-3 col-6">
                                                        <label for="example-email">Last Name</label>
                                                        <input type="email" id="example-email" name="example-email" class="form-control" onChange={(e)=>setLname(e.target.value)} defaultValue={lname}/>
                                                    </div>
                                        </div>

                                        <div className="row">
                                               <div class="form-group mb-3 col-6">
                                                        <label for="simpleinput">Student ID</label>
                                                        <input type="text" id="simpleinput" class="form-control" onChange={(e)=>setStudentID(e.target.value)} defaultValue={studentID}/>
                                                    </div>

                                                    <div class="form-group mb-3 col-6">
                                                    <label for="example-select">Gender</label>
                                                        <select class="form-control" id="example-select" onChange={(e)=>setStudentgender(e.target.value)}>
                                                            <option defaultValue={studentGender}>Current: { studentGender }</option>
                                                            <option defaultValue="Male">Male</option>
                                                            <option defaultValue="Female">Female</option>
                                                          
                                                        </select>
                                                    
                                                    </div>
                                          </div>
                                       
              
                                          <div className="row">


<div class="form-group mb-3 col-8">
                  <label for="simpleinput">Date of Birth</label>
                  <input type="date" id="simpleinput" class="form-control"  onChange={(e)=>setStudentDob(e.target.value)} defaultValue={studentDob}/>
              </div>

              <div class="form-group mb-3 col-4">
                  <label for="example-email">Age</label>
                  <input type="number" id="example-email" name="example-email" class="form-control"  onChange={(e)=>setStudentAge(e.target.value)} defaultValue={studentAge}/>
              </div>
  </div>

  <div className="row">
         <div class="form-group mb-3 col-8">
                  <label for="simpleinput">Email</label>
                  <input type="email" id="simpleinput" class="form-control"  onChange={(e)=>setStudentEmail(e.target.value)} defaultValue={studentEmail}/>
              </div>

              <div class="form-group mb-3 col-4">
              <label for="example-select">Grade</label>
                  <select class="form-control" id="example-select" onChange={(e)=>setStudentGrade(e.target.value)}>
                     <option defaultValue=''>Select Grade</option>
                      <option defaultValue={studentGrade}>Current : {studentGrade }</option>
                      <option defaultValue="R">R</option>
                      <option defaultValue="1">1</option>
                      <option defaultValue="2">2</option>
                      <option defaultValue="3">3</option>
                      <option defaultValue="4">4</option>
                      <option defaultValue="5">5</option>
                      <option defaultValue="6">6</option>
                      <option defaultValue="7">7</option>
                      <option defaultValue="7">8</option>
                      <option defaultValue="8">9</option>
                      <option defaultValue="9">10</option>
                      <option defaultValue="10">11</option>
                      <option defaultValue="12">12</option>
                      
                    
                  </select>
              
              </div>
    </div>
 
    <div className="row">
         <div class="form-group mb-3 col-6">
                  <label for="simpleinput">Phone</label>
                  <input type="text" id="simpleinput" class="form-control"  onChange={(e)=>setStudentPhone(e.target.value)} defaultValue={studentPhone}/>
              </div>

              <div class="form-group mb-3 col-6">
              <label for="example-select">Region</label>
              
                  <select class="form-control" id="example-select" onChange={(e)=>setStudentRace(e.target.value)}>
                    <option defaultValue={studentRegion}>Current: { studentRegion } </option>
                  { mregion !== null ? mregion :
                        loadRegion && loadRegion.map(yu =>(
                          <option key={yu.id} defaultValue={yu.location}>{ yu.location }</option>
                        ))
                    }
                      
                    
                  </select>
              
              </div>
    </div>
 



    <div className="row">
         
         <div class="form-group mb-3 col-12">
                  <label for="simpleinput">Address</label>
                  <textarea type="text" id="simpleinput" class="form-control" onChange={(e)=>setStudentHomeAddress(e.target.value)} >{ studentHomeAddress }</textarea>
              </div>

           
    </div>
 


    <div className="row">
         <div class="form-group mb-3 col-6">
                  <label for="simpleinput">Home Language</label>
              
                  <input type="text" id="simpleinput" class="form-control" onChange={(e)=>setStudentHomeLang(e.target.value)} defaultValue={studentHomeLang} />
              </div>

              <div class="form-group mb-3 col-6">
              <label for="example-select">Other Language</label>
              <input type="text" id="simpleinput" class="form-control"  onChange={(e)=>setStudentOtherLang(e.target.value)} defaultValue={studentOtherLang}/>
              
              </div>
    </div>
 


    <div className="row">
         

              <div class="form-group mb-3 col-12">
              <label for="example-select">Student Race</label>
              <select class="form-control" id="example-select" onChange={(e)=>setStudentRace(e.target.value)}>

                              <option defaultValue={studentRace}>Current: {studentRace }</option>
                              <option defaultValue="Black African">Black African	</option>
                              <option defaultValue="Colored">Colored</option>
                              <option defaultValue="White">White</option>
                              <option defaultValue="Indian/Asian">Indian/Asian</option>
                    
                  </select>
              
              </div>
    </div>
 

    <div className="row">
         <div class="form-group mb-3 col-8">
                  <label for="simpleinput">Language of Instruction</label>
                  <select class="form-control" id="example-select" onChange={(e)=>setLangInstruct(e.target.value)}>
                    <option defaultValue={langInstruct}>Current: { langInstruct } </option>
                 
                    <option defaultValue="Afrikaans">Afrikaans</option>
                              <option defaultValue="English">English</option>
                      
                    
                  </select>
              
             
              </div>

              <div class="form-group mb-3 col-4">
              <label for="example-select">Year Applying</label>
              <select class="form-control" id="example-select" onChange={(e)=>setYearApply(e.target.value)}>
                              <option defaultValue={yearApplying}> { yearApplying} </option>
                             <option defaultValue={2021}>2021</option>
                              <option defaultValue={2022}>2022</option>
                              <option defaultValue={2023}>2023</option>
                              <option defaultValue={2024}>2024</option>
                              <option defaultValue={2025}>2025</option>
                              <option defaultValue={2026}>2026</option>
                              <option defaultValue={2027}>2027</option>
                              <option defaultValue={2028}>2028</option>
                              <option defaultValue={2029}>2029</option>
                              <option defaultValue={2030}>2030</option>
                    
                  </select>
              
              </div>
    </div>
 
          

       <div className="row">
       <button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> updateStudentInfo()} >Update Information</button>
             
         </div>       



               </div>



                
                :
                <div className="col-lg-12">
               
                <button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=>setEditInfo(true)} style={{float:'right'}}>Edit Information</button>
                <br /><br />
              
                <h4 className="header-title mb-3">Student Details</h4>
             
             <p className="mb-2"><span className="font-weight-semibold mr-2"> Name:</span>  { studentNames }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Student ID: </span>  { studentID }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Gender: </span>  { studentGender }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Date of Birth: </span>  { studentDob }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Age: </span> { studentAge }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Grade: </span>  { studentGrade }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Race: </span>  { studentRace }</p>
           
             <p className="mb-2"><span className="font-weight-semibold mr-2">Email:</span> { studentEmail }</p>
            
             <p className="mb-2"><span className="font-weight-semibold mr-2">Phone:</span> { studentPhone }</p>
            
             
             <p className="mb-2"><span className="font-weight-semibold mr-2">Region:</span> { studentRegion }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Address: </span>  { studentHomeAddress }</p>

             <p className="mb-2"><span className="font-weight-semibold mr-2">Home Lang:</span> { studentHomeLang }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Other Lang: </span>  { studentOtherLang }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Language of Instruct:</span> { langInstruct }</p>
             <p className="mb-2"><span className="font-weight-semibold mr-2">Year Applying: </span>  { yearApplying }</p>
           
                </div>
}
              </div>
          
            </div>
          </div>
       
       
        </div>
       
       
        { addingSub === false ?
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
                { notSub === null ? null : notSub }
                { statusM === false ? null : 
            <button onClick={()=> handleBackSubject()} className="btn btn-blue waves-effect btn-sm waves-light" style={{float:'right'}}>Back to Subject</button>
                }
            <h4 className="header-title mb-3">All Subject Offered</h4>
            <button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> addSubjectCheck()} style={{float:'right'}}>Add Subject </button>
            <br/><br/>
              <div className="table-responsive">

                  <p>Select student grade history to view subject offered </p>
              { studentGrade === null ? null : 
                    <select className="custom-select " id="status-select" onChange={(e)=> getStudentSubjectAd(e.target.value)}>
                            <option value="">Grade History Subject</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>{studentGrade === data.grade ? 'Current Grade: '+data.grade : 'Grade: '+ data.grade }</option>
                            ))}
                            
                    </select>

                            }
                                    { loadam !== false ?
                                    <center>
                                    <div className="spinner-border m-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                      </center>
                                      : null }
                            


<br/><br/> 
            { statusM !== false ?
                subjectActivity === null ? subNotify :
           <div>
            
           <table className="table table-borderless table-nowrap table-hover table-centered m-0">
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Assessment</th>
              <th>Upload</th>
              <th>Date Uploaded</th>
             
             
            </tr>
          </thead>
          <tbody>
                { subjectActivity && subjectActivity.map(({upload,date_upload,cate,tasktype},index)=>{
                     return (
                         <tr key={index}>
                             <td>{ index+1}</td>
                             <td>{ tasktype === null ? '--' : tasktype } { ' '+generalSubject+' '+cate }</td>
                             <td>{upload === null ? <span style={{color:'red'}}>Not Available</span>  :
                                    upload === '' ? <span style={{color:'red'}}>Not Available</span> : 
                                      <span style={{color:'darkgreen'}}>Available</span>
                                     }
                             </td>
                             <td>
                             {date_upload === null ? '--' :
                                    date_upload === '' ? '--' : 
                                    date_upload
                                     }
                             </td>
                            

                         </tr>
                     )
                }) }
          </tbody>

          </table>
            </div>
            : 

             offsubject === null ? notify :
       
                <div>
                    

                  
                                        <div id="warning-alert-modal" className="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog modal-sm">
                                                <div className="modal-content">
                                                    <div className="modal-body p-4">
                                                        <div className="text-center">
                                                            <i className="dripicons-warning h1 text-warning"></i>
                                                            <h4 className="mt-2">Warning</h4>
                                                            <p className="mt-3">Removing this subject from student subject list, student won't be able to offer this subject. kindly use the option
                                                             </p>
                                                             <div className="row">
                                                               <div className="col-md-6">
                                                               <button type="button" className="btn btn-warning btn-sm" data-toggle="modal" data-target="#centermodal">Change Subject</button>
                                                          
                                                                 </div>

                                                                 <div className="col-md-6">
                                                             <button type="button" className="btn btn-danger btn-sm" onClick={()=> removeSubject()} >Remove Subject</button>
                                                     
                                                                 </div>

                                                               </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                       
                                        <div className="modal fade" id="centermodal" tabindex="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title" id="myCenterModalLabel">Changing Subject</h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                                    </div>
                                                    <div className="modal-body">
                                                        { notes === null ? null : notes }
                                                        <h5>Select Subject</h5>
                                                          
                                                         { changeSub === null ? <select><option disabled>No data available</option></select>
                                                              :
                                                              <select className="custom-select " id="status-select" onChange={(e)=> setSubjectChange(e.target.value)}>
                                                                             <option value="">Select Subject</option>
                                                                            { changeSub && changeSub.map(({subject,subject_id,subject_cate},index)=>{

                                                                                 return (
                                                                                      <option key={index} value={subject_id}>{ subjectIDData === subject_id ? 'Current '+subject+' - '+subject_cate : subject+' - '+subject_cate }</option>
                                                                                 )
                                                                                 
                                                                            }) }
                                                              </select>

                                                        }
                                                       <br /><br/>
                                                       <button className="btn btn-blue btn-sm" onClick={()=> changeSubjectAvailable()}>Change</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
             

       <table className="table table-borderless table-nowrap table-hover table-centered m-0">
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Subject Name</th>
              <th>Grade</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            
                { offsubject && offsubject.map(({subject,subject_id,student_id,status,grade},index)=>{
                      return(
                          <tr key={index}>
                              <td>{index+1}</td>

                              <td>{ subject }</td>

                              <td>{ grade }</td>

                              <td>{ status === 1 ? 'Active' : 'Not Active' }</td>
                              <td><button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> handleActivity(student_id,subject_id,subject)}>View Activity</button>
                              &nbsp;&nbsp; &nbsp;&nbsp;
                              <button type="button" className="btn btn-danger waves-effect btn-sm waves-light" onClick={()=> getSubData(subject_id)} data-toggle="modal" data-target="#warning-alert-modal">Remove</button> </td>
                         
                          </tr>
                      )
                })
              }
             
           </tbody>
        </table>
             </div>
             
             }

      </div> 
            </div>
          </div>
        </div>
         
         :

                moreSubject !== false ? 
                
                <div className="col-lg-8"> 

            <div className="card">
            <div className="card-body">
            <button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> returnBack()} style={{float:'right'}}> Back </button>
            <h4 className="header-title mb-3">Add More Subject</h4>

              <div className="row">

            <div className="col-lg-12">
        <div className="card-box">
          <h4 className="header-title">List of Current Subject</h4>
        
          <div className="table-responsive">
            <table className="table table-hover mb-0">

              <thead>
                <tr>
                  <th>#</th>
                  <th>Subject Name</th>
                  <th>Subject Code</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                { listofCurrentSubject && listofCurrentSubject.map(({subject,price,subject_id},index)=>{
                     return (
                      <tr key={index}>
                      <th scope="row">{ index + 1} </th>
                      <td>{subject }</td>
                      <td>{ subject_id }</td>
                      <td>{ price }</td>
                    </tr>
                     )
                })}
               
              
              </tbody>
            </table>
          </div> {/* end table-responsive*/}
        </div> {/* end card-box */}
      </div> {/* end col */}
              
</div>




<div className="row">

<div className="col-lg-12">
<div className="card-box">
<h4 className="header-title">Subject to Add</h4>
                { sub_note=== null ? null : sub_note}
<div className="table-responsive">

  { displaymoreSubject === false ? <div className="alert alert-info text-center"> No subject available</div>
  :
<table className="table table-hover mb-0">
  <thead>
    <tr>
      <th>#</th>
      <th>Subject Name</th>
      <th>Subject Code</th>
      <th>Category</th>
      <th>Price</th>
    </tr>
  </thead>
  <tbody>
    { displaymoreSubject && displaymoreSubject.map(({subject,subject_id,price,subject_cate},index)=>{
           
        return (
           
          <tr key={index}>        
          <th scope="row">
            
            <input type="checkbox"
            
            
            value={subject_id}
            name={subject}
            onClick={(e)=>handleAddMoreSubject(e,price)}  />
          
        
            </th>
          <td>{ subject }</td>
          <td>{ subject_id }</td>
          <td>{ subject_cate }</td>
          <td>{ price }</td>

        </tr>
        )
    })}
 
  
  </tbody>
</table>
}
<br /><br />
<button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> completeSave()} >Save Subject selected</button>
</div> {/* end table-responsive*/}
</div> {/* end card-box */}
</div> {/* end col */}
  
</div>

              </div>
              </div>

                  </div> 
                                          
                                          
                                          :


              <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
            <button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> returnBack()} style={{float:'right'}}> Back </button>
          
            <h4 className="header-title mb-3">Add Subject</h4>
           
           
           <div className="row">
           <div className="col-lg-12">
           { noti !== null ?  <div className='alert alert-info'>{ noti } </div> : null }
          
             </div>
                     

                  <div className="col-lg-6">

                    
                <select className="custom-select " onChange={ checkSelectedGrade } id="status-select">
                <option defaultValue="">Select Grade</option>
                              { studentGrade === null ? null :
                              <option value={studentGrade}> { 'Current Grade: '+studentGrade }</option>
                                    }
                            
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                             
                  </select>
                 
                  </div>

                    
                      
                  <div className="col-lg-6">
                <select className="custom-select " id="status-select" onChange={handlePrice }>
                <option defaultValue="">Select Duration</option>
                            <option value='Monthly'>Term </option>
                             <option value='Yearly'>Year </option>
                             
                  </select>
                  </div>
                  
                  <div className="col-lg-12">
                    <br></br><br></br><br></br>
                    </div>

                  <div className="col-lg-12">

                     { 
                            msybjectStatus === false ? null :
                            subjectStatus !== null ? <div className='alert alert-info'>{ subjectStatus }</div>
                            :
                         <div>
       <h4 className="card-title mb-4"> Subject For Grade { schooloption }</h4>
        <div className="table-responsive">
         
        <table className="table table-bordered table-centered mb-0">
         <thead className="thead-light">
              <tr>
                <th style={{width: '20px'}}>
                  
                </th>
                <th className="align-middle">Subject ID</th>
                <th className="align-middle">Subject Name</th>
                <th className="align-middle">Category</th>
                <th className="align-middle">Language of Instructor</th>
                <th className="align-middle">Price</th>
               
               
              </tr>
            </thead>
            <tbody>
              
              { subjectData && subjectData.map(({ subject, subject_id, price, subject_cate, lang }, index) => {
                  return(
                         
                      <tr key={index}>
                      <td>
                        <div className="form-check font-size-16">
                          <input className="form-check-input" type="checkbox"
                           id="transactionCheck02" 
                           value={subject}
                           name={subject}
                           checked={isChecked[index]}
                           onChange={()=>handleSubjectCheck(subject_id,price,subject)}  />
                          <label className="form-check-label" htmlFor="transactionCheck02" />
                         
                        </div>
                      </td>
                      <td><div className="text-body fw-bold">#{ subject_id }</div> </td>
                      <td>{ subject }</td>
                      <td>{ subject_cate }</td>
                      <td>{ lang }</td>
                      <td>
                        { price === null ? currencyFormat(0) : price === '' ? currencyFormat(0) : currencyFormat(parseInt(price))}
                      </td>
                      </tr>
                  );
              })

              }
              
               </tbody>
          </table>
      
        </div>
        </div>
                        
                        
                        }
                    </div>

                    <div className="col-lg-12">
                    <br></br><br></br><br></br>
                    </div>

           <div className="col-lg-12">
           <table className="table project-list-table table-nowrap align-middle">
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td colSpan={1} className="border-0 text-end">
                              {duration} Fee
                              </td>
                              <td className=" text-end">
                              { total === 0 ? ('0.00') : currencyFormat(parseInt(total))}
                              </td>
                            </tr>

                            <tr>
                            <td></td>
                              <td></td>
                              <td colSpan={1} className=" text-end">
                               Fee of { amtSub } Subject
                              </td>
                              <td className=" text-end">
                              { total === 0 ? ('0.00') : currencyFormat(parseInt(overallTotal))}
                              </td>
                            </tr>

                            <tr>
                            <td></td>
                              <td></td>
                              <td colSpan={1} className=" text-end">
                                <strong>Total</strong>
                              </td>
                              <td className=" text-end">
                              { total ===  0 ? ('0.00') : currencyFormat(parseInt(totalsubTerm))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     
            
            
             </div>

             <div className="col-lg-12">
             <button type="button" onClick={()=>handlePriceSave()} className="btn btn-primary w-md submit">Complete &amp; Save</button>
                  
               </div>



             </div>
        

              </div>
              </div>

                </div>
           
           
           }
    
      </div>


      <div className="modal fade" id="centerRegion" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Link Student to Centre</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">
          
           { noteCentre === null ? null : noteCentre }

          <div className="row">

          <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Select Region </label><br></br>
              
                <select className="custom-select" id="status-select" onChange={(e)=>displayCentre(e.target.value)}>
                <option defaultValue={studentRegion}>Current: { studentRegion } </option>
                  { mregion !== null ? mregion :
                        loadRegion && loadRegion.map(yu =>(
                          <option key={yu.id} defaultValue={yu.location}>{ yu.location }</option>
                        ))
                    }
                           
                  </select>
              </div>
                { loaderStatus === false ? null :
              <center>
              <div className="spinner-border text-blue m-2" role="status"></div>
              </center>
              }
            </div>
            
          
          </div>

            { centreData === null ? null :
                centreData && centreData.map(({centreName,centreAddress,center_id,tutor_id},index)=>{
                        return(
                          <div key={index} className="inbox-widget" data-simplebar style={{maxHeight: '407px'}}>
                          <div className="inbox-item">
                            <div className="inbox-item-img"><img src="/assets/images/users/user-2.jpg" className="rounded-circle" alt="" /></div>
                            <p className="inbox-item-author">{ centreName }</p>
                            <p className="inbox-item-text">{ centreAddress}</p>
                            <p className="inbox-item-date">
                               { center_id === centreID ?
                              <span className=" text-info font-13">Student has been Linked to this centre </span>
                                    :
                                    <span onClick={()=>AddStudentCentre(center_id,centreName,tutor_id)} className="btn btn-sm btn-link text-info font-13"> {notifyStud()} </span>

                               }
                            </p>
                          </div>
                        </div>
                        )
                })
    
}
          
        
  
        
        </div>
      
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}



                    </div>
                    </div>
                    </div>

          
        </div>
   
    )
}

export default StudentInfo
