import React, {useState, useEffect} from 'react'
import {Link, NavLink, useNavigate} from 'react-router-dom'
import axios from 'axios';


function NavHead({siteName,authenCheck}) {
      const [fnames, setFnames] = useState();
      const [adminType, setAdminType] = useState();
      const history = useNavigate();


      const logout = () =>{
       axios.get('/user-logout',{
        headers: { 'Content-Type': 'application/json'},
        withCredentials:true
     })
       .then((response)=>{
                  if(response.data.status){
                      //setLoader(false);
                     authenCheck(false);
                     history("/"); 
                   
                  }

       })
  }


     useEffect(()=>{
    
         axios.get('/fetchlogin-admin',{
          headers: { 'Content-Type': 'application/json'}
         })
         .then((response)=>{
                setFnames(response.data.adminData[0].names);
                setAdminType(response.data.adminData[0].admin_type);
         })
     })
    return (
        <div>
        {/* Topbar Start */}
        <div className="navbar-custom">
          <div className="container-fluid">
            <ul className="list-unstyled topnav-menu float-right mb-0">
              
              
            
            
             
              <li className="dropdown notification-list topbar-dropdown">
                <Link className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                 
                  <span className="pro-user-name ml-1">
                   Welcome { fnames }!
                  </span>
                </Link>
               
              </li>
             
            </ul>
            {/* LOGO */}
            <div className="logo-box">
              <div className="logo logo-dark text-center">
                <span className="logo-sm">
                  <img src="../assets/images/fg.png" alt="" height={22} />
                 
                </span>
                <span className="logo-lg">
                  <img src="../assets/images/eq4.png" alt="" height={40} />
                 
                </span>
              </div>
              <div className="logo logo-light text-center">
                <span className="logo-sm">
                  <img src="../assets/images/fq.png" alt="" height={22} />
                </span>
                <span className="logo-lg">
                  <img src="../assets/images/eq4.png" alt="" height={40} />
                </span>
              </div>
            </div>
            <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                            <button className="button-menu-mobile waves-effect waves-light">
                                <i className="fe-menu"></i>
                            </button>
                        </li>

                        <li>
                         
                            <div className="navbar-toggle nav-link" data-toggle="collapse" data-target="#topnav-menu-content">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                          
                        </li> 
              </ul>
          
            <div className="clearfix" />
          </div>
        </div>
        {/* end Topbar */}
        <div className="topnav shadow-lg">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <div className="collapse navbar-collapse" id="topnav-menu-content">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <NavLink className="nav-link dropdown-toggle arrow-none" to="/dashboard" id="topnav-dashboard" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe-airplay mr-1" /> Dashboards 
                    </NavLink>
                   
                  </li>
                  <li className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle arrow-none"  id="topnav-apps" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe-user mr-1" />Registered Users <div className="arrow-down" />
                    </div>
                    <div className="dropdown-menu" aria-labelledby="topnav-apps">
                    <Link to="/all-student" className="dropdown-item"><i className="fe-calendar mr-1" />All Student</Link>
                      <Link to="/parents" className="dropdown-item"><i className="fe-calendar mr-1" /> Parents</Link>
                      <Link to="/tutors" className="dropdown-item"><i className="fe-message-square mr-1" /> Tutors</Link>
                    
                        </div>
                  </li>


                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/subject" id="topnav-ui" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe-briefcase mr-1" /> Subjects
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle arrow-none" id="topnav-components" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe-layers mr-1" /> Academic <div className="arrow-down" />
                    </div>
                    <div className="dropdown-menu" aria-labelledby="topnav-components">
                     
                    <Link className="dropdown-item dropdown-toggle arrow-none" to="/questionupl">
                          <i className="fe-pocket mr-1" /> Upload Invigilator Question
                        </Link>

                        <Link className="dropdown-item" to="/admintutorupload" id="topnav-extendedui">
                          <i className="fe-pocket mr-1" /> View or Update Invigilator Upload
                        </Link>
                       
                    
                      <Link to="/assessment-summary" className="dropdown-item"><i className="fe-gift mr-1" /> Assessment Summary </Link>

                  
                    
                        <Link className="dropdown-item dropdown-toggle arrow-none" to="/capture-assessment" >
                          <i className="fe-bar-chart-2 mr-1" /> Capture Assessment 
                        </Link>
                       
                     
                     
                        <Link className="dropdown-item dropdown-toggle arrow-none" to="/upload-assessment"  >
                          <i className="fe-grid mr-1" /> View Assessment 
                        </Link>
                        
                 
                    
                        <Link className="dropdown-item dropdown-toggle arrow-none" to="/term-final" id="topnav-icons" >
                          <i className="fe-cpu mr-1" /> Term &amp; Final 
                        </Link>
                       
                   
                   
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="#" id="topnav-pages" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fe-package mr-1" /> Finance <div className="arrow-down" />
                    </Link>

                    <div className="dropdown-menu" aria-labelledby="topnav-pages">

                    <div className="dropdown">
                        <Link className="dropdown-item dropdown-toggle arrow-none" to="/fee-setup" >
                         Set Up Fees
                        </Link>
                        
                      </div>


                      <div className="dropdown">
                        <Link className="dropdown-item dropdown-toggle arrow-none" to="/paid-invoice" >
                          Paid Invoice 
                        </Link>
                        
                      </div>
                      
                      <div className="dropdown">
                        <Link className="dropdown-item dropdown-toggle arrow-none" to="/unpaid-invoice" >
                          Unpaid Invoice 
                        </Link>
                        
                      </div>

                      <div className="dropdown">
                        <Link className="dropdown-item dropdown-toggle arrow-none" to="/generate-invoice" >
                          Generate Invoice 
                        </Link>
                        
                      </div>
                    
                    </div>
                  </li>
                 

                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/support" >
                      <i className="fe-sidebar mr-1" /> Support
                    </Link>
                    
                  </li>

                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/request" >
                      <i className="fe-sidebar mr-1" /> Request
                    </Link>
                    
                  </li>

                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/settings" style={{cursor:'pointer'}}>
                      <i className="fe-settings mr-1" /> Admin Settings
                    </Link>
                    
                  </li>

                  <li className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle arrow-none" onClick={()=>logout()} style={{cursor:'pointer'}}>
                      <i className="fe-sidebar mr-1" /> Logout
                    </div>
                    
                  </li>
                </ul> {/* end navbar*/}
              </div> {/* end .collapsed*/}
            </nav>
          </div> {/* end container-fluid */}
        </div> {/* end topnav*/}
      </div>
    )
}

export default NavHead
