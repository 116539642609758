import React from 'react'

function NavFoot({siteName}) {
     const currentDate = new Date().getFullYear();
    return (
       
      <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            { currentDate } -  © { siteName }
          </div>
          <div className="col-md-6">
            
          </div>
        </div>
      </div>
    </footer>
  
    )
}

export default NavFoot
