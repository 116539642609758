import axios from 'axios';
import React,{useState} from 'react'

function Register({siteFoot,checkAdminLog}) {
    const curDate = new Date().getFullYear();

    const [fnames, setFnames] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passError, setPassError] = useState(null);
    const [notify, setNotify] = useState(null);


    const validateAdmin = () =>{
         let valid = true;
           if(!fnames.trim()){
                 setNameError('Please enter full name');
                  valid = false
           }else
           {
            setNameError(null);
                 valid = true;
           }

           if(!email.trim()){
            setEmailError('Please enter email address');
             valid = false
      }else
      if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError('Please provide a valid email address');
        valid = false;
         
      }else
      {
       setEmailError(null);
            valid = true;
      }

      if(!password.trim()){
        setPassError('Please enter password');
         valid = false
  }else
  {
   setPassError(null);
        valid = true;
  }

     return valid;

    }

    axios.defaults.withCredentials = true;
    const handleRegister = (e) =>{
          e.preventDefault();
              const admin_type ='head';
            if(!validateAdmin()){}else{
             
                axios.post('/register-admin',{
                   fname:fnames,
                   email:email,
                   password:password,
                   admin_type:admin_type
                },{

                  headers: { 'Content-Type': 'application/json'}
                
                }).then((respose)=>{
                       
                             
                          if(respose.data.status === 'allow'){
                            checkAdminLog(true);
                        }else
                        {
                            setNotify('Error occur while processing your registration');
                        }

                     
                      
                })
            }
    }


    return (
        <div>
        <div className="account-pages mt-5 mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card bg-pattern">
                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <div className="auth-logo">
                        <a href="/" className="logo logo-dark text-center">
                          <span className="logo-lg">
                            <img src="../assets/images/eq4.png" alt="" height={40} />
                          </span>
                        </a>
                        <a href="/" className="logo logo-light text-center">
                          <span className="logo-lg">
                            <img src="../assets/images/eq4.png" alt="" height={40} />
                          </span>
                        </a>
                      </div>
                      <p className="text-muted mb-4 mt-3">This a first time registration. Your registering as the head of admin </p>
                    </div>

                    { notify !== null ? 
                    <div className="alert alert-primary text-center">{ notify }</div>
                    : null }

                    <form onSubmit={handleRegister} method="post">
                      <div className="form-group">
                        <label htmlFor="fullname">Full Name</label>
                        <input className="form-control" type="text" id="fullname" placeholder="Enter your name" onChange={(e)=> setFnames(e.target.value)} />
                            { nameError !== null ? <p style={{color:'red'}}>{ nameError }</p> : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="emailaddress">Email address</label>
                        <input className="form-control" type="email" id="emailaddress" placeholder="Enter your email" onChange={(e)=> setEmail(e.target.value)} />
                        { emailError !== null ? <p style={{color:'red'}}>{ emailError }</p> : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="input-group input-group-merge">
                          <input type="password" id="password" className="form-control" placeholder="Enter your password" onChange={(e)=>setPassword(e.target.value)} />
                          
                        </div>
                        { passError !== null ? <p style={{color:'red'}}>{ passError }</p> : null}
                      </div>
                     
                      <div className="form-group mb-0 text-center">
                         
                        <button className="btn btn-success btn-block" type="submit"> Sign Up </button>
                                 
                      </div>
                    </form>
                  
                  </div> {/* end card-body */}
                </div>
               
              </div> {/* end col */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
        {/* end page */}
        <footer className="footer footer-alt text-black-50">
          { curDate } -  © { siteFoot }
        </footer>
      </div>
    )
}

export default Register
