import React,{useState, useEffect} from 'react'
import axios from 'axios'
import {useParams,useNavigate} from 'react-router-dom'

function CentreDetails() {
         const { id } = useParams();
         const [studentData, setStudentData] = useState(null);
         const [notifyStud, setNotifyStud] = useState(null);
         const [numStud, setNumStud] = useState();
         const [centreName, setCentreName] = useState();
         const [centrePhone, setCentrePhone] = useState();
         const [centreEmail, setCentreEmail] = useState();
         const [centreAddress, setCentreAddress] = useState();
         const [dateRegistered, setDateRegistered] = useState();
         const [owner, setOwner] = useState();
         const [status, setStatus] = useState();
         const [mtutor, setMtutorID] = useState();
         const [deleStatus, setDeleStatus] = useState(false);
        
         const [noteMe, setNoteMe] = useState(false);
         const [noti, setNoti] = useState(null);
         const history = useNavigate();
       


    useEffect(()=>{
        axios.get('/all-centre-student-data/'+id,{
            headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
            if(res.data.studentData === false){
                setNotifyStud(<div className="alert alert-info text-center">Oops! No student has registered in this centre</div>)
            }else
            {
                 setStudentData(res.data.studentData);
            }

               setNumStud(res.data.Nostud);
               //centreData
               setCentreName(res.data.centreData[0].centreName);
               setCentrePhone(res.data.centreData[0].centrePhone);
               setCentreEmail(res.data.centreData[0].centreEmail);
               setCentreAddress(res.data.centreData[0].centreAddress);
               setDateRegistered(res.data.centreData[0].date_reg);
               setOwner(res.data.centreData[0].tutorName);
               setStatus(res.data.centreData[0].status);
               setMtutorID(res.data.centreData[0].tutor_id);
        })
    },[])

  const centreStatus = (mstatus) =>{
          if(mstatus === 'delete'){
            setDeleStatus(true);
          }else{
            setNoteMe(true);
          }
         
              const mData ={
                   status:mstatus,
                   centreID:id
              }
             axios.post('/activate-centres-data',mData,{
              headers: { 'Content-Type': 'application/json'}
             }).then((res)=>{
                    setTimeout(()=>{
                        
                      if(mstatus === 'delete'){
                        setDeleStatus(false);
                      }else{
                        setNoteMe(false);
                      }
                      if(res.data.message === false){
                              switch(mstatus){
                                 case 'Pending':
                                  setNoti(<div className="alert alert-info text-center">Deactivation was successful</div>)
                                   break;
                                  case 'Active':
                                    setNoti(<div className="alert alert-info text-center">Activation was successful</div>)
                                    break;
                              }
                           
                      }else
                      {
                          if (res.data.message === mstatus) {
                            history('/tutor-data/details/'+mtutor);
                          }else{
                        setNoti(<div className="alert alert-info text-center">{ res.data.message }</div>)
                          }
                      }

                      setTimeout(()=>{
                          window.location.reload(false);
                      },2000)
                      
                    },2000)
                  
             })
  }


    const buttonDisplay = (status) =>{
          switch(status){
               case 'Pending':
                   return (

                    <div className="btn btn-success waves-effect btn-sm waves-light" 
                    onClick={()=>centreStatus('Active')}> 
                    Activate Centre</div>
                
            
                   )
                 break;
                 case 'Active':
                    return (
                      <div className="btn btn-blue waves-effect btn-sm waves-light"
                      onClick={()=>centreStatus('Pending')}> 
                      Deactivate Centre</div> 
           
                    )
                   break;
          }
    }


    const displayStatus = (status) =>{
      switch(status){
           case 'Pending':
               return (

               <div className="alert alert-danger text-center">This centre is not active</div>
            
        
               )
             break;
             case 'Active':
                return (
                  <div className="alert alert-success text-center">This centre is active</div>
            
       
                )
               break;
      }
}

    return (
        <div id="wrapper">
        <div class="content-page">
                <div class="content">

                 
                    <div class="container-fluid">

                    <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="javascript: void(0);">Tutor</a></li>
                <li className="breadcrumb-item active">Centre Detail</li>
              </ol>
            </div>
            <h4 className="page-title">Centre Detail</h4>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-lg-4">
          { displayStatus(status)}
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">Centre Information</h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-4">
                    <h5 className="mt-0">Centre Registred:</h5>
                    <p>#{ owner }</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-4">
                    <h5 className="mt-0">No of Student:</h5>
                    <p>{ numStud } </p>
                  </div>
                </div>
              </div>
              <h4 className="header-title mb-3">Centre Details</h4>
              <p className="mb-2"><span className="font-weight-semibold mr-2">Centre Name:</span> { centreName }</p>
             
              <p className="mb-2"><span className="font-weight-semibold mr-2">Email:</span>{ centreEmail }</p>
             
              <p className="mb-2"><span className="font-weight-semibold mr-2">Phone:</span> { centrePhone }</p>
              <p className="mb-2"><span className="font-weight-semibold mr-2">Address: </span> { centreAddress }</p>
              <p className="mb-0"><span className="font-weight-semibold mr-2">Date Created: </span> { dateRegistered }</p>

              <hr />
              { noti === null ? null : noti }
              { noteMe === false ? null :
              <button className="btn btn-info waves-effect btn-sm waves-light" type="button" disabled>
                
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait...
            </button> 
}
              {
               noteMe === false ?
              buttonDisplay(status) : null }
               
              &nbsp;&nbsp;&nbsp;
              { deleStatus === false ? 
              <div className="btn btn-danger waves-effect btn-sm waves-light" onClick={()=>centreStatus('delete')}><i className="mdi mdi-trash-can" /> Delete Centre</div>
                  :
                  <button className="btn btn-danger waves-effect btn-sm waves-light" type="button" disabled>
                
                  <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                  Deleting...
              </button> 
              }
            
            </div>
          </div>
       
       
        </div>
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <h4 className="header-title mb-3">All Student</h4>
              <div className="table-responsive">
                  { studentData === null ? notifyStud :
        <table className="table table-borderless table-nowrap table-hover table-centered m-0">
          <thead className="thead-light">
            <tr>
              <th>Student Name</th>
              <th>Grade</th>
              <th>Gender</th>
              <th>Student Race</th>
              <th>Year Appling</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
              { studentData && studentData.map(({firstname,lastname,grade,student_race,gender,year_applying,student_id,userid},index)=>{
                    return (
                        <tr key={index}>
                        <td>
                          <h5 className="m-0 font-weight-normal">{ firstname+' '+lastname }</h5>
                        </td>
                        <td>
                         { grade}
                        </td>
                        <td>
                          {gender}
                        </td>
                        <td>
                          {student_race}
                        </td>
                        <td>
                          { year_applying }
                        </td>
                        <td>
                          <div className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> history('/student-data/details/'+student_id+'/'+userid)} ><i className="mdi mdi-eye" /> view</div>
                        </td>
                      </tr>
                  
                    )
              })}
           </tbody>
        </table>
}
      </div> {/* end .table-responsive*/}
            </div>
          </div>
        </div>
      </div>




                    </div>
                    </div>
                    </div>

          
        </div>
    )
}

export default CentreDetails
