import React,{useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios';

function OutResetPass() {

      const [urout, setUrout] = useState();
       const [email, setEmail] = useState('');
        const [pass1, setPass1] = useState('');
        const [pass2, setPass2] = useState('');
       const [ucode, setUcode] = useState('');
       const [status, setStatus] = useState(null);
       const [checkCode, setCheckCode] = useState();
       const [uid, setUid] = useState();
   

    
       const HandleReset = (type) =>{

                switch(type){
                     case 'email':
                        if(email === ''){
                            setStatus(<div className='alert alert-info text-center'>Please provide email address</div>)
                       }else
                       {
                             axios.post('/email-confirm',{email:email,req_type:'email'},{
                                headers: { 'Content-Type': 'application/json'}
                             }).then((res)=>{
                                  if(res.data.mstatus){
                                        setUrout('request-code');
                                        setCheckCode(res.data.verifyCode);
                                        setUid(res.data.uid);

                                  }else
                                  {
                                    setStatus(<div className='alert alert-info text-center'>Email address does not exist</div>)
                                  }
                             })
                       }
                         break;
                    case 'code':
                        if(ucode === ''){
                            setStatus(<div className='alert alert-info text-center'>Please provide verification Code</div>)
                          }else
                          {
                               if(ucode != checkCode){
                                setStatus(<div className='alert alert-info text-center'>Verification code is invalid</div>)
                               }else
                               {
                                axios.post('/email-confirm',{ucode:ucode,email:email,req_type:'code'},{
                                    headers: { 'Content-Type': 'application/json'}
                                 }).then((res)=>{
                                      if(res.data.mstatus){
                                            setUrout('request-pass');
                                        
                                      }else
                                      {
                                        setStatus(<div className='alert alert-info text-center'>Invalid verification code</div>)
                                      }
                                 })
                               }
                          }
                        break;
                     case 'pass':
                             if(pass1 === '' || pass2 === ''){
                                setStatus(<div className='alert alert-info text-center'>All form field must be fill</div>)
                                   
                             }else
                             {
                                   if(pass1 !== pass2){
                                    setStatus(<div className='alert alert-info text-center'>Password did not match</div>)
                                   
                                   }else
                                   {
                                    axios.post('/email-confirm',{pass:pass1,req_type:'pass',email:email},{
                                        headers: { 'Content-Type': 'application/json'}
                                     }).then((res)=>{
                                          if(res.data.mstatus){
                                                setUrout('request-success');
                                               
                                          }else
                                          {
                                            setStatus(<div className='alert alert-info text-center'>Error occur when processing your request</div>)
                                          }
                                     })
                                   } 
                                
        
                             }

                        break
                }
              
       }


    const successReq = () =>{
        return (

            <div className="mt-3 text-center">
        
              <h3>Success !</h3>
              <p className="text-muted font-14 mt-2"> Your admin password has been reset successully. Kindly click on the login button below to go back to login page</p>
              <Link to='/login' className="btn btn-block btn-pink waves-effect waves-light mt-3">Back to Login</Link>
            </div>
          );
    }


    

    const resetPassword = ()=>{
        return(  <form>
             { status === null ? null : status }
            <div className="form-group mb-3">
              <label htmlFor="emailaddress">Enter New Password</label>
              <input className="form-control" type="email" id="emailaddress" onChange={(e)=> setPass1(e.target.value)} required placeholder="New Password" />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="emailaddress">Enter Confirm Password</label>
              <input className="form-control" type="email" id="emailaddress" onChange={(e)=> setPass2(e.target.value)} required placeholder="Confirm Password" />
            </div>
            <div className="form-group mb-0 text-center">
              <button className="btn btn-primary btn-block" onClick={()=> HandleReset('pass')} type="button"> Submit </button>
            </div>
          </form>
          )
    }

    const confirmCode = () =>{
      return(  <form>
           { status === null ? null : status }
        
        <div className="form-group mb-3">
          <label htmlFor="emailaddress">Enter Code</label>
          <input className="form-control" type="text" id="emailaddress" onChange={(e)=> setUcode(e.target.value)} required placeholder="Verification Code" />
        </div>
        <div className="form-group mb-0 text-center">
          <button className="btn btn-primary btn-block" onClick={()=> HandleReset('code')} type="button"> Submit </button>
        </div>
      </form>
      )
    }


    const EmailRequest = () =>{
         return(
                    
                  <form>
                      { status === null ? null : status }
                    <div className="form-group mb-3">
                      <label htmlFor="emailaddress">Email address</label>
                      <input className="form-control" onChange={(e)=> setEmail(e.target.value)} type="email" id="emailaddress" required placeholder="Enter your email" />
                    </div>
                    <div className="form-group mb-0 text-center">
                      <button  className="btn btn-primary btn-block" onClick={()=> HandleReset('email')} type="button"> Submit </button>
                    </div>
                  </form>
           
         )
    }

    const uRoutPages = () =>{
                switch(urout){
                      case 'request-email':
                         
                           return(
                               <div>
                                   <p className="text-muted mb-4 mt-3">Enter your email address and we'll send
                          you an email with instructions to reset your password.</p>
                                  { EmailRequest() } 
                               </div>
                           
                           )
                         
                          break;
                      case 'request-pass':
                         
                        return(
                            <div>
                                <p className="text-muted mb-4 mt-3">Enter your new and confirm password to complete password reset</p>
                            {resetPassword()}
                            </div>
                        )
                          break;
                        case 'request-code':
                                return(
                                <div>
                                    <p className="text-muted mb-4 mt-3">Enter the code that was sent to your email address.</p>
                                    {  confirmCode() }
                                </div>
                            )
                            
                           break;
                        case 'request-success':
                              return(
                                  <div>
                                    {  successReq() }
                                  </div>
                                  
                              )
                               break;
                }
    }


    

useEffect(()=>{
  setUrout('request-email')
},[])

     
  return (

        

     <div className="account-pages mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card bg-pattern">
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">

                    <div className="auth-logo">
                        <a href="/" className="logo logo-dark text-center">
                          <span className="logo-lg">
                            <img src="../assets/images/eq4.png" alt="" height={40} />
                          </span>
                        </a>
                        <a href="/" className="logo logo-light text-center">
                          <span className="logo-lg">
                            <img src="../assets/images/eq4.png" alt="" height={40} />
                          </span>
                        </a>
                      </div>
                
                  </div>
                   

                    { uRoutPages() }


                </div> {/* end card-body */}
              </div>
              {/* end card */}
              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-black-50">Back to <Link to="/login" className="text-blue ml-1"><b>Log in</b></Link></p>
                </div> {/* end col */}
              </div>
              {/* end row */}
            </div> {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
     
  )
}

export default OutResetPass