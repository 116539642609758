import axios from 'axios';
import React,{useState} from 'react';
import download from 'downloadjs'
import $ from 'jquery';
import { useEffect } from 'react';

function InvigilatorDownload() {
    const [loader, setLoader] = useState(false);
    const [loaderSubject, setLoaderSubject] = useState(false);
    const [notifyAdd, setNotifyAdd] = useState(null);
    const [subject, setSubject] = useState(null);
    const [subjectID, setSubjectID] = useState();
    const [mgrade, setMgrade] = useState('');
    const [msubject, setMsubject] = useState('');
    const [categoryType, setCategoryType] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [questionEng, setQuestionEng] = useState('');
    const [questionAfr, setQuestionAfr] = useState('');
    const [memoEng, setMemoEng] = useState('');
    const [memoAfr, setMemoAfr] = useState('');

    const [subcate, setSubCate] = useState('')
    const [assessment, setAssessment] = useState('');
    const [searchSubj, setSearchSubj] = useState(null);
    const [subjectSrch, setSubjectSrch] = useState(null);

    const [viewCate, setViewCate] = useState('');
    const [viewType, setViewType] = useState('');
    const [viewGrade, setViewGrade] = useState('');
    const [viewSubject, setViewSubject] = useState('');

    const [downloadData, setDownloadData] = useState();
    const [notices, setNotices] = useState(null);
    const [display, setDisplay] = useState(false);
    const [subjectName,setSubjectName] = useState();
    const [year, setYear] = useState();
    const [gradeRSubject, setGradeRSubject] = useState();

    const [lang, setLang] = useState();
    const [invigilatorID, setInvigilatorID] = useState();

    const [score, setScore] = useState('');
    const [topic, setTopic] = useState('');
    const [gradeR_Question, setGradeR_Question] = useState('');
    const [noteQ, setNoteQ] = useState(null);
    const [editQuest, setEditQuest] = useState(false);
    const [questStatus, setQuestStatus] = useState(null);
    const [questionEdit, setQuestionEdit] = useState('');
    const [etopic, setETopic] = useState('');
    const [escore, setEScore] = useState('');
    const [topicID, setTopicID] = useState();
    const [editNote, setEditNote] = useState(null);
    const [editTopicTerm, setEditTopicTerm] = useState('');
    const [TopicTerm, setTopicTerm] = useState(viewType);
    const [viewTopicTerm, setViewTopicTerm] = useState(viewType);
    const [newSub, setNewSub] = useState(null);
    const [downloadID, setDownloadID] = useState();
    const [remstatus, setRemStatus] = useState(null);
    const [downloadStatus, setDownloadStatus] = useState(null);
    const [langer, setLanger] = useState();
    const [myear, setMyear] = useState('');

   
    const category = [
        {cate:'Task',id:2},
       {cate:'Test',id:1},
      {cate:'Assignment',id:3},
      {cate:'Examination',id:4},
      {cate:'Project',id:5},
      {cate:'Practical',id:6}  
];

const ucate = [
  {cater:'Term 1',id:2},
 {cater:'Term 2',id:1},
{cater:'Term 3',id:3},
{cater:'Term 4',id:4},
];

const gradeData = [
   {grade:'R',id:1},
  {grade:'1',id:2},
  {grade:'2',id:3},
  {grade:'3',id:4},
  {grade:'4',id:5},
  {grade:'5',id:6},
  {grade:'6',id:7},
  {grade:'7',id:8},
  {grade:'8',id:9},
  {grade:'9',id:10},
  {grade:'10',id:11},
  {grade:'11',id:12},
  {grade:'12',id:13}   
];


const emptyUpload = () =>{
       $("input").val();
        setViewGrade('');
        setMsubject('');
        setCategoryType('');
        setDueDate('');
        setSubCate('');
        setAssessment('');
      
}
const getLang = (grd) =>{
        setMgrade(grd);
       getSubject(grd);

}

const getSubject = (gradID) =>{
             
       axios.get('/get-subject-cate/'+gradID,{
            headers:{ 'Content-Type': 'application/json'}
       }).then((response)=>{
              
             if(response.data.subjectData === 'not allow'){
                    setNotifyAdd(<div className="alert alert-info text-center">No subject available for grade { gradID}</div>);
                      setSubject(null);
             }else
             {
                   setSubject(response.data.subjectData);
             }
       })
}


const srtSubject = (gradID) =>{
      setViewGrade(gradID);
}

const updateDownload = async (e) => {
  e.preventDefault();

  // Validate the form inputs
  if (
    viewGrade === '' || msubject === '' || categoryType === '' ||
    dueDate === '' || subcate === '' || assessment === ''
  ) {
    setNotifyAdd(
      <div className='alert alert-info text-center'>
        Please check if some form inputs are missing
      </div>
    );
    return;
  }

  // Reset notifications and show loader
  setNotifyAdd(null);
  setLoaderSubject(true);

  // Prepare FormData
  const formData = new FormData();
  formData.append('year', year);
  formData.append('grade', viewGrade);
  formData.append('subjectid', msubject);
  formData.append('category', categoryType);
  formData.append('dueDate', dueDate);
  formData.append('subcate', subcate);
  formData.append('assessment', assessment);
  formData.append('subject', subjectName);
  formData.append('lang', lang);
  formData.append('downloadID', downloadID);

  // Conditionally append files if they exist
  if (questionEng) formData.append('questionEng', questionEng);
  if (questionAfr) formData.append('questionAfr', questionAfr);
  if (memoEng) formData.append('memoEng', memoEng);
  if (memoAfr) formData.append('memoAfr', memoAfr);

  try {
    // Make the POST request
    const response = await axios.post('/update-invigilatordownload', formData);

    setTimeout(() => {
      setLoaderSubject(false);

      if (response.data.status === false) {
        setNotifyAdd(
          <div className='alert alert-info text-center'>
            Unable to process your upload at this moment
          </div>
        );
      } else {
        setNotifyAdd(
          <div className='alert alert-success text-center'>
            {response.data.status}
          </div>
        );
      }
    }, 2000);

    console.log(response);
  } catch (err) {
    setLoaderSubject(false);
    setNotifyAdd(
      <div className='alert alert-danger text-center'>
        Something went wrong! Please try again later.
      </div>
    );
    console.error(err);
  }
};


    const lookSubjectLag = (lid) =>{
      setLanger(lid)
      axios.get('/admin-get-subjectLang/'+lid+'/'+viewGrade,{
       headers:{ 'Content-Type': 'application/json'}
      }).then((res)=>{
             if(res.data.subData === false){
                 setSubjectSrch(null)
             }else
             {
              setSubjectSrch(res.data.subData);
             }
      })
      
    }

    const getSubjectLang = (lID) =>{
          setLang(lID);
         axios.get('/admin-get-subjectLang/'+lID+'/'+viewGrade,{
          headers:{ 'Content-Type': 'application/json'}
         }).then((res)=>{
                if(res.data.subData === false){
                    setSearchSubj(null)
                }else
                {
                  setSearchSubj(res.data.subData);
                }
         })


    }

    const getDownloadSubject = (subID)=>{
      
      axios.get('/update-user-subject/'+subID,{
        headers:{ 'Content-Type': 'application/json'}
      }).then((res)=>{
             console.log(res.data.gradeData[0].subject)
            setSubjectName(res.data.gradeData[0].subject);

            
      })
    }

    const doSubject = (subID) =>{
      setMsubject(subID);
      getDownloadSubject(subID)
    }

  const viewInvigilatorDownload = () =>{
   
          if(viewGrade === '' || viewSubject === '' || viewType === ''
            || viewCate === '' || myear === ''){
              setDisplay(false)
              setNotices(<div className='alert alert-success text-center'>Please select All category</div>);
            }else
            {
              getDownloadSubject(viewSubject)
                setLoader(true);
                axios.post('/get-invigilator-download',{ 
                     grade:viewGrade,
                     cate:viewCate,
                     subject:viewSubject,
                     lang:lang,
                      term:viewType,
                      year:myear
                },{
                  headers:{ 'Content-Type': 'application/json'}
                }).then((respond)=>{
                       setTimeout(()=>{
                              setLoader(false);

                         if(respond.data.vigilatorData === false){
                          setDisplay(false)
                          setNotices(<div className='alert alert-success text-center'>No data found for the selected category</div>);
                         }else
                         {
                              setNotices(null)
                              setDisplay(true)
                              setDownloadData(respond.data.vigilatorData);
                              if(respond.data.useData === 'use'){
                                  setNewSub('use');
                                setNotices(<div className='alert alert-success text-center'>No { viewCate } data for {viewType}. kindly use any of the { viewCate } shown below for {viewType} </div>);
                              }else{
                                setNewSub(null);
                              }
                         }
                       },2000)
                })
                    
            }
  }


   const downloadQuestAnsw = (type,downloadId) =>{
                    setDownloadStatus('dk');
                  axios.post('/download-question',{
                      category:type,
                      downloadID:downloadId
                  },{
                    responseType: 'blob'
                  }
                  
                  ).then((response)=>{
                    setDownloadStatus(null)
                    const content = response.headers['content-type'];
                    download(response.data, type+'.pdf', content)
                    
                    
                  })
   }

    const GradeRQuestSet = (subject,invig) =>{
             setInvigilatorID(invig);
             setGradeRSubject(subject);
            

    }

    const viewTopicQuest = () =>{
      axios.get('getGeneralQuestionIvd/'+viewGrade+'/'+invigilatorID,{
        headers:{ 'Content-Type': 'application/json'}
       }).then((res)=>{
            if(res.data.status === false){
                    setQuestStatus(null)
            }else{
                  setQuestStatus(res.data.status);
            }
       })
    }

    const processGradeRQuestion = () =>{

              if(viewGrade === 'R'){
           
          if(score ==='' || topic === '' || gradeR_Question === ''){
                  setNoteQ(<div className="alert alert-danger text-center">All form field must be fill before submitting</div>);
          }else
          {
                let uData = {grade:viewGrade, subjectID:viewSubject, score:score, topic:topic,
                   question:gradeR_Question, invigiDownloadID:invigilatorID,subject:gradeRSubject, TopicTerm:TopicTerm, year:myear}
                axios.post('/grade-r-question',uData,{
                  headers:{ 'Content-Type': 'application/json'}
                }).then((res)=>{
                  setNoteQ(<div className="alert alert-info text-center">{ res.data.status }</div>);
                })

          }
        }else{


          if(score ==='' || gradeR_Question === ''){
            setNoteQ(<div className="alert alert-danger text-center">All form field must be fill before submitting</div>);
    }else
    {
          let uData = {grade:viewGrade, subjectID:viewSubject, score:score,topic:'none',
             question:gradeR_Question, invigiDownloadID:invigilatorID,subject:gradeRSubject, TopicTerm:viewType, year:myear}
          axios.post('/grade-r-question',uData,{
            headers:{ 'Content-Type': 'application/json'}
          }).then((res)=>{
            setNoteQ(<div className="alert alert-info text-center">{ res.data.status }</div>);
          })

    }

        }

    }

    const controlEditQuest = (question,topic_id,score,topic,term) =>{
              setEditQuest(true);
              setQuestionEdit(question);
              setETopic(topic);
              setTopicID(topic_id);
              setEScore(score);
              setViewTopicTerm(term)

              


}

   const removeTopicData = (tid) =>{
       
            axios.get('remove-topic/'+tid,{
              headers:{ 'Content-Type': 'application/json'}
            }).then((res)=>{
                
                   
             
                      setEditNote(res.data.status);
                       notifyMes()
                   
                
            })
   }

   const updateTopicQuest = ()=>{
        setLoaderSubject(true)

         if(viewGrade === 'R'){

          if(questionEdit === '' || etopic==='' || escore===''){
            setEditNote('Please make sure some form input are not empty');
             notifyMes()
          }else{
        const mData = {quest:questionEdit,topicID:topicID,topic:etopic,score:escore,editTopicTerm:viewType}
       axios.post('/update-topicquest',mData,{
        headers:{ 'Content-Type': 'application/json'}
       }).then((res)=>{
          setTimeout(()=>{
            setEditQuest(false);
            setLoaderSubject(false)
            setEditNote(res.data.ustatus);
             notifyMes()
          },3000)
       
       })
      } 


         }else{
         
         if(questionEdit === '' || escore===''){
            setEditNote('Please make sure some form input are not empty');
             notifyMes()
          }else{
        const mData = {quest:questionEdit,topicID:topicID,topic:'none',score:escore,editTopicTerm:editTopicTerm}
       axios.post('/update-topicquest',mData,{
        headers:{ 'Content-Type': 'application/json'}
       }).then((res)=>{
          setTimeout(()=>{
            setEditQuest(false);
            setLoaderSubject(false)
            setEditNote(res.data.ustatus);
             notifyMes()
          },3000)
       
       })
      } 
    }


   }

   const notifyMes = ()=>{
          setTimeout(()=>{
            setEditNote(null)
          },3000)
   }

   const UseTopic = (uid) =>{
           axios.post('/usedownload-nextterm',{uid:uid,term:viewType},{
            headers:{ 'Content-Type': 'application/json'}
           }).then((res)=>{
            setNotices(<div className='alert alert-success text-center'>{res.data.mstate} </div>);
                             
           }) 
   }


   const editDownloadQuest = (inID) =>{
          axios.get('/get-download-invig/'+inID,{
            headers:{ 'Content-Type': 'application/json'}
          }).then((res)=>{
                  setAssessment(res.data.downladData[0].assessment);
                  setYear(res.data.downladData[0].year);
                  setCategoryType(res.data.downladData[0].download_type)
                  setSubCate(res.data.downladData[0].terms);
                  setViewGrade(res.data.downladData[0].grade);
                  setLang(res.data.downladData[0].lang);
                  setSubject(res.data.downladData[0].subject);
                  setSubjectID(res.data.downladData[0].subject_id);
                  setDueDate(res.data.downladData[0].due_date);
                  setDownloadID(res.data.downladData[0].invigilator_id);
                  setMsubject(res.data.downladData[0].subject_id)
          })
   }


   const removeDownload = (cate,type,downloadid) => {
        const uData = {cate:cate,type:type,downloadID:downloadid}
          axios.post('/remove-download-data',uData,{
            headers:{ 'Content-Type': 'application/json'}
          }).then((res)=>{
              setRemStatus(<div className='alert alert-info text-center'>{res.data.status}</div>)
          })   
   }


   useEffect(()=>{
    let min = 2020,
    max = 2040,
   select = document.getElementById('year');

for (var i = min; i<=max; i++){
   var opt = document.createElement('option');
   opt.value = i;
   opt.innerHTML = i;
   select.appendChild(opt);
}
   },[])

    return (
        <div id="wrapper">
            <div className="content-page">
        <div className="content">
        <div className="container-fluid">

        <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    
                  </div>
                  <h4 className="page-title">Invigilator Upload</h4>
                </div>
              </div>
            </div>     



             
            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-10">
                      <form className="form-inline">
                       
                        <div className="form-group mx-sm-3 ">


                        <select style={{marginRight:10}} className="custom-select" id="year" onChange={(e)=>setMyear(e.target.value)}>
                           <option>Select year</option>
                          
                          </select>
                         

                          <select className="custom-select" id="status-select" onChange={(e)=>setViewCate(e.target.value)}>
                            <option value=''> Category </option>
                            { category.map((data) =>(
                                    <option key={data.id} value={data.cate}>{ data.cate }</option>
                            ))}
                          </select>


                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=>setViewType(e.target.value)}>
                            <option value=''> Term </option>
                            { ucate.map((data) =>(
                                    <option key={data.id} value={data.cater}>{ data.cater }</option>
                            ))}
                          </select>

                            
                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=> srtSubject(e.target.value)}>
                          <option value=''> Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>


                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=> getSubjectLang(e.target.value)}>
                          <option value="">Language</option>
                              <option value="Afrikaans">Afrikaans</option>
                              <option value="English">English</option>
                          </select>

                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=> setViewSubject(e.target.value)}>
                          <option value=''>Subject</option>
                           
                            {  searchSubj === null ? <option value='' disabled>Not available</option> :
                            
                          searchSubj &&  searchSubj.map((data) =>(
                                  <option key={data.id} value={data.subject_id}>{ data.subject }</option>
                            ))}
                          </select>


                          <button type="button" style={{marginLeft:10}} onClick={()=> viewInvigilatorDownload()}  className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-eye mr-1" /> View Upload</button>
                          { loader !== false ?
                            <div class="d-flex" style={{marginLeft:10}}>
                                            <div class="spinner-border" role="status"></div>
                                        </div>
                                        : null }
                        </div>
                      </form>
                    </div>
                   
                  </div> {/* end row */}
                </div> {/* end card-box */}
              </div> {/* end col*/}
            </div>

      

            <div className="row">

              <div className="col-12">


              { notices !== null ? notices : null}
                        { display !== false ?
                                    <div>
                                     <div className="card">
                                         <div className="card-body">
                                             
                                            { remstatus === null ? null : remstatus }
                                             <div className="table-responsive">

                                                    { downloadStatus === null ? null : 
                                                     <div>
                                                     <div class="d-flex">
                                                     <div class="spinner-border" role="status"></div>
                                                 </div>
                                                   <p>Processing your request. Please wait...</p>
                                                 </div>
                                                    }
                                 
                                                 <table className="table table-centered table-nowrap table-striped" id="products-datatable">
                                                     <thead>
                                                     <tr>
                                                         <th style={{width: '20px'}}>
                                                         #
                                                         </th>
                                                         { newSub !== null ? <th>Use Action</th> : null}
                                                         <th>Assessment</th>
                                                         <th>Period</th>
                                                         <th>Type</th>
                                                         <th>Due Date</th>
                                                         <th>Question (Eng)</th>
                                                         <th>Question (Afr)</th>
                                                         <th>Memo (Eng)</th>
                                                         <th>Memo (Afr)</th>
                                                         <th style={{width: '85px'}}>Action</th>
                                                     </tr>
                                                     </thead>
                                                     <tbody>
                                                        { downloadData && downloadData.map(({id,invigilator_id,question_eng,question_afr,memo_eng,memo_afr,assessment,grade,period,download_type,subject,due_date,subject_id},index)=>{
                                                            return (
                                                                <tr key={index}>
                                                                   <td>{ index+1}</td>
                                                                    { newSub !== null ? 
                                                                    <td> <button type="button" onClick={()=>UseTopic(invigilator_id)}  className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#editgrade" data-backdrop="static">Use for { viewType }</button>
                                                                    </td>
                                                                    
                                                                    : null}
                                                                 
                                                                  <td>Grade: {grade +' '+subject+' '+assessment}</td>
                                                                  <td>{ period }</td>
                                                                  <td>{ download_type }</td>
                                                                  <td>{ due_date }</td>
                                                                  <td>{ question_eng === null ? 'None' : <div><div onClick={()=> downloadQuestAnsw('qEng',invigilator_id)} className="btn btn-blue waves-effect btn-sm waves-light">Download </div> <div onClick={()=> removeDownload('qEng','remove',invigilator_id) } className="btn btn-blue waves-effect btn-sm waves-light"> Remove </div> </div> }</td>
                                                                  <td>{ question_afr === null ? 'None' : <div><div onClick={()=> downloadQuestAnsw('qAfr',invigilator_id)} className="btn btn-blue waves-effect btn-sm waves-light">Download </div> <div onClick={()=> removeDownload('qAfr','remove',invigilator_id) } className="btn btn-blue waves-effect btn-sm waves-light"> Remove </div> </div> }</td>
                                                                  <td>{ memo_eng === null ? 'None' : <div> <div  onClick={()=> downloadQuestAnsw('mEng',invigilator_id)} className="btn btn-blue waves-effect btn-sm waves-light">Download </div> <div onClick={()=> removeDownload('mEng','remove',invigilator_id) } className="btn btn-blue waves-effect btn-sm waves-light"> Remove </div> </div> }</td>
                                                                  <td>{ memo_afr === null ? 'None' : <div><div  onClick={()=> downloadQuestAnsw('mAfr',invigilator_id)} className="btn btn-blue waves-effect btn-sm waves-light">Download </div> <div onClick={()=> removeDownload('mAfr','remove',invigilator_id) } className="btn btn-blue waves-effect btn-sm waves-light"> Remove </div> </div> }</td>
                                                                  
                                                                  
                                                                  <td> 

                                                                  <button onClick={()=>editDownloadQuest(invigilator_id)} type="button"  className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#editQuestionupload" data-backdrop="static"><i className="mdi mdi-pencil mr-1" />Edit</button>
                                                                  
                                                                 
                                                                  <button type="button"  data-toggle="modal"  className="btn btn-warning waves-effect btn-sm waves-light"  onClick={()=> GradeRQuestSet(subject,invigilator_id)} data-target="#instruct" data-backdrop="static" style={{marginLeft:5}}>View / Add / Edit { grade === 'R'? 'Question' : null } &amp; Topic</button>
                                                                    
                                 
                                                                    <button type="button" onClick={()=> removeDownload('qEng','delete',invigilator_id) }  className="btn btn-danger waves-effect btn-sm waves-light" style={{marginLeft:5}}><i className="mdi mdi-delete mr-1" /> Remove</button>
         
                                                                  </td>
                                                                </tr>
                                                            )
                                                        })}
                                                     </tbody>
                                                 </table>
                                 
                                             </div>
                                           
                                          
                                 
                                         </div> {/* end card-body*/}
                                     </div> {/* end card*/}
                                 </div>
                                 : null }
                                        

            </div>

            <div className="modal fade" id="instruct" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Add / Edit Topic</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
         
            <div className="modal-body p-4">

          
        <ul className="nav nav-pills navtab-bg nav-justified">
          <li className="nav-item">
            <a href="#home1" data-toggle="tab" aria-expanded="true" className="nav-link active">
              Add Topic &amp; Question
            </a>
          </li>
          <li className="nav-item">
            <a href="#profile1" onClick={()=>viewTopicQuest()} data-toggle="tab" aria-expanded="false" className="nav-link">
              Edit Topic &amp; Question
            </a>
          </li>
         
        </ul>
        <div className="tab-content">

          <div className="tab-pane show active" id="home1">
               { noteQ === null ? null : noteQ }
            <div className="row">

    
 
            <div className="col-md-3">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Score </label>
                <input type='text' className="form-control" id="field-7" onChange={(e)=> setScore(e.target.value)} placeholder="Enter Score" />

              </div>
            </div>

          
              { viewGrade === 'R' ?
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Topic { gradeRSubject }</label>
                <input type='text' className="form-control" id="field-7" placeholder="Enter Topic" onChange={(e)=> setTopic(e.target.value)}  />

              </div>
            </div>
            : null }

                            
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">{ viewGrade === 'R' ? 'Question' : 'Topic'} on { gradeRSubject }</label><br></br>
                <small style={{color:'red'}}>Hint: use this arrow tag shown in the example to create new { viewGrade ==='R'? 'question': 'topic'}. e.g: {'=>'} </small>
                <textarea type='text' className="form-control" id="field-7" rows={15} style={{resize:'none'}} onChange={(e)=> setGradeR_Question(e.target.value)} > </textarea>
              </div>
            </div>

            <div className="col-md-12">
            { loaderSubject === false ?
          <button type="submit" className="btn btn-info waves-effect waves-light" style={{float:'right'}} onClick={()=>processGradeRQuestion()}>Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Please wait...
                                                    </button> 
}

            </div>

              

            </div>
          </div>

          <div className="tab-pane" id="profile1">

             { questStatus === null ? <div className="alert alert-info text-center">Oops no data found for this subject</div> :
        
          editQuest !== false ? 
           <div>

             { editNote === null ? null : <div className="alert alert-info text-center">{editNote}</div>}

<div className="row">

<div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Topic { viewGrade === 'R' ? 'and Question' : null} is for</label>
                <select type='text' className="custom-select" id="field-7" onChange={(e)=>setEditTopicTerm(e.target.value)}>
                        <option>Select term</option>
                        <option value={ viewTopicTerm }>Current : { viewTopicTerm }</option>
                      
                       
                </select>

              </div>
            </div>
  
<div className="col-md-3">
  <div className="form-group no-margin">
    <label htmlFor="field-7" className="control-label">Score </label>
    <input type='text' className="form-control" id="field-7" value={escore} onChange={(e)=>setEScore(e.target.value)} placeholder="Enter Score" />

  </div>
</div>

        
{ viewGrade === 'R'?
<div className="col-md-12">
  <div className="form-group no-margin">
    <label htmlFor="field-7" className="control-label">Topic { gradeRSubject }</label>
    <input type='text' className="form-control" id="field-7" value={etopic} onChange={(e)=>setETopic(e.target.value)} placeholder="Enter Topic"  />

  </div>
</div>
: null }

                
<div className="col-md-12">
  <div className="form-group no-margin">
    <label htmlFor="field-7" className="control-label"> { viewGrade ==='R' ? 'Question' : 'Topic'} on { gradeRSubject }</label><br></br>
    <small style={{color:'red'}}>Hint: use this arrow tag shown in the example to create new { viewGrade ==='R'? 'question': 'topic'}. e.g: {'=>'} </small>
    <textarea className="form-control" id="field-7" rows={15} style={{resize:'none'}} value={questionEdit} onChange={(e)=> setQuestionEdit(e.target.value)}></textarea>
  </div>
</div>

<div className="col-md-12">

{ loaderSubject === false ?
<button type="submit" className="btn btn-info waves-effect waves-light" style={{float:'right'}} onClick={()=>updateTopicQuest()}>Update Details</button>
:
<button class="btn btn-primary" type="button" disabled style={{float:'right'}}>
                                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                            Please wait...
                                        </button> 
}


<button type="submit" className="btn btn-blue waves-effect waves-light"  onClick={()=>setEditQuest(false)}>Back</button>

</div>

  

</div>


             </div>
        :
        <div>
           { editNote === null ? null : <div className="alert alert-info text-center">{editNote}</div>}
        <table className="table">
              <thead>
                 <tr>
                   <th>#</th>
                  { viewGrade === 'R'?
                   <th>Topic</th>
                   : null }
                   <th>Score</th>
                   <th>Subject</th>
                   <th>Action</th>
                 </tr>
                </thead>
             <tbody>
            { questStatus && questStatus.map(({topic,score,subject,question,topic_id,term},index)=>{
                return (
              <tr key={index}>
                <td>{ index+1 } </td>
            
              { viewGrade === 'R'?
                   <td>{topic}</td>
                   : null }
              <td>{ score }</td>
              <td>{subject}</td>
              <td><button className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=>controlEditQuest(question,topic_id,score,topic,term)}>Edit</button> <button className="btn btn-danger waves-effect btn-sm waves-light" onClick={()=>removeTopicData(topic_id)}>Remove</button> </td>
            </tr>
                )
            })}
            </tbody>
          </table>
          </div>
}
          </div>
         
        </div>
     
         
        </div>
    
     
        
        
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}



      <div className="modal fade" id="editQuestionupload" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Edit Question</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <form onSubmit={updateDownload}>
            <div className="modal-body p-4">

              { notifyAdd !== null ? notifyAdd : null }



              <div className="row">
           
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Assessment </label>
                <input type='text' className="form-control" id="field-7" value={assessment} placeholder="Assessment" onChange={(e)=> setAssessment(e.target.value)}  />
              </div>
            </div>



          <div className="col-md-6">
              <div className="form-group no-margin">
              <label htmlFor="field-3" className="control-label">Year</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setYear(e.target.value)} >
                         
                            <option value={year}>Current Year: { year}</option>
                            <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                          </select>
              </div>
            </div>




          </div>

                
        

          <div className="row">

              
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Upload Type</label>
                <select className="custom-select " id="status-select" onChange={(e) => setCategoryType(e.target.value) } >
                            <option value={categoryType}>Current Cate: {categoryType}</option>
                            { category.map((data) =>(
                                    <option key={data.id} value={data.cate}>{ data.cate }</option>
                            ))}
                          </select>
              </div>
            </div>

            
            <div className="col-md-6">
              <div className="form-group no-margin">
              <label htmlFor="field-3" className="control-label">Select Term</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setSubCate(e.target.value)} >
                            <option value={subcate}>Current { subcate }</option>
                            { 
                              
                             ucate.map((data) =>(
                                    <option key={data.id} value={data.cater}>{ data.cater }</option>
                            ))}
                          </select>
              </div>
            </div>


            
          </div>




          <div className="row">
          <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Grade</label>
                <select className="custom-select " id="status-select" onChange={(e) => setViewGrade(e.target.value) } >
                            <option value={viewGrade}>Current : Grade { viewGrade }</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
              </div>
            </div>


            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Language of Instruct</label>
                <select className="custom-select " id="status-select" onChange={(e) => getSubjectLang(e.target.value) } >
                            <option value={lang}>Current: { lang }</option>
                              <option value="Afrikaans">Afrikaans</option>
                              <option value="English">English</option>
                          </select>
              </div>
            </div>

          </div>


          <div className="row">
            


            <div className="col-md-6">
              <div className="form-group no-margin">
              <label htmlFor="field-3" className="control-label">Select Subject</label>
                <select className="custom-select " id="status-select" onChange={(e)=> doSubject(e.target.value)} >
                            <option value={subjectID}>Current : { subject }</option>
                            {  searchSubj === null ? <option value='' disabled>Not available</option> :
                            
                            searchSubj &&  searchSubj.map((data) =>(
                                    <option key={data.id} value={data.subject_id}>{ data.subject }</option>
                              ))}
                          </select>
              </div>
            </div>



            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Due Date</label>
                <input type='date' className="form-control" id="field-7" value={ dueDate } placeholder="Due Date" onChange={(e)=> setDueDate(e.target.value)}  />
              </div>
            </div>


          </div>
          
        
        
        
          <div className="row">
          <div className="col-md-12">
            <hr></hr>
            </div>
            </div>
        

          <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Question Eng</label>
                <input type="file" id="example-fileinput" class="form-control-file"
                 accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e)=>setQuestionEng(e.target.files[0])}
                 />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Question Afri</label>
                <input type="file" id="example-fileinput" class="form-control-file"
                 accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e)=>setQuestionAfr(e.target.files[0])}
                 />
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Memo Eng</label>
                <input type="file" id="example-fileinput" class="form-control-file"
                 accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e)=>setMemoEng(e.target.files[0])}
                 />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Memo Afri</label>
                <input type="file" id="example-fileinput" class="form-control-file"
                 accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e)=>setMemoAfr(e.target.files[0])}
                 />
              </div>
            </div>
          </div>

          <div className="row">
          <div className="col-md-12">
            <hr></hr>
            </div>
            </div>
        
         
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
          { loaderSubject === false ?
          <button type="submit" className="btn btn-info waves-effect waves-light">Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Please wait...
                                                    </button> 
}
        </div>
        </form>
        
        
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


            </div>

            

            </div>
            </div>
        </div>
            
        </div>
    )
}

export default InvigilatorDownload
