import React,{useState, useEffect, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import MaterialTable from '@material-table/core'



let autoComplete;


const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
   
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current
  );
  autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const querys = addressObject.formatted_address;
  const longitude = addressObject.geometry.location.lng();
  const latitude = addressObject.geometry.location.lat();
  
   localStorage.setItem('lat',latitude);
   localStorage.setItem('lng',longitude);
  
  updateQuery(querys);

 
 // console.log(addressObject);

}


function TutorInfo() {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const [centreName, setCentreName] = useState("");
  const [centrePhone, setCentrePhone] = useState("");
  const [centreEmail, setCentreEmail] = useState("");
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [addressError, setAddressError] = useState('');

     const { id } = useParams();
    const [susLoad, setSusLoad] = useState(false);
    const [susState, setSusState] = useState(null);
    const [susNote, setSusNote] = useState(null);
    const [numCent, setNumCent] = useState();
    const [centreDat, setCentreDat] = useState(null);
    const [notCent, setNotCent] = useState(null);
    const [addCent, setAddCent] = useState(false);

    const history = useNavigate();

    const [tname, setTName] = useState();
    const [temail, setTEmail] = useState();
    const [tphone, setTPhone] = useState();
    const [tID, setTID] = useState();
    const [taddress, setTAddress] = useState();
    const [wallet, setWallet] = useState(0);
    const [dateReg, setDateReg] = useState();
    const [iDNumber, setIDNumber] = useState();
    const [notifyWallet, setNotifyWallet] = useState(null);
    const [walletData, setWalletData] = useState(null);
    const [earn, setEarn] = useState(0);
    const [wrequest, setWRequest] = useState(0);

    const [load, setLoad] = useState(false);
    const [fund, setFund] = useState('');
    const [notifund, setNotifund] = useState(null)
    const [notify, setNotify] = useState(null);
    const [mregion, setMRegion] = useState(null);
    const [loadRegion, setLoadRegion] = useState();
    const [region,setRegion] = useState();
    const [loading, setLoading] = useState(false);

    const [repNotis, setRepNotis] = useState();
    const [tass, setTass] = useState(null);
    const [ututor, setUtutor] = useState(null);
    const [fundType, setFundType] = useState('');
    const [reason, setReason] = useState('');


    const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }


      const checkError = () =>{
        let valid = true;
        if(!centreName.trim()){
              setNameError('Please provide centre name');
              valid = false
        }else
        {
            setNameError('');
              valid = true;
        }
        

        if (!centreEmail.trim()) {
            setEmailError('Please enter your email address');
            valid = false;
              
        }else if (!/\S+@\S+\.\S+/.test(centreEmail)) {
          setEmailError('Please provide a valid email address');
          valid = false;
           
        }else{
           setEmailError('');
           valid = true;
        }

        if (!centrePhone.trim()) {
            setPhoneError('Please provide your phone number');
            valid = false;
            
        }else
        {
            setPhoneError('')
            valid = true;
        }

        if (!query.trim()) {
            setAddressError('Please provide your centre address');
            valid = false
            
        }else
        {
          valid = true;
           setAddressError('');
        }

         return valid;
  }


  const handleSubmit = (e) =>{
              
    e.preventDefault();
    const lat = localStorage.getItem('lat');
    const lng = localStorage.getItem('lng');


        if(!checkError()){}else{

        axios.post('/create-centre',{
            centreName:centreName,
            centreEmail:centreEmail,
            centrePhone:centrePhone,
            centreAddress: query,
            lat:lat,
            lng:lng,
            region:region,
            ufrom:id

        },{
          headers: { 'Content-Type': 'application/json'}
       })
        .then((response)=>{
                    setLoading(true)
               setTimeout(()=>{
                     setLoading(false);
                   
                  if (response.data.message === true) {
                        setNotify('Your centre was created successfully')
                  }else
                  {
                    setNotify(response.data.message);
                  }

               },2000);
        })
      }
 }





    const SuspendAcct = (status) =>{
        setSusState(status);
        setSusLoad(true);
    const spend = {
         status:status,
         parentID:id
    }

    axios.post('/suspend-users',spend,{
        headers: { 'Content-Type': 'application/json'}
    }).then((res)=>{
           setTimeout(()=>{
            setSusLoad(false);
                    if(res.data.motify === false){
                        setSusNote( <div className="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show" role="alert">
                        
                      Server error occur when trying to perform your request
                    </div>)
                    }else{
                        setSusNote( <div className="alert alert-primary alert-dismissible bg-primary text-white border-0 fade show" role="alert">
                        
                        { status === 1 ? 'Suspension of this account was successful' : 
                        'Suspension on this account was remove successfully' }
                    </div>)
                    }


                  
           },2000);
    })
}

const addFundWallet = () =>{
            
  const funder ={
    fund:fund,
    current_amount:wallet,
    userid:id,
    reason:reason,
    fundType:fundType
}
     if(fundType === ''){
      setNotifund(<div className="alert alert-info text-center">Select Option of Operation</div>);
     }else{

        switch(fundType){
            case'fund':
            if(fund ===''){
              setNotifund(<div className="alert alert-info text-center">Amount field is required</div>);
         }else{
          setLoad(true);
          setNotifund(null)
      axios.post('/admin-addfund',funder,{
        headers: { 'Content-Type': 'application/json'}   
    }).then((res)=>{
             setTimeout(()=>{
                setLoad(false);
                setNotifund(<div className="alert alert-info text-center">{res.data.message}</div>);
                  if(res.data.total === false){

                  }else{
                        setWallet(res.data.total);
                  }
             },2000);
            
    })
              
         }
            break;
            case 'Deduct':

              if(fund === '' || reason === ''){
                setNotifund(<div className="alert alert-info text-center">Make all form input are not empty</div>);
              }else
              {
                setLoad(true);
                setNotifund(null)

                axios.post('/admin-addfund',funder,{
                  headers: { 'Content-Type': 'application/json'}   
              }).then((res)=>{
                       setTimeout(()=>{
                          setLoad(false);
                          setNotifund(<div className="alert alert-info text-center">{res.data.message}</div>);
                            if(res.data.total === false){
    
                            }else{
                                  setWallet(res.data.total);
                            }
                       },2000);
                      
              })
                   
              }
              break;
        }

     }
}

      const tutorWalletInfo = () => {
                axios.get('/mytutor-wallet-activity/'+id,{
                  headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{
                                if(res.data.earnData === false){
                                    setEarn(convertCurrency(0));
                           }else
                           {
                                    let wearn =0;
                                     res.data.earnData.map((amt)=>(
                                         wearn = parseInt(wearn) + parseInt(amt.amount)
                                     ))
                                     setEarn(convertCurrency(parseInt(wearn)));

                           }


                           
                           if(res.data.withRequestEarn === false){
                                        setWRequest(convertCurrency(0));

                          }else
                          {
                            let wrq =0;
                            res.data.withRequestEarn.map((amt)=>(
                                wrq = parseInt(wrq) + parseInt(amt.amount)
                            ))
                            setEarn(convertCurrency(parseInt(wrq)));

                          }

                          
                })
      }


       const HandleLocation = (addrr) =>{
           setQuery(addrr);
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
          () => handleScriptLoad(setQuery, autoCompleteRef)
        );
       }

        useEffect(()=>{

         
          const fetchRegion = () => {
            axios.get('/all-region',{
              headers: { 'Content-Type': 'application/json'},
              withCredentials:true
              
           })
            .then((response)=>{
                 if(response.data.allRegion === false){
                    setMRegion(<option disabled>No data available</option>)
                 }else{
                   
                  setLoadRegion(response.data.allRegion)
                 }
            })
        } 
       
          fetchRegion()

        

                axios.get('/tutorinfo-data/'+id,{
                  headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{
                        setNumCent(res.data.NumCentre)
                         console.log(res.data.CentreData)
                        if(res.data.CentreData === false){
                                setNotCent(<div className="alert alert-info text-center">This tutor has no centre</div>);
                        }else
                        {
                                setCentreDat(res.data.CentreData);
                        }

                        setWallet(res.data.tutorData[0].wallet);
                        setTName(res.data.tutorData[0].name);
                        setTEmail(res.data.tutorData[0].email);
                        setTPhone(res.data.tutorData[0].phone);
                        setTAddress(res.data.tutorData[0].address);
                        setTID(res.data.tutorData[0].id_number);
                        setDateReg(res.data.tutorData[0].date_reg);

                           if(res.data.transData === false){
                                        setNotifyWallet(<div className="alert alert-info">No Data Found</div>)
                           }else
                           {
                                  setWalletData(res.data.transData);
                           }
                            
                })

                tutorWalletInfo();
        },[]);

        const updatePassword = () =>{
          axios.post('/update-parent-passdata',{id:id},{
            headers: { 'Content-Type': 'application/json'} 
          }).then((res)=>{
               setTass('tap')
              if(res.data.status === false){
                   setRepNotis(res.data.message)
                    
              }else{
                setRepNotis(res.data.message)
              }
          })   
   }

         const updateTutorInfoData = () =>{

          if(taddress === '' || temail === '' || tphone === '' || tname ===''){
            setUtutor(<div className='alert alert-info text-center'>make sure all form field are fill correctly</div>);
     }else
     {
        let dataDet = { address:taddress,email:temail,pID:tID,phone:tphone,name:tname,userid:id,utype:'tutor'}
         axios.put('/update-parent-data-details',dataDet,{
          headers: { 'Content-Type': 'application/json'} 
         }).then((res)=>{
          setUtutor(<div className='alert alert-info text-center'>{res.data.ustatus}</div>);
         })
     }

         }

        const passwordReset = () =>{
          return (
  
           <div id="password-reset" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
               <div class="modal-dialog modal-sm">
                   <div class="modal-content modal-filled bg-danger">
                       <div class="modal-body p-4">
                           
                           <div class="text-center">
                               <i class="dripicons-wrong h1 text-white"></i>
 
                               <h4 class="mt-2 text-white">Oh snap!</h4>
                               
                               <p class="mt-3 text-white">
                                 { tass === null ?
                                 <div>
                                 You are about to reset the password of this user account profile.
                                New password will be sent to this user login email address
                                 </div>
                                 : <div> { repNotis } </div> }
                             
                               </p> 
 
                               <button type="button" class="btn btn-light btn-sm waves-effect waves-light" onClick={()=> updatePassword()} >Comfirm Reset</button>
 
                               <button type="button" className="btn btn-danger waves-effect btn-sm waves-light" data-dismiss="modal">  Cancel</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
 
 
         
          )
     }
 
 
     const UserInfoEdit = () =>{
          return (
 
       <div className="modal fade" id="editUserInfo" tabIndex={-1} role="dialog" aria-hidden="true">
       <div className="modal-dialog modal-dialog-centered">
         <div className="modal-content">
           <div className="modal-header">
             <h4 className="modal-title" id="myCenterModalLabel">Profile Update</h4>
             <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
           </div>
           <div className="modal-body p-4">
          { ututor === null ? null : ututor }
        
         <div className="row">
          
           <div className="col-md-12">
             <div className="form-group no-margin">
               <label htmlFor="field-7" className="control-label">Full-Name</label><br></br>
               <input type="text" className="form-control" id="field-7" value={tname} onChange={(e)=>setTName(e.target.value)}  placeholder="fullname"  />
             </div>
           </div>
 
 
           <div className="col-md-6">
             <div className="form-group no-margin">
               <label htmlFor="field-7" className="control-label">Email</label><br></br>
               <input type="email" className="form-control"  id="field-7" value={temail} onChange={(e)=>setTEmail(e.target.value)} placeholder="Email"  />
             </div>
           </div>
 
           <div className="col-md-6">
             <div className="form-group no-margin">
               <label htmlFor="field-7" className="control-label">Phone</label><br></br>
               <input type="number" className="form-control" id="field-7" value={tphone} onChange={(e)=>setTPhone(e.target.value)} placeholder="Phone"  />
             </div>
           </div>
 
           <div className="col-md-12">
             <div className="form-group no-margin">
               <label htmlFor="field-7" className="control-label">ID-Number</label><br></br>
               <input type="number" className="form-control" id="field-7" value={tID} onChange={(e)=>setTID(e.target.value)} placeholder="ID-Number"  />
             </div>
           </div>
 
           <div className="col-md-12">
             <div className="form-group no-margin">
               <label htmlFor="field-7" className="control-label">Address</label><br></br>
               <input type="text" className="form-control" id="field-7" value={taddress} onChange={(e)=>setTAddress(e.target.value)} placeholder="Address"  />
             </div>
           </div>
 
 
         </div>
       
 
       
       </div>
       <div className="modal-footer">
         <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
        
         
         <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> updateTutorInfoData()} >Submit</button>
   
 
 
       </div>
         </div>{/* /.modal-content */}
       </div>{/* /.modal-dialog */}
     </div>
          )
     }

    return (
        <div id="wrapper">
        <div className="content-page">
    <div className="content">
      {/* Start Content*/}
      <div className="container-fluid">



      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="form-inline">
                <div className="form-group">
                  <div className="input-group input-group-sm">
                  <button type="button" className="btn btn-warning waves-effect btn-sm waves-light" onClick={()=> setAddCent(true)}>  Create Centre</button>
                     &nbsp;&nbsp;
                     <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centermodal" data-backdrop="static"> <i className="mdi mdi-wallet-plus" /> Fund / Deduct From Wallet</button>
                     &nbsp;&nbsp;
                        { susLoad === false ? 
                         susState === null ?
                     <button type="button" className="btn btn-danger waves-effect btn-sm waves-light" onClick={()=>SuspendAcct(1)}>  Suspend Account</button>
                     :
                     <button type="button" className="btn btn-success waves-effect btn-sm waves-light" onClick={()=>SuspendAcct(null)}>  Unsuspend Account</button>
                  
                                :
                     <button className="btn btn-danger waves-effect btn-sm waves-light" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Please wait...
                                                    </button> 
}
                 
                  </div>
                </div>
               
              </form>
            </div>
            <h4 className="page-title">Tutor Dashboard</h4>
          </div>
        </div>
      </div>


      { susNote === null ? null : 
     
     susNote 
 
}


<div className="row">
          
          <div className="col-md-6 col-xl-3">
            <div className="card-box">
              <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title data-original-title="More Info" />
              <h4 className="mt-0 font-16">Wallet Balance</h4>
              <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ wallet=== null ? 'R0.00' : convertCurrency(parseInt(wallet)) }</span></h2>
              </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="card-box">
              <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title data-original-title="More Info" />
              <h4 className="mt-0 font-16">Total Earning</h4>
              <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ earn }</span></h2>
             </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="card-box">
              <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title data-original-title="More Info" />
              <h4 className="mt-0 font-16">Withdrawal Request</h4>
              <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ wrequest }</span></h2>
             </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="card-box">
              <i className="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title data-original-title="More Info" />
              <h4 className="mt-0 font-16">Registered Centre(s)</h4>
              <h2 className="text-primary my-3 text-center"><span data-plugin="counterup">{ numCent }</span></h2>
            
            </div>
          </div>
        </div>
        

        <div className="row">

        <div className="col-lg-4">
        <div className="card-box">
          <div className="media mb-3">
            
            <div className="media-body">
              <h4 className="mt-0 mb-1 text-uppercase">{ tname }</h4>
              <p className="text-muted">Tutor</p>
             
             
            </div>
          </div>
          <h5 className="mb-3 mt-4 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1" /> Personal Information</h5>
          <div>
           
            <h4 className="font-13 text-muted text-uppercase mb-1">Email :</h4>
            <p className="mb-3"> {temail}</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Phone :</h4>
            <p className="mb-3">{tphone}</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Address :</h4>
            <p className="mb-3"> {taddress}</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Date Reg :</h4>
            <p className="mb-0"> { dateReg }</p>
          </div>
          <br></br>
          <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#editUserInfo" data-backdrop="static"> Edit Information</button>&nbsp;&nbsp;&nbsp;
            <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#password-reset"> Reset user Password</button>     
        </div> {/* end card-box*/}
      </div>
       
        { addCent !== false ?
        <div className="col-lg-8">

          <div className="card-box">
          <h4 className="header-title mb-3">Add Centre</h4>
          { notify !== null ? (<div className="alert alert-info text-center">{ notify }</div>) : null }
          <form onSubmit={handleSubmit} noValidate autoComplete='off'>
                   
                   <div className="form-floating mb-3">
                   <label htmlFor="floatingemailInput">Centre Name</label>
                     <input type="text" className="form-control" name="centreName" 
                     id="centreName" placeholder="Enter Your Full Names" 
                     value={centreName} onChange={(e)=> setCentreName(e.target.value)} />
                    
                     { nameError !=='' ? <p style={{color:'red'}}>{ nameError }</p> : null }
                   
                   </div>
                 
                 
                 <div className="row">
                   <div className="col-md-6">
                     <div className="form-floating mb-3">
                     <label htmlFor="floatingemailInput">Centre Email address</label>
                       <input type="email" className="form-control"
                        name="centreEmail" id="email" placeholder="Enter Email address" value={centreEmail} onChange={(e)=> setCentreEmail(e.target.value)}  />
                      
                       { emailError !=='' ? <p style={{color:'red'}}>{ emailError }</p> : null }
                      
                     </div>
                   </div>
                   <div className="col-md-6">
                     <div className="form-floating mb-3">
                     <label htmlFor="floatingemailInput">Centre Phone Number</label>
                       <input type="number" className="form-control" name="centrePhone"
                        id="phone" placeholder="Enter Phone Number" value={centrePhone} onChange={(e)=> setCentrePhone(e.target.value)} />
                       
                       { phoneError !=='' ? <p style={{color:'red'}}>{ phoneError }</p> : null }
                     </div>
                   </div>
                 </div>

                 <div className="form-floating mb-3">
                 <label htmlFor="floatingSelectGrid">Select Region</label>
                 <select name="applying_year" className="custom-select" id="status-select" onChange={(e)=>setRegion(e.target.value)}>
                 { mregion !== null ? mregion :
                     loadRegion && loadRegion.map(yu =>(
                       <option key={yu.id} defaultValue={yu.location}>{ yu.location }</option>
                     ))
                 }
                         </select>
                        
                         </div>
                
                         <div className="form-floating mb-3">
                      <input type="text" className="form-control" name="centreAddress" id="address"
                      ref={autoCompleteRef}
                      onChange={ (e)=> HandleLocation(e.target.value)}
                       value={query}
                       placeholder="Enter Home Address" autoComplete="off" />
                      <label htmlFor="floatingnameInput">Centre Address</label>
                      { addressError !=='' ? <p style={{color:'red'}}>{ addressError }</p> : null }

                     
                    </div>


                 
                 <button type="button" className="btn btn-dark w-md submit" onClick={()=> setAddCent(false)}>Back to Centre </button>
              
                 <div style={{float: 'right'}}>

               
               { loading === false ?
                 <button type="submit" className="btn btn-primary w-md submit">Submit</button>
                     :
                 <button className="btn btn-primary waves-effect waves-light" disabled>

                 <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                   Please Wait...</button>
                   }


              </div>
               </form>
            </div>

        </div>
        :

      <div className="col-lg-8">
         
        
          { centreDat === null ? notCent :
           
           centreDat && <MaterialTable
            title="Registered Centre"
            columns={[
              { title: 'Centre Name', field: 'centreName' },
              { title: 'Phone', field: 'centrePhone' },
              { title: 'Email', field: 'centreEmail' },
              {title: 'Date Registered', field: 'date_reg'}
            
              
            ]}
            data= {
              centreDat
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => history('/centre/detials/'+rowData.center_id)
              }
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />
}         
      
      </div>
}
      {/* end col */}
    
       
       
        </div>

        <div className="row">


      <div className="col-lg-12">
        <div className="card-box">
         
          <h4 className="header-title mb-3">Wallet Activity</h4>
           { walletData === null ? notifyWallet :
          <div className="table-responsive">
            <table className="table table-borderless table-nowrap table-hover table-centered m-0">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>Amount</th>
                  <th>Memo</th>
                  <th>Date</th>
                  <th>Ref</th>
                </tr>
              </thead>
              <tbody>
                   { walletData && walletData.map(({amount,details,date_trans,ref},index)=>{
                        return(
                <tr key={index}>
                    <td>{1+index }</td>
                  <td>
                    <h5 className="m-0 font-weight-normal">{ amount}</h5>
                  </td>
                  <td>
                    {details}
                  </td>
                  <td>
                    { date_trans}
                  </td>
                  <td>
                    { ref }
                  </td>
                 
                </tr>
                
                        )
                   })}
                
                  
                
              
              </tbody>
            </table>
          </div> 
}
       </div> {/* end card-box*/}
      </div> {/* end col */}
    
       

        </div>



        <div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Fund Wallet or Deduct From Wallet</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">
            { notifund === null ? null : notifund }
         
          <div className="row">

          <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">I want to </label><br></br>
              
                <select className="custom-select" id="status-select" onChange={(e)=>setFundType(e.target.value)}>
                    <option value="">Option</option>
                              <option value="fund">Fund Wallet</option>
                              <option value="Deduct">Deduct From Wallet</option>
                           
                  </select>
              </div>
            </div>
            
              { fundType === '' ? null :
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Amount</label><br></br>
                <small>Enter the amount without adding dot example 2000, 3000</small>
                <input type="number" className="form-control" id="field-7" placeholder="Amount" onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() } onChange={(e)=>setFund(e.target.value)}  />
              </div>
            </div>
              }

              { fundType === 'Deduct' ?

            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Reason </label><br></br>
                
                <textarea className="form-control" id="field-7" onChange={(e)=>setReason(e.target.value)} placeholder="Address"   />
              </div>
            </div>
            : null

            }


          </div>
        
  
        
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
         
           { load === false ?
          <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> addFundWallet()} >Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> 
}

        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


      { passwordReset() }

      { UserInfoEdit() }


                 </div>
              </div>
          </div>
        </div>
    )
}

export default TutorInfo
