import React from 'react'
import { Link } from 'react-router-dom'


function ErrorPage({auth}) {
    return (
      <div id="wrapper">
        <div className="content-page">
        <div className="content">
          {/* Start Content*/}
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-xl-4 mb-4">
                <div className="error-text-box">
                  <svg viewBox="0 0 600 200">
                    {/* Symbol*/}
                    <symbol id="s-text">
                      <text textAnchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                    </symbol>
                    {/* Duplicate symbols*/}
                    <use className="text" xlinkHref="#s-text" />
                    <use className="text" xlinkHref="#s-text" />
                    <use className="text" xlinkHref="#s-text" />
                    <use className="text" xlinkHref="#s-text" />
                    <use className="text" xlinkHref="#s-text" />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="mt-0 mb-2">Whoops! Page not found </h3>
                  <p className="text-muted mb-3">It's looking like you may have taken a wrong turn. Don't worry...
                    it happens to the best of us. You might want to check your internet connection.
                    Here's a little tip that might help you get back on track.</p>

                    { auth !== false ?
                  <Link to="/"  className="btn btn-success waves-effect waves-light">Back to Dashboard</Link>
                  : 
                  <Link to="/login" className="btn btn-primary waves-effect waves-light">Back to Login</Link>
                    }

                </div>
                {/* end row */}
              </div> {/* end col */}
            </div>
            {/* end row */}
          </div> {/* container */}
        </div> {/* content */}
        {/* Footer Start */}
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                2015 -  © UBold theme by <a href>Coderthemes</a> 
              </div>
              <div className="col-md-6">
                <div className="text-md-right footer-links d-none d-sm-block">
                  <a href="javascript:void(0);">About Us</a>
                  <a href="javascript:void(0);">Help</a>
                  <a href="javascript:void(0);">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* end Footer */}
      </div>
        </div>
    )
}

export default ErrorPage
