import axios from 'axios';
import React,{useState} from 'react'
import {Link} from 'react-router-dom'

function Login({siteFoot,checkAdminLog}) {
  const curDate = new Date().getFullYear();

  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [notify, setNotify] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
        if(!email.trim() || !password.trim()){
          setNotify('Please email and password is required');
        }else
        {
          if (!/\S+@\S+\.\S+/.test(email)) {
            setNotify('Please provide a valid email address');
             
          }else{
           
              axios.post('/admin-login-data',{
                 email:email,
                 password:password
              },{
                headers: { 'Content-Type': 'application/json'}
              }).then((response)=>{
                    if(response.data.status === 'allow'){
                         checkAdminLog(true);
                    }else
                    {
                       setNotify(response.data.message);
                    }
              })
          }
        }
  }

  
    return (
        <div>
        <div className="account-pages mt-5 mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card bg-pattern">
                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <div className="auth-logo">
                        <a href="/" className="logo logo-dark text-center">
                          <span className="logo-lg">
                            <img src="../assets/images/eq4.png" alt="" height={40} />
                          </span>
                        </a>
                        <a href="/" className="logo logo-light text-center">
                          <span className="logo-lg">
                            <img src="../assets/images/eq4.png" alt="" height={40} />
                          </span>
                        </a>
                      </div>
                      <p className="text-muted mb-4 mt-3">Enter your email address and password to access admin panel.</p>
                    </div>
                    { notify !== null ? <div className="alert alert-info text-center">{notify}</div> : null}
                    <form onSubmit={handleLogin}>
                      <div className="form-group mb-3">
                        <label htmlFor="emailaddress">Email address</label>
                        <input className="form-control" onChange={(e)=>setEmail(e.target.value)} type="email" id="emailaddress" required placeholder="Enter your email" />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="password">Password</label>
                        <div className="input-group input-group-merge">
                          <input type="password" id="password" onChange={(e)=>setPassword(e.target.value)} className="form-control" placeholder="Enter your password" />
                          
                        </div>
                      </div>
                    
                      <div className="form-group mb-0 text-center">
                        <button className="btn btn-primary btn-block" type="submit"> Log In </button>
                      </div>
                    </form>
                  
                  </div> {/* end card-body */}
                </div>
                {/* end card */}

                <div class="row mt-3">
                            <div class="col-12 text-center">
                                <p> <Link to="/forgot-pass" class=" ml-1" style={{color:'black'}}>Forgot your password?</Link></p>
                                </div> 
                        </div>
               
                {/* end row */}
              </div> {/* end col */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
        {/* end page */}
        <footer className="footer footer-alt text-black-50">
          { curDate } -  © {siteFoot }
        </footer>
      </div>
    )
}

export default Login
