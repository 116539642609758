import React,{useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core'


function Alltutor() {
        const [tutorData, setTutorData] = useState();
        const [status, setStatus] = useState(null);
        const [notifyUser, setNotifyUser] = useState(null);
        const [parentID, setParentID] = useState();
        const [rid, setRid] = useState();
        const [chk, setChk] = useState(false)
        const history = useNavigate();


        const removeParent = () =>{
          const data = { userID:parentID, userType:'tutor',ID:rid}
          axios.post('/remove-parent-data',data,{
           headers: { 'Content-Type': 'application/json'}
          }).then((res)=>{
               if(res.data.status === false){
                   setNotifyUser('Account was remove successfully');
                      setChk(true)
                   setTimeout(()=>{
                         setNotifyUser(null)
                   },5000);
               }
          })
   }


   const showWarning = (parentID,id) =>{
    setParentID(parentID);
    setRid(id);
    document.getElementById('showM').click();
    
}


        
     useEffect(()=>{
          axios.get('/registered-tutor',{
            headers: { 'Content-Type': 'application/json'}
          })
          .then((response)=>{
               if(response.data.tutorDetails === false){
                    setStatus(<div className="alert alert-info text-center">No registered tutors</div>);
               }else{
                      setTutorData(response.data.tutorDetails);
               }
          })
     },[chk])
    return (
        <div id="wrapper">
        <div className="content-page">
    <div className="content">
      {/* Start Content*/}
      <div className="container-fluid">
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">Registered</li>
                  <li className="breadcrumb-item active">Registered Tutor</li>
                </ol>
              </div>
              <h4 className="page-title">All Tutors</h4>
            </div>
          </div>
        </div>     
        {/* end page title */} 
        <div className="row">
          <div className="col-12">

          <button  type="button" id='showM' class="btn btn-danger" style={{display:'none'}} data-toggle="modal" data-target="#danger-alert-modal">Danger Alert</button>

<div id="danger-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
      <div class="modal-content modal-filled bg-danger">
          <div class="modal-body p-4">
              <div class="text-center">
                  <i class="dripicons-wrong h1 text-white"></i>
                  <h4 class="mt-2 text-white">Oh snap!</h4>
                  <p class="mt-3 text-white">You are about to delete this parent profile. All details of this parent will be removed permanently.</p>
                  <p class="mt-1 text-white">Are you sure you want to continue.</p>
                 
                 
              </div>
          </div>

          <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
                   <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={()=> removeParent()}>Delete</button>
               </div>
      </div>
  </div>
</div>

            <div className="card">
              <div className="card-body">
                { notifyUser === null ? null : <div className="alert alert-info text-center">{ notifyUser }</div>}
                <div className="table-responsive">
                    { status !== null ? status :
                
                    
                tutorData && <MaterialTable
                title="All registered tutor data"
                columns={[
                  { title: 'Names', field: 'name' },
                  { title: 'Email', field: 'email' },
                  { title: 'Phone', field: 'phone' },
                  { title: 'Passport ID Number', field: 'id_number' },
                  { title: 'Profile ID Number', field: 'userid' },
                ]}
                data= {
                  tutorData
                }
    
    
                actions={[
                  {
                    icon:'visibility',
                    tooltip: 'View Tutor',
                    onClick: (event, rowData) => history('/tutors/'+rowData.userid)
                  },
                  rowData => ({
                    icon: 'delete',
                    tooltip: 'Delete Tutor',
                    onClick: (event, rowData) => showWarning(rowData.userid,rowData.id),
                   
                  })
                ]}
                options={{
                  actionsColumnIndex: -1
                }}
                
                />
               
            
            }                   
                </div>
                
              </div> {/* end card-body*/}
            </div> {/* end card*/}
          </div> {/* end col */}
        </div>
        {/* end row */}
      </div> {/* container */}
    </div> 
    </div>
        </div>
         )
}

export default Alltutor
