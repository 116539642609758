import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

function Protectpage({ isAuth, redirectPath = '/login' }) {
  if (!isAuth) {
    return <Navigate to={redirectPath} />;
  }

  return <Outlet />;
}

export default Protectpage;
