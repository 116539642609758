import React,{useState, useEffect } from 'react'
import MaterialTable from '@material-table/core'
import axios from 'axios';

function Request() {
      const [loadRemoval, setLoadRemoval] = useState(null);
      const [loadCentreChange, setLoadCentreChange] = useState(null);
      const [loadLinkStudent, setLoadLinkStudent] = useState(null);
      const [loadWithdrawal, setWithdrawal] = useState(null);
      const [numRemoval, setNumRemoval] = useState(0);
      const [numcentre, setNumCentre] = useState(0);
      const [numLink, setNumLink] = useState(0);
      const [numWith, setNumWith] = useState(0);
      const [linkStatus, setLinkStatus] = useState(false);
      const [studName, setStudName] = useState();
      const [studLang, setStudLang] = useState();
      const [grade, setGrade] = useState();
      const [parentName, setParentName] = useState();

      const [centreName, setCentreName] = useState();
      const [tutorName, setTutorName] = useState();
      const [centreAddress, setCentreAddress] = useState();
      const [centrePhone, setCentrePhone] = useState();
      const [rid, setRid] = useState();
      const [studid,setStudID] = useState();

      const [verify, setVerify] = useState();
      const [noteAdmin, setNoteAdmin] = useState();

      const [wNames, setWNames] = useState();
      const [wAmount, setWAmount] = useState();
      const [wTime, setWTime] = useState();
      const [wStatu, setWStatu] = useState();
      const [userType, setUserType] = useState();

      const [wStatus, setWStatus] = useState(false);
      const [userID, setUserID] = useState();
      const [bkName, setBkName] = useState();
      const [acctNumb, setAcctNumb] = useState();
      const [acctName, setAccName] = useState();
      const [bCode, setBCode] = useState();
      const [bstatus, setBstatus] = useState(false)
     
      const [wnote, setWNote] = useState(null)
      const [rd,setRis] = useState();
      const [reNote, setReNote] = useState(false);
      const [centreLogi, setCentreLogi] = useState();
      const [centreLati, setCentreLati] = useState();
      const [usrCentreData, setUsrCentreData] = useState(null);
  
      const [usrCid, setUsrCid] = useState(null);
   
      const [addingStatus, setAddingStatus] = useState(false);
      const [centreID, setCentreID] = useState();
      const [centreEmail, setCentreEmail] = useState();
      const [parentEmail, setParentEmail] = useState();
  
      const [remParentSide, setRemParentSide] = useState(false);
      const [remTutorSide, setRemTutorSide] = useState(false);
      const [generalOpen, setGeneralOpen] = useState(false);
      const [reason, setReason] = useState();
      const [queryID, setQueryID] = useState();
      const [userCid, setUserCid] = useState();
      const [dateRequest, setDateRequest] = useState();
      const [studentName, setStudentName] = useState();
      const [studGrade, setStudGrade] = useState();
      const [notify, setNotify] = useState(null);
      const [rstatus, setRStatus] = useState();
      const [openCentreStatus, setOpenCentreStatus] = useState(false);
      const [reasonState, setReasonState] = useState();
      const [changeCentID, setChangeCentID] = useState();
      const [permiss, setPermiss] = useState();
      



      const RemoveWit =(rid) =>{
              axios.post('/remove-withdrawal-request',{
                   rid:rid, 
              },{
                headers: { 'Content-Type': 'application/json'}
              }).then((res)=>{
                    setReNote(res.data.message);
              })
      }

      const mstatus = (stat) =>{
              switch(stat){
                  case 'Progress':
                       return (
                        <span className="badge badge-warning">{ stat }</span>
                       )
                    break;
                    case 'Pending':
                      return (
                        <span className="badge badge-info">{ stat }</span>
                       )
                      break;
                      case 'Reject':
                        return (
                          <span className="badge badge-danger">{ stat }</span>
                         )
                        break;

                      case 'Complete':
                        return (
                          <span className="badge badge-success">{ stat }</span>
                         )
                        break;
              }
      }   

      const viewWithdrawal = (userid,amount,time,status,rid) =>{
                    setWStatus(true)
              axios.get('/get-user-withdrawal-admin/'+userid,{
                headers: { 'Content-Type': 'application/json'}
              }).then((res)=>{

                       setUserType(res.data.userType);
                   setWNames(res.data.wData[0].fnames);
                     setWAmount(amount);
                     setWTime(time);
                     setWStatu(status);
                     setUserID(userid);
                      setRis(rid)

                     if(res.data.bankData === false){
                              setBstatus(false);
                     }else
                     {
                          setBstatus(true)

                        setBkName(res.data.bankData[0].bank_name);
                          setAcctNumb(res.data.bankData[0].account_num);
                       setAccName(res.data.bankData[0].account_name);
                      setBCode(res.data.bankData[0].branch_code);

                     }

                        switch(res.data.userType){
                             case 'Parent':
                              setWNames(res.data.wData[0].fnames);
                               break;
                               case 'Tutor':
                                setWNames(res.data.wData[0].name);
                                 break;
                        }
                             
              })

      }

      const processRequest = (data,id) => {
                 axios.get('/processrequest-data-admin/'+data+'/'+id+'/'+studid,{
                  headers: { 'Content-Type': 'application/json'}
                 }).then((res)=>{
                       setNoteAdmin(<div className="alert alert-info text-center">{ res.data.message }</div>)
                 })
      }

      const updateWStatus = (status) =>{
              if(status === ''){}else{
             const uData = { status:status, uid:userID, rid:rd, direct:status}
                axios.post('/update-admin-withdrawal',uData,{
                  headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{
                      setWNote(<div className="alert alert-info text-center">{res.data.message}</div>)
                })  
                
              }
      }

  const processRemove =(userid,userType,reason,qid,sid,date,status) =>{
            setReason(reason);
            setQueryID(qid);
            setUserCid(sid);
            setUserType(userType);
            setDateRequest(date);
            setUserID(userid);
            setRStatus(status)
            alert(status);
            setGeneralOpen(true);
             switch(userType){
                 case 'Parent':
                    setRemParentSide(true);
                    setRemTutorSide(false);
                      axios.get('/admin-getstudent-parent/'+userid+'/'+sid,{
                        headers: { 'Content-Type': 'application/json'}
                      }).then((res)=>{
                            setStudentName(res.data.studentDetails[0].firstname+' '+res.data.studentDetails[0].lastname);
                            setStudGrade(res.data.studentDetails[0].grade);
                              console.log(res.data.studentDetails[0].student_centre_id)
                            axios.get('/centre-update-data/'+res.data.studentDetails[0].student_centre_id,{
                              headers: { 'Content-Type': 'application/json'}
                             }).then((rep)=>{
                                  setTutorName(rep.data.tutorData[0].centreName);
                             }) 
                      })
                   break;
                  case 'Tutor':
                    setRemParentSide(false);
                    setRemTutorSide(true);
                    axios.get('/centre-update-data/'+sid,{
                      headers: { 'Content-Type': 'application/json'}
                     }).then((rep)=>{
                          setCentreName(rep.data.tutorData[0].centreName);
                          setCentrePhone(rep.data.tutorData[0].centrePhone);
                          setCentreEmail(rep.data.tutorData[0].centreEmail);
                          setCentreAddress(rep.data.tutorData[0].centreAddress);
                     }) 
                    break;
             }
             


  }

  const removeStudentFromCentre = () =>{
          const QueryData = {usrID:userCid,qid:queryID,userType:userType,
             userid:userID, studentName:studentName, centreName:centreName}
          axios.post('/update-remove-student',QueryData,{
            headers: { 'Content-Type': 'application/json'}
          }).then((resp)=>{
               
                     setNotify(<div className='alert alert-info text-center'>{ resp.data.status }</div>);
                
          })
  }
      

  const removalRequest = () =>{
          return(
             <div>
              
            { loadRemoval === null ? <div className="alert alert-info text-center">No removal request </div> : 


              generalOpen !== false ? 
              
              
              <div className="mt-4">
                 <button className="btn btn-light" style={{clear:'both', float:'right'}} onClick={()=> setGeneralOpen(false)}>Back to all request </button>
              <h5 className="font-18">{ userType } Request</h5>
              <hr />
               { notify }
              <div className="media mb-3 mt-1">
                <div className="media-body">
                  <small className="float-right">Date { dateRequest }</small>
               
                </div>
              </div>
             
              <p>{ reason }</p>

             { remParentSide !== false ?
              <div>
              <p>Learner Name: { studentName } </p>
              <p>Grade: { studGrade } </p>
              <p>Current Centre: { tutorName } </p>
               
              
              <hr />
          
                <div className="mt-1">
               
                { rstatus === 'Pending' ? 
                <button className="btn btn-light" onClick={()=>removeStudentFromCentre()}>Grant Request <i className="mdi mdi-forward ml-1" /> </button>
                : null }
                </div>
                
                 
              
              </div>
              :

              <div>

              <p>Centre Name: { centreName } </p>
              <p>Centre Email: { centreEmail } </p>
              <p>Centre Phone: { centrePhone } </p>
              <p>Centre Address: { centreAddress }</p>  

              <hr />
                <div className="mt-5">
               
                 { rstatus === 'Pending' ? 
                <button className="btn btn-light" onClick={()=>removeStudentFromCentre()}>Grant Request <i className="mdi mdi-forward ml-1" /> </button>
                : null }
                </div>

              </div>

             }



            </div> :
            
            <MaterialTable
            title="Remove Request"
            columns={[
              { title: 'Users', field: 'user_type' },
              { title: 'User Query', field: 'reason' },
              {title: 'Answer Status', field: 'status'},
              { title: 'Date Reqeust', field: 'date_request' },

             
             
              
            ]}
            data= {
              loadRemoval
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => processRemove(rowData.userid,rowData.user_type,rowData.reason,rowData.id,rowData.sid_cid,rowData.date_request,rowData.status)
              },
              rowData => ({
                icon: 'delete',
                tooltip: 'Delete student',
                onClick: (event, rowData) => alert(4000),
               
              })
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />
            }
               
             </div>
          )
  }


  const grantCentreChange = (id,centreName,date_request,reason,reason_state,cid) =>{
          setOpenCentreStatus(true);
           setCentreName(centreName);
           setDateRequest(date_request);
           setReason(reason);
           setReasonState(reason_state);
           setChangeCentID(id);
           setCentreID(cid)
           

         axios.get('/view-centrechange-request/'+id,{
          headers: { 'Content-Type': 'application/json'}
         }).then((resp)=>{
                
         })
  }

  const grantPermission = () =>{
        const uData = { centreID:centreID,changeCentID:changeCentID}
       axios.post('/grant-permission-centre',uData,{
        headers: { 'Content-Type': 'application/json'}
       }).then((rep)=>{
           setPermiss(<div className="alert alert-info text-center">{ rep.data.status } </div>)
       })
  }

  const centreChange = () =>{
  return(
           
                <div>
            { loadCentreChange === null ? <div className="alert alert-info text-center">No centre change request </div> : 
            
              openCentreStatus !== false ? 

              <div className="mt-4">
                 <button className="btn btn-light" style={{clear:'both', float:'right'}} onClick={()=> setOpenCentreStatus(false)}>Back to all request </button>
              <h5 className="font-18"> Permit to change centre Information</h5>
              <hr />
               { permiss }
              <div className="media mb-3 mt-1">
                <div className="media-body">
                  <small className="float-right">Date { dateRequest }</small>
               
                </div>
              </div>

              <p>{ reason }</p>

            
              <div>
              <p>Centre Name: { centreName } </p>
             
               
              
              <hr />
          
                <div className="mt-1">
               
                { reasonState === 'not approve' ? 
                <button className="btn btn-light" onClick={()=>grantPermission()}>Grant Permission <i className="mdi mdi-forward ml-1" /> </button>
                : null }
                </div>
                
                 
              
              </div>
             


            </div> 
              :
         
            <MaterialTable
            title="Centre Change"
            columns={[
              { title: 'Date Request', field: 'date_request' },
              { title: 'Centre Name', field: 'centreName'},
              { title: 'State of Reason', field: 'reason_state' },
              { title: 'Status', field: 'status' },
            
              
            ]}
            data= {
              loadCentreChange
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => grantCentreChange(rowData.id,rowData.centreName,rowData.date_request,rowData.reason,rowData.reason_state,rowData.centre_id)
              },
              rowData => ({
                icon: 'delete',
                tooltip: 'Delete student',
                onClick: (event, rowData) => alert(4000),
               
              })
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />
             

            }
               
             </div>
             
          )
  }

  const addingStudent = (cid,centreName,tutorName,tutorID) =>{
          setUsrCid(cid);
          const addData = {centreEmail:centreEmail, parentEmail:parentEmail, cid:cid, studid:studid, centreName:centreName,tutorName:tutorName,tutorID:tutorID}
          axios.post('/change-centre',addData,{
            headers: { 'Content-Type': 'application/json'}
          }).then((res)=>{
                setTimeout(()=>{
                      if(res.data.centreAdd === false){
                        alert("Error occur when processing your request");
                      }else
                      {
                        setAddingStatus(true)
                      }
                     
                },2000); 
          })
            
  }

  const loadStatusAdd = (cid) =>{
         if(cid === usrCid){
                  if(addingStatus !== false){

                    return ('Linked')
                  }else
                  {
                    return ('Please wait...')
                  }
                
         }else
         {
             return ('Link Leaner')
         }
           
  }

  const linkStudent = () => {
    return(
      <div>
          <div>
            { loadLinkStudent === null ? <div className="alert alert-info text-center">No linking request </div> : 
             linkStatus !== false 
             
             ?
              <div className="row">
                <div className="col-12">
                <button type="button" className="btn btn-success btn-xl waves-effect mb-20 waves-light" onClick={()=> setLinkStatus(false)}>Back to Request</button>&nbsp;&nbsp;&nbsp;

                    { noteAdmin }
                  </div>

      <div className="col-lg-4 col-xl-4">
        <div className="card-box text-center">
          <h4 className="mb-0">{studName }</h4>
          <p className="text-muted">Student</p>
          <div className="text-left mt-3">
            
            <p className="text-muted mb-2 font-13"><strong>Parent Name :</strong> <span className="ml-2">{ parentName }</span></p>
            <p className="text-muted mb-2 font-13"><strong>Grade :</strong><span className="ml-2">{ grade }</span></p>
            <p className="text-muted mb-2 font-13"><strong>Language of Instruction :</strong> <span className="ml-2 ">{ studLang }</span></p>
          
          </div>
         
        </div> {/* end card-box */}
     
      </div> {/* end col*/}

      <div className="col-lg-4 col-xl-4">
            <br /> <br /> <br /> <br /> 
            { verify === 'Pending' ?
           
          <center>
           
          <button type="button" className="btn btn-success btn-xl waves-effect mb-20 waves-light" onClick={()=> processRequest('approve',rid)}>Approve</button>&nbsp;&nbsp;&nbsp;

          <button type="button" className="btn btn-danger btn-xl waves-effect mb-20 waves-light" onClick={()=> processRequest('remove',rid)}>Reject</button>

            </center>
            :

            <center>
           
            <button type="button"  className="btn btn-success btn-xl waves-effect mb-20 waves-light" onClick={()=>fetchNearbyTutor()} data-toggle="modal" data-target="#centermodal" data-backdrop="static">Change Centre</button>&nbsp;&nbsp;&nbsp;
  
          
              </center>

    }
       
         </div>



         
      <div className="col-lg-4 col-xl-4">
        <div className="card-box text-center">
          <h4 className="mb-0">{ centreName }</h4>
          <p className="text-muted">Centre</p>
         <div className="text-left mt-3">
          
           
            <p className="text-muted mb-2 font-13"><strong>Centre Owner :</strong> <span className="ml-2">{ tutorName }</span></p>
            <p className="text-muted mb-2 font-13"><strong>Mobile :</strong><span className="ml-2">{ centrePhone }</span></p>
           
            <p className="text-muted mb-1 font-13"><strong>Location :</strong> <span className="ml-2">{ centreAddress }</span></p>
          </div>
         
        </div> {/* end card-box */}
     
      </div> {/* end col*/}



      <div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
          
            <div className="modal-body p-4">

        <h4 className="header-title mb-3">Available Centres</h4>
       
         { usrCentreData === null ? <div>

         

         </div> :

          usrCentreData && usrCentreData.map((cd)=>(
            <div className="inbox-widget" data-simplebar style={{maxHeight: '407px'}}>
            <div className="inbox-item">
             
              <p className="inbox-item-author">{ centreID === cd.center_id ? ('Current centre') : null } - { cd.centreName }</p>
              <p className="inbox-item-text">Tel: { cd.centrePhone }</p>
              <p className="inbox-item-text">{ cd.centreAddress }</p>

              <p className="inbox-item-date">
                 { centreID !== cd.center_id ?
                  <button type="button" class="btn btn-blue btn-xs waves-effect waves-light" onClick={()=> addingStudent(cd.center_id,cd.centreName,cd.tutorName,cd.tutor_id)}>{ loadStatusAdd(cd.center_id) }</button>
             
                  :
                  <button type="button" class="btn btn-blue btn-xs waves-effect waves-light" disabled>Linked</button>
               
                
                }
              
              </p>
            </div>
          </div>
          )) 
     
        }


        
           </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
         

        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}



                </div>
              
              :
            <MaterialTable
            title="Student Request"
            columns={[
              { title: 'Centre Name', field: 'centre_name' },
              {title: 'Verification', field: 'verify'},
              { title: 'Status', field: 'status' },
              { title: 'Date Request', field:'date_added'}
            
              
            ]}
            data= {
              loadLinkStudent
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => controlLink(rowData.student_id,rowData.centre_id,rowData.parent_id,rowData.verify,rowData.id)
              }
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />
            }
               
             </div>
      </div>
   )
  }


  const widthdrawal = () =>{
    return(
      <div>
           { wStatus !== false ? 
           
           <div className="table-responsive">
               { wnote === null ? null : wnote}
             <button type="button" onClick={()=> setWStatus(false)} className="btn btn-danger btn-xl waves-effect mb-20 waves-light" >Back</button>
              <br></br>
              <select style={{float:'right', width:200}}  className="custom-select" id="status-select" onChange={(e)=> updateWStatus(e.target.value)}>
                          <option value=''>
                            Status Option
                           </option>
                           <option value="Reject">
                             Reject Request
                           </option>
                           <option value="Progress">
                             In Progress
                           </option>
                           <option value="Complete">
                             Complete
                           </option>
                           </select>
                           <br></br> <br></br>

                           <table className="table table-borderless mb-0">
               <thead className="thead-light">
                  <tr>
                    <th>Bank Name</th>
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Branch Code</th>
                  </tr>
                 </thead>
                 <tbody>
                  <tr>
                    <td>{ bkName }</td>
                    <td>{ acctName }</td>
                    <td>{ acctNumb }</td>
                    <td>{ bCode }</td>
                  </tr>
                   </tbody>

                   </table>
                   <br></br> <br></br>
           <table className="table table-borderless mb-0">
               <thead className="thead-light">
                   <tr>
                     
                       <th>Names</th>
                       <th>User Type</th>
                       <th>Amount</th>
                       <th>Date Request</th>
                       <th>Status</th>
                      
                   </tr>
               </thead>
               <tbody>
                   <tr>
                      
                       <td>{ wNames }</td>
                       <td>{ userType }</td>
                       <td>{ wAmount }</td>
                       <td>{ wTime }</td>
                       <td>{mstatus(wStatu) }</td>
                       <td> 
            

</td>
                   </tr>
                 
               </tbody>
           </table>
       </div>

           :

          <div>
            { reNote === false ? null : reNote }
            { loadWithdrawal === null ? <div className="alert alert-info text-center">No withdrawal Request </div> : 
            
            <MaterialTable
            title="Withdrwal Request"
            columns={[
              { title: 'Amount(R)', field: 'amount' },
              { title: 'Date Request', field: 'date_request' },
              { title: 'Status', field: 'status' },
            
              
            ]}
            data= {
              loadWithdrawal
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => viewWithdrawal(rowData.userid,rowData.amount,rowData.date_request,rowData.status,rowData.request_id)
              },
              rowData => ({
                icon: 'delete',
                tooltip: 'Delete student',
                onClick: (event, rowData) => RemoveWit(rowData.request_id),
               
              })
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />
            }
               
             </div>
  }
    
      </div>
   )
  }

  const getRemoveRequest = () => {
        axios.get('/get-admin-request-data/remove',{
          headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
          if(res.data.removeData === false){
                   setLoadRemoval(null)
          }else{
                  setLoadRemoval(res.data.removeData)
          }
        })

      

        setInterval(()=>{

          axios.get('/notify-admin-request/remove',{
            headers: { 'Content-Type': 'application/json'}
          }).then((rep)=>{
                setNumRemoval(rep.data.numRemove)
          })
        },2000)
  }

  const getCentreRequest = () => {
    axios.get('/get-admin-request-data/centre',{
      headers: { 'Content-Type': 'application/json'}
    }).then((res)=>{
      if(res.data.centreData === false){
              setLoadCentreChange(null)
      }else{
             setLoadCentreChange(res.data.centreData)
      }
    })


   

    setInterval(()=>{
      axios.get('/notify-admin-request/centre',{
        headers: { 'Content-Type': 'application/json'}
      }).then((rep)=>{
              setNumCentre(rep.data.numChange)
      })
    },2000)
  }

  const getLinkRequest = () => {
    axios.get('/get-admin-request-data/link',{
      headers: { 'Content-Type': 'application/json'}
    }).then((res)=>{
      if(res.data.linkData === false){
              setLoadLinkStudent(null)
      }else{
              setLoadLinkStudent(res.data.linkData)
      }
    })

   

    setInterval(()=>{
      axios.get('/notify-admin-request/link',{
        headers: { 'Content-Type': 'application/json'}
      }).then((rep)=>{
            setNumLink(rep.data.numLink)
      })
    },2000)

  }



  const getWithdrawRequest = () => {
    axios.get('/get-admin-request-data/withdraw',{
      headers: { 'Content-Type': 'application/json'}
    }).then((res)=>{
        if(res.data.withData === false){
                setWithdrawal(null)
        }else{
                setWithdrawal(res.data.withData)
        }
    })

       setInterval(()=>{
        axios.get('/notify-admin-request/withdraw',{
          headers: { 'Content-Type': 'application/json'}
        }).then((rep)=>{
              setNumWith(rep.data.numWith);
        })
       },2000)
      

  }


  const controlLink = (studentID, centreID,pid,verify,id) =>{
            setLinkStatus(true);
            setRid(id);
            setStudID(studentID);
          axios.get('/process-student-request/'+studentID+'/'+centreID+'/'+pid,{
            headers: { 'Content-Type': 'application/json'}
          }).then((res)=>{
                setCentreAddress(res.data.centre[0].centreAddress);
                setTutorName(res.data.centre[0].tutorName);
                setCentreName(res.data.centre[0].centreName);
                setCentrePhone(res.data.centre[0].centrePhone);
                setCentreEmail(res.data.centre[0].centreEmail);
                setCentreLati(res.data.centre[0].lati);
                setCentreLogi(res.data.centre[0].logi);
                 setCentreID(res.data.centre[0].center_id);
               

                setStudName(res.data.stud[0].firstname+' '+res.data.stud[0].lastname);

                setStudLang(res.data.stud[0].lang_intruct);
                setParentName(res.data.parent[0].fnames);
                setParentEmail(res.data.parent[0].email);
                setGrade(res.data.stud[0].grade);
          })

          setVerify(verify)
  }

 useEffect(()=>{

    getWithdrawRequest();
    getCentreRequest();
    getLinkRequest();
    getRemoveRequest();

 },[])


  const fetchNearbyTutor = () =>{
         axios.get('/get-tutor-nearby/'+centreLati+'/'+centreLogi,{
          headers: { 'Content-Type': 'application/json'}
         }).then((resp)=>{
            if(resp.data.centreDatas === false){

                 setUsrCentreData(null);

            }else{

                 setUsrCentreData(resp.data.centreDatas);
            }
         })
  }



    return (
        <div id="wrapper">

<div className="content-page">
        <div className="content">
          {/* Start Content*/}
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="javascript: void(0);">UBold</a></li>
                      <li className="breadcrumb-item"><a href="javascript: void(0);">Email</a></li>
                      <li className="breadcrumb-item active">Inbox</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Request</h4>
                </div>
              </div>
            </div>     
            {/* end page title */} 


            <div className="row">
      
        <div className="col-xl-12">
          <div className="card-box">
          
            <ul className="nav nav-tabs nav-bordered nav-justified">
            <li className="nav-item">
                <a href="#remove-b2" data-toggle="tab" aria-expanded="false" className="nav-link active">
                  Removal <span className="badge badge-soft-danger"> { numRemoval }</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="#home-b2" data-toggle="tab" aria-expanded="false" className="nav-link">
                  Centre Change <span className="badge badge-soft-danger">{ numcentre }</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#profile-b2" data-toggle="tab" aria-expanded="true" className="nav-link">
                  Link Student <span className="badge badge-soft-danger">{ numLink }</span>
                </a>
              </li>

              <li className="nav-item">
                <a href="#withdraw-b2" data-toggle="tab" aria-expanded="true" className="nav-link">
                  Withdrawal <span className="badge badge-soft-danger">{ numWith }</span>
                </a>
              </li>
             
            </ul>
            <div className="tab-content">
            <div className="tab-pane active" id="remove-b2">
               {removalRequest() }
              </div>

              <div className="tab-pane" id="home-b2">
               
               { centreChange() }
                 </div>
              <div className="tab-pane" id="profile-b2">
                  { linkStudent() }
              </div>

              <div className="tab-pane" id="withdraw-b2">
                  { widthdrawal() }
              </div>
             
            </div>
          </div> {/* end card-box*/}
        </div> {/* end col */}
      </div>
              
        
          </div> {/* container */}
        </div> {/* content */}
       
      </div>
            
        </div>
    )
}

export default Request
