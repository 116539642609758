import React,{ useState, useEffect} from 'react'
import axios from 'axios';

function Dashboard() {

    const [terms, setTerms] = useState();
    const [currTerm, setCurrTerm] = useState();
    const [notices, setNotices] = useState(null)

     const updateTerm = () =>{
         axios.post('/api-update-userterms',{terms:terms},{
             headers:{'Content-Type':'application/json'}
         }).then((res)=>{
                 setCurrTerm(terms)
                setNotices(res.data.mstatus);
         })
     
     }

     useEffect(()=>{

        axios.get('api-exporecurrentterm',{
            headers:{'Content-Type':'application/json'}
        }).then((res)=>{
             setCurrTerm(res.data.term)
        })

     },[]);

    return (
        <div id="wrapper">
        <div className="content-page">
    <div className="content">
      {/* Start Content*/}
      <div className="container-fluid">

      <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Dashboard</li>
                     
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Dashboard</h4>
                </div>
              </div>
            </div>     

            <div className="row">
                <div className='col-xl-3'>

                </div>

                <div className='col-xl-6'>
                    <div className='card'>
                        <div className='card-body'>
                            <h4>Update Terms</h4>

                            <table className='table bg-primary text-white'>
                                <tbody>
                                    <tr className=''>
                                        <td>Current Term</td>
                                        <td>Term { currTerm }</td> 
                                    </tr>
                                </tbody>
                            </table>

                             
                                { notices === null ? null : <div className='alert alert-success'>{notices}</div>}
                            <strong className='mb-4'>Update current term</strong>
                         
                            <select className='form-select form-control' onChange={(e)=>setTerms(e.target.value)}>
                                <option>Select Term</option>
                                <option value={1}>Term 1</option>
                                <option value={2}>Term 2</option>
                                <option value={3}>Term 3</option>
                                <option value={4}>Term 4</option>

                            </select>

                            <button onClick={()=> updateTerm()} className='btn btn-primary mt-4'>Update</button>

                            <hr />

                        </div>
                    </div>
                    
                </div>

             </div>




                 </div>

              </div>
            </div>
        </div>
    )
}

export default Dashboard
