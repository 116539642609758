import React,{useState, useEffect, useRef} from 'react'
import axios from 'axios';
import $ from 'jquery'

function CaptureAssessment() {
  const noteRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [subjectID, setSubjectID] = useState();
  const [searchSubj, setSearchSubj] = useState(null);
  const [viewCate, setViewCate] = useState();
  const [grade, setGrade] = useState();
  const [years, setYears] = useState();
  const [assessYear, setAssessYears] = useState();
  const [loadAssessData, setAssessData] = useState(null);
  const [loadAss, setLoadAss] = useState();
  const [notify, setNotify] = useState(null);
  const [loadAssessment, setLoadAssessment] = useState(null);
  const [subject, setSubject] = useState();
  const [location, setLocation] = useState();
  const [assessID, setAssessID] = useState();
  const [status, setStatus] = useState(null);
  const [marks,setMarks] = useState('');
  const [centreNames,setCentreName] = useState();
  const [term, setTerm] = useState();
  const [captureStatus, setCaptureStatus] = useState(false);
  const [studN, setStudN] = useState();
  const [subjectN, setSubjectN] = useState();
  const [studID, setStudID] = useState();
  const [gterm, setGterm] = useState();
  const [downloadID, setDownloadID] = useState();
  const [gradeRdata, setGradeRData] = useState(null);
  const [subjectId, setSubjectId] = useState();
  const [quID, setqID] = useState();
  const [topID,setTopID] = useState();
  const [alertStatus, setAlertStatus] = useState();
  const [userScore, setUserScore] = useState();
  const [checkAlreadyScore, setCheckAlreadyScore] = useState('');
  const [question, setQuestion] = useState(null);
  const [topic_id, setTopic_ID] = useState();
  const [highergradenotify, setHigherGradeNotify] =  useState(null);
  const [studentName, setStudentName] = useState();
  const [studentID, setStudentID] = useState();
  const [subjectName,setSubjectName] = useState();



  const ucate = [
    {cater:'Term 1',id:2},
   {cater:'Term 2',id:1},
  {cater:'Term 3',id:3},
  {cater:'Term 4',id:4},
  ];

    const category = [
        {cate:'Task',id:2},
       {cate:'Test',id:1},
      {cate:'Assignment',id:3},
      {cate:'Examination',id:4},
      {cate:'Project',id:5},
      {cate:'Practical',id:6}  
];

const gradeData = [
   {grade:'R',id:1},
  {grade:'1',id:3},
  {grade:'2',id:4},
  {grade:'3',id:5},
  {grade:'4',id:6},
  {grade:'5',id:7},
  {grade:'6',id:8},
  {grade:'7',id:9},
  {grade:'8',id:10},
  {grade:'9',id:11},
  {grade:'10',id:12},
  {grade:'11',id:13},
  {grade:'12',id:14}   
];

const srtSubject = (gradID) =>{
  setGrade(gradID);
axios.get('/get-subject-cate/'+gradID,{
headers:{ 'Content-Type': 'application/json'}
}).then((response)=>{

if(response.data.subjectData === false){

setSearchSubj(null) 
}else
{
setSearchSubj(response.data.subjectData);
}
})
}

const getcentreName = (centID) =>{
       axios.get('/centre-update-data/'+centID,{
        headers:{ 'Content-Type': 'application/json'}
       }).then((response)=>{
           setCentreName(response.data.tutorData[0].centreName);
           return response.data.tutorData[0].centreName;
               
       })
}

const getAssessmentData = (subjectID) => {
        setSubjectID(subjectID);
       axios.post('/captureUserMarks-data',{
             grade:grade,
             year:assessYear,
             subjectID:subjectID,
             viewCate:viewCate,
             term:term
       },{
        headers:{ 'Content-Type': 'application/json'}
       }).then((response)=>{
              if(response.data.assessData === false){
                      setLoadAss(<option disabled>No data available</option>);
              }else{
                     setAssessData(response.data.assessData)
              }
       })

}



  const viewAllAssessmentCategory = () =>{
            setLoader(true);
             
          axios.post('/viewAllAssementCate',{
              grade:grade,
              year:assessYear,
              subjectID:subjectID,
              assessID:assessID,
              term:term
          },{
            headers:{ 'Content-Type': 'application/json'}
          }).then((response)=>{
               setTimeout(()=>{
                   setLoader(false)
                if(response.data.assessmentData === false){
                      setNotify(<div className="alert alert-info text-center">No assessment found</div>);
                       setLoadAssessment(null);
                }else{
                        setNotify(null);
                        setLoadAssessment(response.data.assessmentData);
                        setSubject(response.data.subject);

                }
               },2000);
              
          })
  }

   useEffect(()=>{
         axios.get('/getassessment-years',{
          headers:{ 'Content-Type': 'application/json'} 
         }).then((response) =>{
              setYears(response.data.years);
         })
   },[])

   const arrangeStudentCaptureResult = (studID,subjectID,term,downloadID,year,studName,subjectName,mstatus)=>{
                    
    setStudN(studName)
    setSubjectN(subjectName);
    setGterm(term);
    setStudID(studID);

    if(mstatus === 'edit'){

      setDownloadID(downloadID);
      setSubjectId(subjectID);

    }else{
   
    axios.get('/get-subject-data-r/'+downloadID+'/'+term+'/'+subjectID+'/'+studID,{
      headers:{ 'Content-Type': 'application/json'}
    }).then((resp)=>{
          if(resp.data.gradeData === false){
            setCaptureStatus(true);
                  setGradeRData(null)
          }else{
              if(resp.data.gradeData === 'modal'){
                    setCheckAlreadyScore('modal');
              }else{
                setCaptureStatus(true);
                setGradeRData(resp.data.gradeData);
              }
           
          }
    })
  }
}



const continueUpdateQuest = () =>{
  ///get-grade-lower-quest/:downid/:subid'
  setCaptureStatus(true);
  axios.get('/get-grade-lower-quest/'+downloadID+'/'+subjectId,{
    headers:{ 'Content-Type': 'application/json'}
  }).then((resp)=>{
        if(resp.data.gradeData === false){
        
                setGradeRData(null)
        }else{
   
              setGradeRData(resp.data.gradeData);
            
         
        }
  })
}


const checkSave = (topicID,qID) =>{

      if(topicID === topID && quID===qID){
                return(
                  <div>{ alertStatus }</div>
                )        
         }
}



const saveMark = (topic,givenScore,topicID,quest,score,term,uploadID,qid)=>{

setTopID(topicID);
setqID(qid);
setUserScore(givenScore);

  const QData = {year:assessYear,subjectID:subjectID,topic:topic,subject:subjectN,studID:studID,topicID:topicID,givenScore:givenScore,score:score,question:quest,qid:qid,term:term}
  axios.post('/save-grader-score',QData,{
    headers:{ 'Content-Type': 'application/json'}
  }).then((res)=> {
      if(res.data.gstatus === false){
        setAlertStatus(<strong style={{color:'red'}}>{ res.data.markStatus}</strong>);
      }else{
        setAlertStatus(<strong style={{color:'green'}}>{ res.data.markStatus}</strong>);
      }
  })


}

$(document).ready(function(){
$(".checkUserScore").unbind().change(function(){
let mscore = $(this).attr("id").replace("score-","");
let scoreValue = $("#score-"+mscore).val();
switch(parseInt(scoreValue)){
  case 1:
 
     $("#grade-"+mscore).html('0 - 29%');
     
    break;

    case 2:
   
   $("#grade-"+mscore).html('30 - 39%');
    
    break;
    case 3:
   $("#grade-"+mscore).html('40 - 49%');

    break;
    case 4:
   
   $("#grade-"+mscore).html('50 - 59%');
    break;

    case 5:
    
   $("#grade-"+mscore).html('60 - 69%');
     
    break;
    case 6:
    
   $("#grade-"+mscore).html('70 - 79%');
    break;
    case 7:
   
   $("#grade-"+mscore).html('80 - 100%');
      
    break;
}

//$("#grade-"+mscore).html();

})

})


const captureOthers = (student_id,subject_id,year,studentName,subject_name,invDown,terms) =>{
          setStudentID(student_id);
          setSubjectID(subject_id);
          setStudentName(studentName);
          
       const uDatas = {studID:student_id,subID:subject_id,year:year,upDown:invDown,term:terms}
    axios.post('/get-higher-grade-quest',uDatas,{
      headers:{ 'Content-Type': 'application/json'}
    }).then((res)=>{
          if(res.data.mDatas === false){
               setHigherGradeNotify(<div className='alert alert-info text-center'>No topic found for this {subject_name}</div>)
          }else{
            setCaptureStatus(true);
            setHigherGradeNotify(null)
              setQuestion(res.data.mDatas[0].question);
              setTopic_ID(res.data.mDatas[0].topic_id);
              setSubjectName(res.data.mDatas[0].subject);
          }
    })

}

const notifyScore =(qid)=>{
if(qid == quID){
  return(
   <div>{ alertStatus }</div>
  )
}
}

const saveHigherGrade = (qid,quest,score) =>{
setqID(qid);
const markData ={qid:qid,quest:quest,score:score,topicID:topic_id,studentID:studentID,term:term,year:assessYear}
axios.post('/add-higher-grade-mark',markData,{
headers:{ 'Content-Type': 'application/json'}
}).then((res)=>{
if(res.data.status === false){
setAlertStatus(<strong style={{color:'red'}}>Not Saved</strong>);
}else{
setAlertStatus(<strong style={{color:'green'}}>{ res.data.status}</strong>);
}
})

}



const displayHigherGradeQuestion = () =>{
 
 
  if(highergradenotify === null){
    if(question === null){

    }else{

        let quest = question.split('=>');

    
  return (
    <div className="table-responsive">

<button className="btn btn-primary waves-effect waves-light" style={{float:'right'}} type='button' onClick={()=>setCaptureStatus(false)}>Back to all Task</button>

    <h2 className='card-title'>Subject: { subjectName }</h2>
    <small><strong>Note: result capture are saved automatically. You can re-capture this learner anytime you wish </strong></small><br></br>
    <table className="table project-list-table table-nowrap align-middle table-borderless">
        <thead>
        <tr>

            <th scope="col">Topic</th>

            <th scope="col">Terms</th>

            <th scope="col">Assessment</th>

            <th scope="col">Level</th>

        </tr>
        </thead>

        <tbody>
        { quest.map((value, index)=>(
        <tr key={index}>
            <td>{value} (G {grade}) </td>
            <td>{term}</td>
            <td>{viewCate}</td>
            <td>
                <div className="row">

                    <div className="col-md-4">
                        <select className="form-select form-control checkUserScore" id={'score-'+index} style={{float:'right'}} onChange={(e)=>saveHigherGrade(index+1,value,e.target.value)} >
                            <option value="">Level</option>
                            <option value={7}>Level 7 (A+)</option>
                            <option value={6}>Level 6 (A)</option>
                            <option value={5}>Level 5 (B)</option>
                            <option value={4}>Level 4 (C)</option>
                            <option value={3}>Level 3 (D)</option>
                            <option value={2}>Level 2 (E)</option>
                            <option value={1}>Level 1 (F)</option>
                        </select>
                    </div>


                    <div className="col-md-2">
                        <div style={{marginTop:8}}></div>
                        <span id={'grade-'+index}></span>

                    </div>
                    <div className="col-md-2">
                        { notifyScore(index+1)}
                    </div>

                </div>
            </td>
        </tr>

        ))}



        </tbody>

    </table>

</div>
  )
            }
  
          }else{
              return (
                highergradenotify
              )
          }     

}


const displayQuestion = (question,score,topicID,term,uploadID,topic) => {
  const quest = question.split('=>');
     return(
         <tbody>
           { quest.map((value, index)=>(
              <tr key={index}>
                <td style={{width:10}}>{index+1})</td>
                <td>{value}</td>
                <td>
                   <div className="row">
                    
                  <div className="col-md-4">
                  <select className="form-select form-control checkUserScore" id={'score-'+index} style={{float:'right'}} onChange={(e)=>saveMark(topic,e.target.value,topicID,value,score,term,uploadID,index+1)} >
                    <option value="">Score</option>
                    <option value={7}>Code 7 (A+)</option>
                    <option value={6}>Code 6 (A)</option>
                    <option value={5}>Code 5 (B)</option>
                    <option value={4}>Code 4 (C)</option>
                    <option value={3}>Code 3 (D)</option>
                    <option value={2}>Code 2 (E)</option>
                    <option value={1}>Code 1 (F)</option>
                    </select>
                    </div>
                    <div className="col-md-3">
                
                    </div>

                  <div className="col-md-2">
                    <p id={'grade-'+index}></p>
                
                    </div>
                    <div className="col-md-2">
                    <p>{ checkSave(topicID,index+1) }</p>
                      </div>

                    </div>
                 
                </td>
                
              </tr>
           ))}
         </tbody>
     )

}

    return (
        <div id="wrapper">
            <div className="content-page">
        <div className="content">
        <div className="container-fluid">

        <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    
                  </div>
                  <h4 className="page-title">Capture Assessment</h4>
                </div>
              </div>
            </div>     



             
            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-12">
                      <form className="form-inline">
                       
                        

                          <div className='row'>
                          <div className='col-md-2'>
                         
                          <select style={{width:150,marginLeft:20}} className="custom-select" id="status-select" onChange={(e)=>setTerm(e.target.value)}>
                            <option value=''> Term</option>
                            { ucate.map((data) =>(
                                    <option key={data.id} value={data.cater}>{ data.cater }</option>
                            ))}
                          </select><br></br>
                       

                          </div>

                          <div className='col-md-2'>

                          <select  className="custom-select " style={{marginLeft:20,width:150}} id="status-select"  onChange={(e)=>setAssessYears(e.target.value)}>
                          <option value=''>Year</option>
                             
                          <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                          </select>
                            
                          </div>

                          <div className='col-md-2'>

                          <select className="custom-select " id="status-select" style={{marginLeft:20, width:150}} onChange={(e)=>setViewCate(e.target.value)}>
                            
                            <option value=''>Category</option>
                            { category.map((data) =>(
                                    <option key={data.id} value={data.cate}>{ data.cate }</option>
                            ))}
                          </select>
                            
                          </div>

                          <div className='col-md-2'>


                          <select className="custom-select " id="status-select" style={{marginLeft:20, width:150}} onChange={(e)=>srtSubject(e.target.value)}>
                          <option value=''>Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
                            
                          </div>

                          <div className='col-md-2'>


                          <select className="custom-select " id="status-select" style={{marginLeft:20, width:150}} onChange={(e)=> getAssessmentData(e.target.value)}>
                          <option value=''>Subject</option>
                           
                            {  searchSubj === null ? <option value='' disabled>Not available</option> :
                            
                          searchSubj &&  searchSubj.map((data) =>(
                                  <option key={data.id} value={data.subject_id}>{ data.subject+' ('+data.lang+')' }</option>
                            ))}
                          </select>
                            
                          </div>

                          <div className='col-md-2'>

                          <select className="custom-select " id="status-select" style={{marginLeft:20,width:150}} onChange={(e)=>setAssessID(e.target.value)}>
                          <option value=''>Assessment</option>
                          { loadAssessData === null ? loadAss :
                             loadAssessData && loadAssessData.map(({assessment,invigilator_id},index)=>{
                                  return (
                                       <option key={index} value={invigilator_id}>{ assessment }</option>
                                  )
                             })
                          }
                           
                          </select>
                            
                          </div>


                          </div>

                       


                        


                           { loader !== false ?
                            <div class="d-flex" style={{marginLeft:10}}>
                                            <div class="spinner-border" role="status"></div>
                                        </div>
                                        : null }
                        
                      </form>
                    </div>
                    <div className="col-lg-4">
                      <div className="text-lg-left mt-3 mt-lg-3">
                        
                      <button type="button" style={{marginLeft:10}} onClick={()=> viewAllAssessmentCategory()}  className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-eye mr-1" /> View Capture</button>
                     
                      
                      </div>


                   
                    </div>{/* end col*/}
                  </div> {/* end row */}
                </div> {/* end card-box */}
              </div> {/* end col*/}
            </div>

      

            <div className="row">
              <div className="col-12">
                          { notify === null ? null : notify }

                          { loadAssessment === null ? null :
            <div>
                                     <div className="card">
                                         <div className="card-body">

                                              { captureStatus !== false ? 
                                              
                                              grade === 'R' ? 
                                              
                                              <div>
                                              <button className="btn btn-primary waves-effect waves-light" style={{float:'right'}} type='button' onClick={()=>setCaptureStatus(false)}>Back to all Task</button>
                                       <h3 className="card-title">{gterm+' - '+subjectN+' ('+studN+')' }</h3>
                                       <small><strong>Note: score are saved automatically. You can re-score this learner anytime you wish </strong></small><br></br>
                                       <br></br>
                                          { gradeRdata !== null ?
                         
                                             gradeRdata && gradeRdata.map(({topic,question,term,score,assessment_upload_id,topic_id},index)=>{
                                                 return(
                         
                                                   <div className="row" key={index}>
                         
                                                   
                                                 <div className="table-responsive">
                                                 <table className="table mb-0">
                                                   <thead>
                                                     <tr style={{backgroundColor:'darkblue', color:'white'}}>
                                                       <th ></th>
                                                       <th ><strong>{index+1}</strong>) Topic: {topic}</th>
                                                       <th></th>
                                                      
                                                     </tr>
                                                     </thead>
                                                      
                                                           { displayQuestion(question,score,topic_id,term,assessment_upload_id,topic)}
                                                       
                         
                                                         </table>
                                                     </div>
                                 
                                                     
                                                 </div>
                         
                         
                                                 )
                                             })
                                          
                                             
                                             :
                                              <div className="alert alert-info text-center">
                                                No data found 
                                                </div>
                                         }
                         
                                          </div>
                                             
                                              :
                                              displayHigherGradeQuestion()
                                              
                                              :
                                 
                                             <div className="table-responsive">
                                 
                                                 <table className="table table-centered table-nowrap table-striped" id="products-datatable">
                                                     <thead>
                                                     <tr>
                                                         <th style={{width: '20px'}}>
                                                         #
                                                         </th>
                                                         <th>Student</th>
                                                         <th>Subject</th>                          
                                                         <th>Grade</th>
                                                         <th>Assessment</th>
                                                      
                                                         <th>Action</th>
                                                      </tr>
                                                     </thead>
                                                     <tbody>
                                                        { loadAssessment && loadAssessment.map(({cate,invigilator_id,grade,subject_name,studentName,student_id,subject_id,tasktype,mark,overall_mark,assess_upload_id,id,terms,year},index)=>{
                                                            return(
                                                               <tr key={index}>
                                                                 <td>{ index+1 }</td>
                                                                 <td>{ studentName }</td>
                                                                 <td>{ subject }</td>
                                                                 <td>{ grade }</td>
                                                               
                                                                 <td>{ cate }</td>
                                                                 <td >
                                                                
                                                                 { grade === 'R' ?  
                                                                
                          checkAlreadyScore === 'modal' ? 
                           <button type="button" onClick={()=>arrangeStudentCaptureResult(student_id,subject_id,terms,invigilator_id,year,studentName,subject_name,'edit')} data-toggle="modal" data-target="#staticBackdrop"  className="btn btn-primary btn-sm waves-effect waves-light"> Capture Mark </button>
                            :
                        <button type="button" onClick={()=>arrangeStudentCaptureResult(student_id,subject_id,terms,invigilator_id,year,studentName,subject_name,'none')}  className="btn btn-primary btn-sm waves-effect waves-light"> Capture Mark </button>
                              : null }                              

                   
                          { grade === 'R' ? null : 
                          <button type="button" onClick={()=>captureOthers(student_id,subject_id,year,studentName,subject_name,invigilator_id,terms)}  className="btn btn-primary btn-sm waves-effect waves-light"> Capture Mark </button>
                                                            }

   
                                                                 </td>
                                                               </tr>
                                                            )
                                                        })}
                                                     </tbody>
                                                 </table>
                                 
                                             </div>
}
                                 
                                         </div> {/* end card-body*/}
                                     </div> {/* end card*/}
                                 </div>
                                        
                          }
            </div>
            </div>


<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                    <h5 className="modal-title" id="staticBackdropLabel" style={{marginLeft:10, marginTop:20}}>Notcie!</h5>
                                                          
                                                        <div className="modal-body">
                                                            <p>Result for this particular subject for this student has been compile for this term. If you wish to re-compile this result, kindly click continue but note previous result compilation for this term  will be lost</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                        <button type="button" className="btn btn-light" data-dismiss="modal">Close</button>
                                                            <button type="button" onClick={()=>continueUpdateQuest()} className="btn btn-primary" data-dismiss="modal">Yes Understood</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

            </div>
            </div>
        </div>
            
        </div>
    )
}

export default CaptureAssessment
