import axios from 'axios';
import React,{useState, useEffect} from 'react'
import '../Nav/LoadCss.css';
import MaterialTable from '@material-table/core'
import { Button } from "@material-ui/core";

function Subject() {
  const currencyFormat = (num) => {
    return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }
    const [viewGrade, setViewGrade] = useState('');
    const [price, setPrice] = useState('');
    const [subjectName, setSubjectName] = useState('');
    const [notifyAdd, setNotifyAdd] = useState(null);
    const [loaderSubject, setLoaderSubject] = useState(false);
    const [loadRemove, setLoadRemove] = useState(false);
    const [notifyDele, setNotifyDele] = useState(null);

    const [remID, setRemID] = useState();

    const [editPrice, setEditPrice] = useState('');
    const [editName, setEditName] = useState('');
    const [editGrade, setEditGrade] = useState('');
    const [subjectCode, setSubjectCode] = useState();

    const [editStatus, setEditStatus] = useState(false);


    const [subjectLang, setSubjectLang] = useState('');

    const [loader, setLoader] = useState(false);
    const [loadSubject, setLoadSubject] = useState();
    const [notify, setNotify] = useState(false);
    const [subStatus, setSubStatus] = useState(null);
    const [subData, setSubData] = useState(false);
    const [subject, setSubject] = useState('');
    const [subjectCate, setSubjectCate] = useState('');
    const [currSubCate, setCurrSubCate] = useState();
    const [language, setLanguage] = useState();
    const [displayWrn, setDisplayWrn] = useState(false);
    const gradeData = [
      
       {grade:'R',id:1},
      {grade:'1',id:3},
      {grade:'2',id:4},
      {grade:'3',id:5},
      {grade:'4',id:6},
      {grade:'5',id:7},
      {grade:'6',id:8},
      {grade:'7',id:9},
      {grade:'8',id:10},
      {grade:'9',id:11},
      {grade:'10',id:12},
      {grade:'11',id:13},
      {grade:'12',id:14}   
];


const deleteSubject = (subID) =>{
  setRemID(subID)
  setDisplayWrn(true)
}
 
const getSubject = () => {
  
  if(!subject.trim()){ 
         setNotify(true);
         setSubData(false);
         setSubStatus(<div className="alert alert-info text-center">Kindly select the grade you want to view </div>);
  }else{
    setNotify(false);
      setLoader(true)
  axios.get('/get-subject-cate/'+subject,{
    headers: { 'Content-Type': 'application/json'}
  })
    .then((response)=>{
          setSubData(true)

        setTimeout(()=>{
             setLoader(false)
          if(response.data.subjectData === 'not allow'){
            setNotify(true);
            setSubData(false);
            setSubStatus(<div className="alert alert-info text-center">No subject found for grade {subject} </div>);
          }else
          {
            setSubData(true);
            setLoadSubject(response.data.subjectData);

          }
        },2000);
        
         
    })
  }
  


}

const addSubject = () => {
    if(!subjectName.trim() || !viewGrade.trim() || !subjectCate.trim()){
            setNotifyAdd(<div className="alert alert-info text-center">All form field must be fill</div>);
    }else
    {
      setLoaderSubject(true)
      setNotifyAdd(null)
            axios.post('/add-subject',{
                price:price,
                subject:subjectName,
                viewGrade:viewGrade,
                subjectCate:subjectCate,
                 subjectLang:subjectLang
            },{
              headers: { 'Content-Type': 'application/json'}
            }).then((resp)=>{
                   
                   setTimeout(()=>{
                    setLoaderSubject(false)
                    if(resp.data.status === 'not allow'){
                      setNotifyAdd(<div className="alert alert-danger text-center">{ resp.data.message }</div>);
                    }else
                    {
                      setNotifyAdd(<div className="alert alert-success text-center">{ resp.data.message }</div>);
                      setTimeout(()=>{
                            window.location.reload(false);
                      },2000)
                    }
                   },2000)
                 
            })
    }
}

   const editGradeSubject = (id) =>{
                  setNotifyAdd(null)
                  setEditStatus(true)
              axios.get('/update-user-subject/'+id,{
                headers: { 'Content-Type': 'application/json'}
              })
              .then((response)=>{

                    setEditGrade(response.data.gradeData[0].grade);
                    setEditName(response.data.gradeData[0].subject);
                    setEditPrice(response.data.gradeData[0].price);
                    setSubjectCode(response.data.gradeData[0].subject_id);
                    setCurrSubCate(response.data.gradeData[0].subject_cate);
                    setLanguage(response.data.gradeData[0].lang);
              })
   }

   const updateSubject = () =>{
            setLoaderSubject(true);
              setNotifyAdd(null)
          axios.put('/update-subject-data',{
                editgrade:editGrade,
                editName:editName,
                editPrice:editPrice,
                subectCode:subjectCode,
                subjectCate:subjectCate,
                subjectLang:subjectLang
          },{
            headers: { 'Content-Type': 'application/json'}
          }).then((response)=>{
                  setTimeout(()=>{
                      setLoaderSubject(false);
                    if(response.data.status === false){
                      setNotifyAdd(<div className="alert alert-danger text-center">{ response.data.updateResponse }</div>);
                 }else
                 {
                  setNotifyAdd(<div className="alert alert-success text-center">{ response.data.updateResponse }</div>);
                 }
                    setTimeout(()=>{
                      setNotifyAdd(null);
                    },5000);
                  },2000);
                
          })
   }

   const removeSubject = () =>{
              setLoadRemove(true)
            axios.post("/remove-subject-data",{
                 sid:remID
            },{
              headers: { 'Content-Type': 'application/json'}
            }).then((response)=>{
                      setTimeout(()=>{
                        setLoadRemove(false)
                        if(response.data.status === false){
                          setNotifyDele(<div className="alert alert-danger text-center">{ response.data.message }</div>)
                          }else
                          {
                            setNotifyDele(<div className="alert alert-danger text-center">{ response.data.message }</div>)
                          }
                          setTimeout(()=>{
                               setNotifyDele(null);
                               window.location.reload(false)
                          },5000);
                      },2000);
                  
            })
   }

    return (
        <div id="wrapper">

{ loadRemove !== false ?
            <div id="preloader-spinners" className="preloaders spinners">

<div className="wrappers">
    <span className="loader"></span>

    <p style={{marginTop:120}}>Removing Subject. Please wait...</p>
</div>

</div>
              : null }

<div className="content-page">
        <div className="content">
          {/* Start Content*/}
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    
                  </div>
                  <h4 className="page-title">Subjects</h4>
                </div>
              </div>
            </div>     
            {/* end page title */} 

           
            <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-8">
                      <form className="form-inline">
                       
                        <div className="form-group mx-sm-3 ">
                         
                          <select className="custom-select " id="status-select" onChange={(e)=>setSubject(e.target.value)}>
                            <option value=''>Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
                          <button type="button" style={{marginLeft:10}} onClick={()=> getSubject()} className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-eye mr-1" /> View subject</button>
                          { loader !== false ?
                            <div class="d-flex" style={{marginLeft:10}}>
                                            <div class="spinner-border" role="status"></div>
                                        </div>
                                        : null }
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-4">
                      <div className="text-lg-right mt-3 mt-lg-0">
                        
                        <button type="button" className="btn btn-success waves-effect waves-light" data-toggle="modal" data-target="#centermodal" data-backdrop="static"><i className="mdi mdi-plus-circle mr-1" /> Add Subject</button>
                     
                      </div>


                   
                    </div>{/* end col*/}
                  </div> {/* end row */}
                </div> {/* end card-box */}
              </div> {/* end col*/}
            </div>


            <div className="row">
             
               { editStatus === false ? 
              <div className="col-12">

            
            
        
                    { displayWrn === false ? null : 
                <div className="alert alert-danger bg-* border-0">
          <p className="mt-3">Are you sure you want to remove this subject. Student won't be able to offer this subject any more</p>
                <button type="button" onClick={()=> removeSubject()} className="btn btn-info my-2">Continue</button> &nbsp;&nbsp; &nbsp;&nbsp;
                <button type="button" onClick={()=> setDisplayWrn(false)} className="btn btn-dark my-2">Cancel</button>
                  </div>

                    }

                
                          { notifyDele !== null ? notifyDele : null }
                          
                              

                                {  
                                
                                
                                subData === false ? null :
                                  
                                  loadSubject &&  <MaterialTable
                                  title="Subjects"
                                  columns={[
                                    { title: 'Subject', field: 'subject' },
                                    { title: 'Language of Instruction', field: 'lang' },
                                    { title: 'Subject Category', field: 'subject_cate' },
                                    { title: 'Subject Code', field: 'subject_id' },
                                    { title: 'Price (R)', field: 'price' },
                                  ]}
                                  data= {
                                    loadSubject
                                  }
                      
                      
                                  actions={[
                                    {
                                      icon:'edit',
                                      tooltip: 'Edit Subject',
                                      onClick: (event, rowData) => editGradeSubject(rowData.subject_id)
                                    },
                                    rowData => ({
                                      icon: 'delete',
                                      tooltip: 'Delete Subject',
                                      onClick: (event, rowData) => deleteSubject(rowData.subject_id),
                                     
                                    } )
                                  ]}


                                  options={{
                                    actionsColumnIndex: -1
                                  }}
                                  
                                  />


                                }


   
                           
            </div>
  :
            <div className="col-12">

           
                                <div className="card">
                                    <div className="card-body">
                                    <button type="button" className="btn btn-secondary waves-effect pull-right" onClick={()=> setEditStatus(false)} style={{float:'right', clear:'both'}}>Back to All Subject</button>
        
                                        <h4 className="header-title">Update Subject</h4>
                                               
                                       
             
          
              { notifyAdd !== null ? notifyAdd : null }


              <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Language of Instruct</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setSubjectLang(e.target.value)}>
                  
                               <option value={language}>Current Language: { language }</option>
                               <option value="Afrikaans">Afrikaans</option>
                              <option value="English">English</option>
                          </select>
              </div>
            </div>
          </div>


              <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Category</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setSubjectCate(e.target.value)}>

                               <option value={currSubCate}>Current Cate: { currSubCate }</option>
                               <option value='Cores'>Cores</option>
                               <option value='Elective'>Elective</option>
                          </select>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Subject Grade</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setEditGrade(e.target.value)} >
                            <option value={editGrade}>{ 'Grade '+editGrade }</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
              </div>
            </div>
          </div>
        
         
          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Subject Name</label>
                <input className="form-control" id="field-7" placeholder="Your subject" onChange={(e)=> setEditName(e.target.value)}  value={editName}  />
              </div>
            </div>
          </div>
        

          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Price of Subject</label>
                <input className="form-control" id="field-7" placeholder="Your subject" onChange={(e)=> setEditPrice(e.target.value)} value={editPrice}  />
              </div>
            </div>
          </div>
        
        
        
      
      


       
          
          { loaderSubject === false ?
          <button type="button" className="btn btn-primary waves-effect waves-light" onClick={()=> updateSubject()}>Update</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> 
}
       

            </div>
            </div>
         </div>
}
            </div>
  
           
          </div>
        </div> 
     
      </div>

       
                     {/* Center modal content */}
             <div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Add New Subject</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">

              { notifyAdd !== null ? notifyAdd : null }


              <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Language of Instruct</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setSubjectLang(e.target.value)}>
                               <option value=''>Select Language</option>
                               <option value="Afrikaans">Afrikaans</option>
                              <option value="English">English</option>
                          </select>
              </div>
            </div>
          </div>


              <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Category</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setSubjectCate(e.target.value)}>
                               <option value=''>Category</option>
                               <option value='Cores'>Cores</option>
                               <option value='Elective'>Elective</option>
                          </select>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Subject Grade</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setViewGrade(e.target.value)} >
                            <option selected>Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
              </div>
            </div>
          </div>
        
         
          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Subject Name</label>
                <input className="form-control" id="field-7" placeholder="Your subject" onChange={(e)=> setSubjectName(e.target.value)}   />
              </div>
            </div>
          </div>
        

          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Price of Subject</label>
                <input className="form-control" id="field-7" placeholder="Your subject" onChange={(e)=> setPrice(e.target.value)}  />
              </div>
            </div>
          </div>
        
        
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
          { loaderSubject === false ?
          <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=>addSubject()}>Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> 
}
        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}




     


            
        </div>
    )
}

export default Subject
