import React,{useState, useEffect} from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom';
import MaterialTable from '@material-table/core'






function Allparent() {

    const [numbcent, setNumbCent] = useState();
    const [status, setStatus] = useState(null);
    const [parentData, setParentData] = useState();
    const [notifyUser, setNotifyUser] = useState(null);
    const [parentID, setParentID] = useState();
    const [rid, setRid] = useState();
    const [chk, setChk] = useState(true)
    const history = useNavigate();

  
    const getNumberLearn = (userID) =>{
        axios.get('/parent-leaner/'+userID,{
          headers: { 'Content-Type': 'application/json'}
        })
        .then((response) => {
                setNumbCent(response.data.numCentre);

                  return response.data.numCentre;
        })
}

      const removeParent = () =>{
             const data = { userID:parentID, userType:'student',ID:rid}
             axios.post('/remove-parent-data',data,{
              headers: { 'Content-Type': 'application/json'}
             }).then((res)=>{
                  if(res.data.status === false){
                      setNotifyUser('Account was remove successfully');
                      setChk(false)
                      setTimeout(()=>{
                            setNotifyUser(null)
                      },5000);
                  }
             })
             
      }

      const showWarning = (parentID,id) =>{
              setParentID(parentID);
              setRid(id);
              document.getElementById('showM').click();
              
      }



        
     useEffect(()=>{
          axios.get('/display-all-student',{
            headers: { 'Content-Type': 'application/json'}
          })
          .then((response)=>{
               if(response.data.stud === false){
                    setStatus(<div className="alert alert-info text-center">No registered Student</div>);
               }else{
                  // console.log(response.data.tutorDetails)
                      setParentData(response.data.stud);
               }
          })
     },[chk])
    return (
        <div id="wrapper">
            <div className="content-page">
        <div className="content">
          {/* Start Content*/}
          <div className="container-fluid" >
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Registered</li>
                     
                      <li className="breadcrumb-item active">Registered Student</li>
                    </ol>
                  </div>
                  <h4 className="page-title">All Student</h4>
                </div>
              </div>
            </div>     
            {/* end page title */} 
            <div className="row">
              <div className="col-12">

              <button  type="button" id='showM' class="btn btn-danger" style={{display:'none'}} data-toggle="modal" data-target="#danger-alert-modal">Danger Alert</button>

              <div id="danger-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content modal-filled bg-danger">
                        <div class="modal-body p-4">
                            <div class="text-center">
                                <i class="dripicons-wrong h1 text-white"></i>
                                <h4 class="mt-2 text-white">Oh snap!</h4>
                                <p class="mt-3 text-white">You are about to delete this Student profile. All details of this Student will be removed permanently.</p>
                                <p class="mt-1 text-white">Are you sure you want to continue.</p>
                               
                               
                            </div>
                        </div>

                        <div class="modal-footer justify-content-center">
                                  <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
                                 <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={()=> removeParent()}>Delete</button>
                             </div>
                    </div>
                </div>
            </div>
             
                    { notifyUser === null ? null : <div className="alert alert-info text-center">{ notifyUser }</div>}
                
                      { status !== null ? status : 
              
            parentData &&  <MaterialTable
            title="All registered parent data"
            columns={[
              { title: 'First Name', field: 'firstname' },
              { title: 'Last Name', field: 'lastname' },
              { title: 'Grade', field: 'grade' },
              { title: 'Passport Number', field: 'student_id_pass' },
              { title: 'Student ID', field: 'student_id' },
            ]}
            data= {
                parentData
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View User',
                onClick: (event, rowData) => history('/student-data/details/'+rowData.student_id+'/'+rowData.userid)
              },
              rowData => ({
                icon: 'delete',
                tooltip: 'Delete User',
                onClick: (event, rowData) => showWarning(rowData.student_id,rowData.id),
               
              })
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />
                

}
                 
                    
                    {/*pagin*/}
                
              </div> {/* end col */}
            </div>
            {/* end row */}
          </div> {/* container */}
        </div> 
        </div>
            </div>
    )
}

export default Allparent
