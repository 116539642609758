import axios from 'axios'
import React,{useEffect, useState} from 'react'
import MaterialTable from '@material-table/core'

export default function Fee() {
      const [allFee, setAllFee] = useState(null);
      const [notify, setNotify] = useState(null);
      const [notifyAdd, setNotifyAdd] = useState(null);
      const [loaderSubject, setLoaderSubject] = useState(false);

      const [feeGrade, setFeeGrade] = useState();
      const [feeMonth, setFeeMonth] = useState();
      const [feeYear, setFeeYear] = useState();

      const [editfeeGrade, setEditFeeGrade] = useState();
      const [editfeeMonth, setEditFeeMonth] = useState();
      const [editfeeYear, setEditFeeYear] = useState();
      const [feeID, setEditID] = useState();
      const [viewEdit, setViewEdit] = useState(false);
      const [gradeRFee, setGradeRFee] = useState(null);
      const [packageName, setPackageName] = useState('');
      const [packagePrice, setPackagePrice] = useState('');
      const [pid, setPid] = useState();

      const gradeData = [
        {grade:'R',id:1},
       {grade:'1',id:2},
       {grade:'2',id:3},
       {grade:'3',id:4},
       {grade:'4',id:5},
       {grade:'5',id:6},
       {grade:'6',id:7},
       {grade:'7',id:8},
       {grade:'8',id:9},
       {grade:'9',id:10},
       {grade:'10',id:11},
       {grade:'11',id:12},
       {grade:'12',id:13}   
     ];

      const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }


      const addNewFee = () =>{
             setLoaderSubject(true)
            axios.post('/add-newfee-data',{
                  feeGrade:feeGrade,
                  feeMonth:feeMonth,
                  feeYear:feeYear
                  
            },{
                headers: { 'Content-Type': 'application/json'} 
            }).then((res)=>{
                        setTimeout(()=>{
                             setLoaderSubject(false);
                              setNotifyAdd(<div className="alert alert-info text-info">{ res.data.fData }</div>);
                               setTimeout(()=>{
                                window.location.reload(false)
                               },1000);
                        },2000)
            })
      }


      const EditNewFee = (feeyr,feemth,grd,id) =>{
            //price,r_package,'R',pid
         
              setEditFeeGrade(grd);
              setEditFeeMonth(feemth);
              setEditFeeYear(feeyr);
              setEditID(id)


             setViewEdit(true);
      }

      const UpdateFee = () =>{
            axios.put('/update-grade-fee',{
               editGrade:editfeeGrade,
               editMonth:editfeeMonth,
               editfeeYear:editfeeYear,
               feeID:feeID
            },{
                headers: { 'Content-Type': 'application/json'} 
            }).then((res)=>{
                setNotifyAdd(<div className="alert alert-info text-info">{ res.data.upData }</div>);
            })
      }

      const UpdateFee2 = () =>{
        
        axios.put('/update-grade-fee',{
           editGrade:'R',
           editMonth:packageName,
           editfeeYear:packagePrice,
           feeID:pid
        },{
            headers: { 'Content-Type': 'application/json'} 
        }).then((res)=>{
            setNotifyAdd(<div className="alert alert-info text-info">{ res.data.upData }</div>);
        })
  }

      const removeData = (grd,id) =>{
             axios.post('/remove-data-fee',{
                 feeID:id,
                 grade:grd
             },{
                headers: { 'Content-Type': 'application/json'}  
             }).then((res)=>{
                setNotify(<div className="alert alert-info text-info">{ res.data.report }</div>);
                 window.location.reload(false)
             })
      }


       useEffect(()=>{
            axios.get('/fetch-all-fee',{
                headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                  if(res.data.feeData === false){
                        setNotify(<div className="alert alert-info text-info">No data found</div>)
                  }else
                  {
                        setAllFee(res.data.feeData);
                  }

                  if(res.data.gradeR === false){
                          setGradeRFee(null)
                  }else{
                          setGradeRFee(res.data.gradeR)
                  }
            })
       },[])


       const EditRFee = (price,r_package,grde,pid) =>{
       
                setPackageName(r_package);
                setPackagePrice(price);
                setPid(pid);
       }


    return (
        <div id="wrapper">
        <div className="content-page">
    <div className="content">
    <div className="container-fluid">

    <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    
                  </div>
                  <h4 className="page-title">Fees</h4>
                </div>
              </div>
            </div>     
          


 <div className="row">


 <div className="col-12">
 <button type="button" style={{float:'right', marginBottom:20}} className="btn btn-success waves-effect waves-light pull-right" data-toggle="modal" data-target="#centermodal" data-backdrop="static"><i className="mdi mdi-plus-circle mr-1" /> Add Fee</button>
           </div>  

                { viewEdit !== false ? 

                  <div className="col-12">


                  <div className="card">
                                    <div className="card-body">
                                    <button type="button" className="btn btn-secondary waves-effect pull-right" onClick={()=> setViewEdit(false)} style={{float:'right', clear:'both'}}>Back to All Fee</button>
        
                                    <h4 className="header-title">Update Fee</h4>
    
          

              { notifyAdd !== null ? notifyAdd : null }

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Grade</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setFeeGrade(e.target.value)} >
                            <option value={editfeeGrade}>Current Grade {editfeeGrade}</option>
                            { gradeData.map((data) =>(
                                     data.grade === 'R'? null :
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
              </div>
            </div>
          </div>
        
      
        
          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Monthly Fee</label>
                <input className="form-control" id="field-7" placeholder="Your Fee" value={editfeeMonth}  onChange={(e)=>setEditFeeMonth(e.target.value)}  />
              </div>
            </div>
          </div>
        


          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Yearly Fee</label>
                <input className="form-control" id="field-7" placeholder="Your Fee " value={editfeeYear} onChange={(e)=>setEditFeeYear(e.target.value)}  />
              </div>
            </div>
          </div>
        
      

       
          { loaderSubject === false ?
          <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> UpdateFee()}>Update Fee</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> 
}
     


</div>
</div>
                  </div>
  :


  


                <div className="col-12">
                   { notify === null ? null : notify }

              { gradeRFee === null ? <div className='alert alert-info text-center'>No set for grade R </div> :
              <table className='table'>
                <thead>
                  <tr>
                    <th>Package Name</th>
                    <th>Package Price (R)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  { gradeRFee && gradeRFee.map(({price,r_package,pid},index)=>{
                       return(
                              <tr key={index}>
                                <td>{r_package}</td>
                                <td>{price}</td>
                                <td> <button type="button" onClick={()=>EditRFee(price,r_package,'R',pid)} className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centerGradeR" data-backdrop="static"> Edit</button>
                                &nbsp;&nbsp;&nbsp;
                                <button type="button" onClick={()=>removeData('R',pid)} className="btn btn-blue waves-effect btn-sm waves-light"> Remove</button>
                                </td>
                              </tr>
                       )
                  })}
                </tbody>
              </table>
}

              <br></br>
                
               

      
        
        { allFee === null ? null : 


            <MaterialTable
            title="All Fee"
            columns={[
              { title: 'Monthly(R)', field: 'term' },
              { title: 'Yearly(R)', field: 'year' },
              { title: 'Grade', field: 'grade' },

              
            ]}
            data= {
              allFee
            }


            actions={[
              {
                icon:'edit',
                tooltip: 'View',
                onClick: (event, rowData) => EditNewFee(rowData.year,rowData.term,rowData.grade,rowData.id)
              },
              rowData => ({
                icon: 'delete',
                tooltip: 'Delete Fee',
                onClick: (event, rowData) => removeData(null,rowData.id),
                disabled: rowData.birthYear < 2000
              })
]}
options={{
  actionsColumnIndex: -1




}}


/>


}


<div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Add New Fee</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">

              { notifyAdd !== null ? notifyAdd : null }

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Grade</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setFeeGrade(e.target.value)} >
                            <option selected>Grade</option>
                            { gradeData.map((data) =>(
                                    
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
              </div>
            </div>
          </div>
        
      
        
          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label"> { feeGrade === 'R' ? ('Package Name') : 'Monthly Fee' }</label>
                <input className="form-control" id="field-7"  onChange={(e)=>setFeeMonth(e.target.value)}  />
              </div>
            </div>
          </div>
        


          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">{ feeGrade === 'R' ? ('Package Fee') : 'Yearly Fee' }</label>
                <input className="form-control" id="field-7"  onChange={(e)=>setFeeYear(e.target.value)}  />
              </div>
            </div>
          </div>
        
        

        
        
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
          { loaderSubject === false ?
          <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> addNewFee()}>Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> 
}
        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


                            
                </div>

}
            </div>
           


            <div className="modal fade" id="centerGradeR" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Update Grade R Fee</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">

              { notifyAdd !== null ? notifyAdd : null }

        
      
        
          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Package Name</label>
                <input className="form-control" id="field-7" placeholder="Your Fee" value={packageName} onChange={(e)=>setPackageName(e.target.value)}  />
              </div>
            </div>
          </div>
        


          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Package Fee</label>
                <input className="form-control" id="field-7" placeholder="Your Fee " value={packagePrice} onChange={(e)=>setPackagePrice(e.target.value)}  />
              </div>
            </div>
          </div>
        
        

        
        
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
         
          <button type="button" className="btn btn-info waves-effect waves-light" onClick={()=> UpdateFee2()}>Submit</button>
        

        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}

        </div>
        </div>
        </div>   
        </div>
    )
}
