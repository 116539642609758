import React, { useState, useEffect } from 'react';
import '../Nav/LoadCss.css';
import MaterialTable from '@material-table/core'
import { Button } from "@material-ui/core";
import axios from 'axios';
import $ from 'jquery';
import HTMLReactParser from 'html-react-parser';
import jsPDF from 'jspdf'


function TermsFinal() {

    const [studentData, setStudentData] = useState(null);
    const [studID, setStudID] = useState();
    const [grade, setGrade] = useState();
    const [viewFinal, setViewFinal] = useState(false);
    const [studentName, setStudentName] = useState();
    const [email, setEmail] = useState();
    const [lang, setLang] = useState();
    const [centreID, setCentreID] = useState();
    const [centreStatus, setCentreStatus] = useState(false);
    const [centreName, setCentreName] = useState();
    const [centrePhone, setCentrePhone] = useState();
    const [centreEmail, setCentreEmail] = useState();
    const [centreAddress, setCentreAddress] = useState();
    const [t1,setT1] = useState('');
    const [t2,setT2] = useState('');
    const [t3,setT3] = useState('');
    const [t4,setT4] = useState('');
    const [years, setYears] = useState();

    const [disterms, setDisTerm] = useState(false)
    const [final,setFinal] = useState(false);
    const [cfinal,setCfinal] = useState('');
    const [allowBtn, setAllowBtn] = useState(false);

    const [createLoad, setCreateLoad] = useState(false);
    const [hideShow, setHideShow] = useState(false);
    const [notes, setNote] = useState(false)

    const [viewReport, setViewReport] = useState(null);

    const [alertStatus, setAlertStatus] = useState(null);


    const gradeData = [
        {grade:'R',id:1},
       {grade:'1',id:3},
       {grade:'2',id:4},
       {grade:'3',id:5},
       {grade:'4',id:6},
       {grade:'5',id:7},
       {grade:'6',id:8},
       {grade:'7',id:9},
       {grade:'8',id:10},
       {grade:'9',id:11},
       {grade:'10',id:12},
       {grade:'11',id:13},
       {grade:'12',id:14}   
 ];

 const checkTerms = () =>{
    if(t1 !== '' && t2==='' && t3==='' && t4==='' && cfinal===''){
        return ('Result compilation for term 1')
    }else
    if(t1 !== '' && t2 !=='' && t3==='' && t4==='' && cfinal===''){
      return ('Result compilation for term 1 & 2')
    }else
    if(t1 !== '' && t2 !=='' && t3 !=='' && t4==='' && cfinal===''){
      return ('Result compilation for term 1,2 & 3')
    }else
    if(t1 !== '' && t2 !=='' && t3 !=='' && t4 !=='' && cfinal===''){
      return ('Result compilation for term 1,2,3 & 4')
    }else if(t1 !== '' && t2 !=='' && t3 !=='' && t4 !=='' && cfinal !==''){
      return ('Final result compilation');
    }
}

$(document).ready(function(){
    $("#create-report").click(function(){
        let ye = $("#useryears").val();
         if($(".yearcont").val() === ''){
             setAlertStatus(<div className='alert alert-info text-center'>Please select Year before proceeding</div>)
         }else{
              setAlertStatus(null)
               
         let termsfinals = 'allow';
         let dataDetails = {term1:t1,term2:t2,term3:t3,term4:t4,cfinal:cfinal,studID:studID,year:ye,termsfinals:termsfinals,grade:grade}
         let base_url = 'https://termfinals-config.equationeducators.com/control_data.php';
          $.ajax({
            url:base_url,
            type:'POST',
             data:dataDetails,
             success:function(res){
                setViewReport(res);
             }
          })
        }
    })
})



const generateCoverPages = () => {
    let doc = new jsPDF("p", "pt", "a1");
    doc.html(document.querySelector("#coverPages"),{
       callback: (pdf) =>{
               
                pdf.save("Terms-report.pdf");
       }
    })
}

const controlCheckBox = (e,dk) =>{
    const checked = e.target.checked;
 
switch(dk){
  case 'term1':
    if(checked){
      setT1('term1')
      setFinal(false)
      setAllowBtn(true)
      setCfinal('')
    }else
    {
      setT1('')
      setFinal(false)
      setAllowBtn(false)
      setCfinal('')
    }
    break;
  case 'term2':
    if(checked){
      setT2('term2')
      setFinal(false)
      setAllowBtn(true)
      setCfinal('')
    }else
    {
      setT2('')
      setFinal(false)
      setAllowBtn(false)
      setCfinal('')
    }
    break;
  case 'term3':
    if(checked){
      setT3('term3')
      setFinal(false)
      setAllowBtn(true)
      setCfinal('')
    }else
    {
      setT3('')
      setFinal(false)
      setAllowBtn(false)
      setCfinal('')
    }
      break;
   case 'term4':
      if(checked){
        setT4('term4')
        setCfinal('')
        setFinal(false)
        setAllowBtn(true)
      }else
      {
        setCfinal('')
        setT4('')
        setFinal(false)
        setAllowBtn(false)
      }
  
        break;
   case 'final':
        if(checked){
           setNote(true)
          setDisTerm(true)
          setFinal(false)
          setCfinal('final')
          setT1('term1')
          setT2('term2')
          setT3('term3')
          setT4('term4')
          setAllowBtn(true)
        }else
        {
          setCfinal('')
          setT1('')
          setT2('')
          setT3('')
          setT4('')
          setNote(false)
          setFinal(true)
          setDisTerm(false)
          setAllowBtn(false)
        }
     break;
    
}

}



   const viewTermsFinalStudent = (student_id,grade,fname,email,lang_intruct,student_centre_id) => {
    
                    setViewFinal(true);
                    setGrade(grade);
                    setStudentName(fname)
                    setEmail(email)
                    setLang(lang_intruct)
                    setStudID(student_id);

                    if(student_centre_id){

                         setCentreStatus(false)
                         displayCentreInfo(student_centre_id)
                    }else{
                        setCentreStatus(true)
                       
                    }
                    

   }

   const displayCentreInfo =(cid) =>{
            axios.get('/centre-update-data/'+cid,{
                headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                    setCentrePhone(res.data.tutorData[0].centrePhone)
                    setCentreEmail(res.data.tutorData[0].centreEmail)
                    setCentreAddress(res.data.tutorData[0].centreAddress)
                    setCentreName(res.data.tutorData[0].centreName)
            })
   }

   const displayTemp = () =>{
         return (
             <div className='row'>

             <div className='col-lg-12'>
            <div className="card">
            <div className="card-body">
            {alertStatus === null ? null : alertStatus}
                <h4 className="header-title">Create Report</h4>
                <button type="button" class="btn btn-primary waves-effect waves-light" onClick={()=>setViewFinal(false)} style={{float:'right'}}>Back to all Student</button>
                                   
                                                     <div className="form-group mb-3 col-md-3">
                                                  
                                                        <label for="example-select">Select Year</label>
                                                        <select onChange={(e)=>setYears(e.target.value)} className="form-control yearcont" id="useryears">
                                                        <option value=''>Select Year</option>
                                                        <option value={2021}>2021</option>
                                                        <option value={2022}>2022</option>
                                                        <option value={2023}>2023</option>
                                                        <option value={2024}>2024</option>
                                                        <option value={2025}>2025</option>
                                                        <option value={2026}>2026</option>
                                                        <option value={2027}>2027</option>
                                                        <option value={2028}>2028</option>
                                                        <option value={2029}>2029</option>
                                                        <option value={2030}>2030</option>
                                                        </select>
                                                    </div> 


                                                 { disterms !== false ?
                                                 <div className="checkbox checkbox-success form-check-inline">
                                                <input type="checkbox" id="inlineCheckbox1" value="option1" disabled checked />
                                                <label for="inlineCheckbox1"> Term 1 </label>
                                                    </div> :
                                            <div className="checkbox checkbox-success form-check-inline">
                                            <input type="checkbox" id="inlineCheckbox1" value="option1" onClick={(e)=> controlCheckBox(e,'term1')} />
                                            <label for="inlineCheckbox1"> Term 1 </label>
                                                </div>
                                                 }


                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            { disterms !== false ?
                                                 <div className="checkbox checkbox-success form-check-inline">
                                                <input type="checkbox" id="inlineCheckbox1" value="option1" disabled checked />
                                                <label for="inlineCheckbox1"> Term 2 </label>
                                                    </div> :
                                            <div className="checkbox checkbox-success form-check-inline">
                                            <input type="checkbox" id="inlineCheckbox1" value="option1" onClick={(e)=> controlCheckBox(e,'term2')} />
                                            <label for="inlineCheckbox1"> Term 2 </label>
                                                </div>
                                                 }

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            { disterms !== false ?
                                                 <div className="checkbox checkbox-success form-check-inline">
                                                <input type="checkbox" id="inlineCheckbox1" value="option1" disabled checked />
                                                <label for="inlineCheckbox1"> Term 3 </label>
                                                    </div> :
                                            <div className="checkbox checkbox-success form-check-inline">
                                            <input type="checkbox" id="inlineCheckbox1" value="option1" onClick={(e)=> controlCheckBox(e,'term3')} />
                                            <label for="inlineCheckbox1"> Term 3 </label>
                                                </div>
                                                 }

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                             { disterms !== false ?
                                                 <div className="checkbox checkbox-success form-check-inline">
                                                <input type="checkbox" id="inlineCheckbox1" value="option1" disabled checked />
                                                <label for="inlineCheckbox1"> Term 4 </label>
                                                    </div> :
                                            <div className="checkbox checkbox-success form-check-inline">
                                            <input type="checkbox" id="inlineCheckbox1" value="option1" onClick={(e)=> controlCheckBox(e,'term4')} />
                                            <label for="inlineCheckbox1"> Term 4 </label>
                                                </div>
                                                 }
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div className="checkbox checkbox-success form-check-inline">
                                                <input type="checkbox" id="inlineCheckbox3" value="option1" onClick={(e)=> controlCheckBox(e,'final')} />
                                                <label for="inlineCheckbox3"> Final </label>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div className="form-check-inline">
                                           
                                            <button type="submit" class="btn btn-primary waves-effect waves-light" id="create-report" data-toggle="modal" data-target="#full-width-modal" data-backdrop="static">Create Report</button>
                                            </div>
            </div>
        </div> 
        </div>

        <div className="col-lg-4">
        <div className="card-box">
         
          <h5 className="mb-3 mt-1 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1" /> Personal Information</h5>
          <div className>
        
            <h4 className="font-13 text-muted text-uppercase mb-1">Names :</h4>
            <p className="mb-3"> { studentName }</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Email :</h4>
            <p className="mb-3"> { email }</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Grade :</h4>
            <p className="mb-3"> { grade }</p>
            <h4 className="font-13 text-muted text-uppercase mb-1">Language of Instruct :</h4>
            <p className="mb-0"> { lang }</p>
          </div>
        </div> {/* end card-box*/}

       

      </div>

                <div className='col-lg-4'>
                <div className="card-box">
         
         <h5 className="mb-3 mt-1 text-uppercase bg-light p-2"><i className="mdi mdi-account-circle mr-1" /> Centre Information</h5>
        
        { centreStatus !== false ? <div className='alert alert-info text-center'>Learner not in centre</div> : 
         <div className>
         
           <h4 className="font-13 text-muted text-uppercase mb-1">Names :</h4>
           <p className="mb-3"> { centreName }</p>
           <h4 className="font-13 text-muted text-uppercase mb-1">Email :</h4>
           <p className="mb-3">{ centreEmail }</p>
           <h4 className="font-13 text-muted text-uppercase mb-1">Phone :</h4>
           <p className="mb-3"> { centrePhone }</p>
           <h4 className="font-13 text-muted text-uppercase mb-1">Address :</h4>
           <p className="mb-0"> { centreAddress }</p>
         </div>
   }

       </div> {/* end card-box*/}
                </div>


      </div>
         )
   }

     useEffect(()=>{
            axios.get('/get-all-learner',{
                headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                    if(res.data.studData === false){
                            setStudentData(null)
                    }else{
                            setStudentData(res.data.studData);
                    }
            })
     },[])
    return (
        <div id="wrapper">
           <div className="content-page">
                <div className="content">

                    <div className="container-fluid">


                    <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Fast Excellence</li>
                                            <li className="breadcrumb-item">Terms &amp; Final</li>
                                       
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Terms &amp; Finals</h4>
                                </div>
                            </div>
                        </div>


         <div className="row">
        <div className="col-md-12">

            { 
            
              viewFinal !== false ?

                displayTemp()
            
            :
            studentData === null ? <div className='alert alert-info text-info'>No student found at this moment</div>
            :
            studentData && <MaterialTable
            title="All registered student data"
            columns={[
              { title: 'First Name', field: 'firstname' },
              { title: 'Last Name', field: 'lastname' },
              { title: 'Grade', field: 'grade' },
              { title: 'ID Number', field: 'student_id_pass' },
              {title: 'Language of Instruct', field: 'lang_intruct'},
              { title: 'Gender', field: 'gender' },
            ]}
            data= {
                studentData
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View User',
                onClick: (event, rowData) => viewTermsFinalStudent(rowData.student_id,rowData.grade,rowData.firstname+' '+rowData.lastname,rowData.email,rowData.lang_intruct,rowData.student_centre_id)
              },
              
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />   
            
            }

       
        </div> {/* end col */}

      </div>


      <div id="full-width-modal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="fullWidthModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-full-width">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="fullWidthModalLabel">Modal Heading</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body" id='coverPages'>

            <div className="row">

<div className="col-lg-12">
    <div className="card">
        <div className="card-body">

            <div className="invoice-title">
                <center>
                    <h1 className=" font-size-70"><b>Term Report {years}</b></h1>
                </center>

                <div className="mb-4">
                <img src="../assets/images/eq4.png" alt="" height={40} />
                </div>
            </div>
            <hr></hr>


            { viewReport === null ? null : HTMLReactParser(viewReport)}



            {/* end table-responsive */}
        </div>
    </div>
</div>
</div>


<div className="row">

<div className="col-lg-4">
    <div className="card">
        <div className="card-body">
            <center>
                <img src="../assets/images/sign.png" alt="logo" height={100} />
            </center>

            <hr style={{marginTop:-20}}></hr>
            <p className='text-center'>Wayne Cox (Head of Assessment)</p>
            <p>Head: Assessment Development and Marking/ Hoof: Assesseringsontwikkeling en Nasien</p>

            {/* end table-responsive */}
        </div>
    </div>
</div>
<div className="col-lg-2">

</div>
<div className="col-lg-6">
    <div className="card">
        <div className="card-body">


            <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                    <tr>

                        <th className="align-middle">Rating Code</th>
                        <th className="align-middle">Description of Competence</th>
                        <th className="align-middle">Percentage</th>


                    </tr>
                    </thead>
                    <tbody>
                    <tr>

                        <td className="text-center"> 7 </td>
                        <td><p>Outstanding achievement: Exceptional<br></br>
                                and commendable execution of the skill.</p></td>
                        <td>
                            80%-100%
                        </td>


                    </tr>
                    <tr>

                        <td className="text-center"> 6 </td>
                        <td><p>Meritorious achievement: Very good<br></br>
                                execution of the skill.</p></td>
                        <td>
                            70%-79%
                        </td>


                    </tr>

                    <tr>

                        <td className="text-center"> 5 </td>
                        <td><p>Substantial achievement: Good execution<br></br>
                                of the skill.</p></td>
                        <td>
                            60%-69%
                        </td>


                    </tr>

                    <tr>

                        <td className="text-center"> 4 </td>
                        <td><p>Adequate achievement: Satisfactory<br></br>
                                progress.</p></td>
                        <td>
                            50%-59%
                        </td>


                    </tr>

                    <tr>

                        <td className="text-center"> 3 </td>
                        <td><p>Moderate achievement: Little progress<br></br>
                                and assistance is required.</p></td>
                        <td>
                            40%-49%
                        </td>


                    </tr>

                    <tr>

                        <td className="text-center"> 2 </td>
                        <td><p>Elementary achievement: Very little<br></br>
                                progress and requires constant
                                assistance.</p></td>
                        <td>
                            30%-39%
                        </td>


                    </tr>

                    <tr>

                        <td className="text-center"> 1 </td>
                        <td><p>Not achieved: No progress and requires<br></br>
                                immediate attention.</p></td>
                        <td>
                            0%-29%
                        </td>


                    </tr>

                    </tbody>
                </table>
            </div>
            {/* end table-responsive */}
        </div>
    </div>
</div>
</div>


                    </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={()=> generateCoverPages()}>Download Result</button>
            </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}


                        </div>
                        </div>
       
        </div>
        </div>
    )
}

export default TermsFinal
