import React,{useState, useEffect} from 'react'
import axios from 'axios';
import $ from 'jquery';
import HTMLReactParser from 'html-react-parser';

function AssessmentSummary() {
 
  const [loader, setLoader] = useState(false);
  const [subjectID, setSubjectID] = useState();
  const [searchSubj, setSearchSubj] = useState(null);
  const [viewCate, setViewCate] = useState();
  const [grade, setGrade] = useState();
  const [years, setYears] = useState();
  const [assessYear, setAssessYears] = useState();
  const [loadAssessData, setAssessData] = useState(null);
  const [loadAss, setLoadAss] = useState();
  const [notify, setNotify] = useState(null);
  const [loadAssessment, setLoadAssessment] = useState(null);
  const [subject, setSubject] = useState();
  const [location, setLocation] = useState();
  const [assessID, setAssessID] = useState();
  const [centreNames,setCentreName] = useState();
  const [term, setTerm] = useState();
  const [gradeRstatus, setGradeRStatus] = useState(false);
  const [viewAssData, setViewAssesData] = useState(null);
  const [studentName, setStudentName] = useState();
  const [subjectName,setSubjectName] = useState();


  const ucate = [
    {cater:'Term 1',id:2},
   {cater:'Term 2',id:1},
  {cater:'Term 3',id:3},
  {cater:'Term 4',id:4},
  ];


    const category = [
        {cate:'Task',id:2},
       {cate:'Test',id:1},
      {cate:'Assignment',id:3},
      {cate:'Examination',id:4},
      {cate:'Project',id:5},
      {cate:'Practical',id:6}  
];

const gradeData = [
   {grade:'R',id:1},
  {grade:'1',id:3},
  {grade:'2',id:4},
  {grade:'3',id:5},
  {grade:'4',id:6},
  {grade:'5',id:7},
  {grade:'6',id:8},
  {grade:'7',id:9},
  {grade:'8',id:10},
  {grade:'9',id:11},
  {grade:'10',id:12},
  {grade:'11',id:13},
  {grade:'12',id:14}   
];

const srtSubject = (gradID) =>{
  setGrade(gradID);
axios.get('/get-subject-cate/'+gradID,{
headers:{ 'Content-Type': 'application/json'}
}).then((response)=>{

if(response.data.subjectData === false){

setSearchSubj(null) 
}else
{
setSearchSubj(response.data.subjectData);
}
})
}



const getAssessmentData = (subjectID) => {
        setSubjectID(subjectID);
       axios.post('/captureUserMarks-data',{
             grade:grade,
             year:assessYear,
             subjectID:subjectID,
             viewCate:viewCate,
             term:term
       },{
        headers:{ 'Content-Type': 'application/json'}
       }).then((response)=>{

              if(response.data.assessData === false){
                      setLoadAss(<option disabled>No data available</option>);
              }else{
                     setAssessData(response.data.assessData)
              }
       })

}




  const viewAllAssessmentCategory = () =>{
            setLoader(true);
             
          axios.post('/viewAllAssementCate',{
              grade:grade,
              year:assessYear,
              subjectID:subjectID,
              assessID:assessID,
              term:term
          },{
            headers:{ 'Content-Type': 'application/json'}
          }).then((response)=>{
               setTimeout(()=>{
                   setLoader(false)
                if(response.data.assessmentData === false){
                      setNotify(<div className="alert alert-info text-center">No assessment found</div>);
                       setLoadAssessment(null);
                }else{
                        setNotify(null);
                        setLoadAssessment(response.data.assessmentData);
                        setSubject(response.data.subject);

                }
               },2000);
              
          })
  }

   useEffect(()=>{
         axios.get('/getassessment-years',{
          headers:{ 'Content-Type': 'application/json'} 
         }).then((response) =>{
              setYears(response.data.years);
         })
   },[])


  const handleData = (studentName,subject_name,grade,subject_id,invigilator_id,studentID)=>{
    setGradeRStatus(true);
    setSubjectName(subject_name);
    setStudentName(studentName);
    

      let mData = {studentID:studentID,indown:invigilator_id,subject_id:subject_id,term:term,grade:grade,year:assessYear}
     let base_url = 'https://termfinals-config.equationeducators.com/control_data.php';
      $.ajax({
        url:base_url,
        data:mData,
         type:'POST',
         success:function(res){
              setViewAssesData(res)
         }
      })
 


}

const showDt = () =>{
     if(grade === 'R'){
        return(
          <div>
           <h4>Learner Name : { studentName}</h4>
          <h5>Subject : {subjectName }</h5>
          </div>
        
        )
     }else{
      return(
        <div>
        <h4>Learner Name : { studentName}</h4>
      
        </div>
      
      )
     }
}

    return (
        <div id="wrapper">
            <div className="content-page">
        <div className="content">
        <div className="container-fluid">

        <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    
                  </div>
                  <h4 className="page-title">Assessment Summary</h4>
                </div>
              </div>
            </div>     



             
            <div className="row">
              <div className="col-12">
                <div className="card-box">

                  <div className="row">
                    <div className="col-lg-8">
                      <form className="form-inline">
                      <div className="form-group mx-sm-3 ">
                      <select className="custom-select" id="status-select" onChange={(e)=>setTerm(e.target.value)}>
                            <option value=''> Term</option>
                            { ucate.map((data) =>(
                                    <option key={data.id} value={data.cater}>{ data.cater }</option>
                            ))}
                          </select>

                        <select style={{marginLeft:20}} className="custom-select " id="status-select"  onChange={(e)=>setAssessYears(e.target.value)}>
                          <option value=''>Year</option>
                         
                          <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                           
                          </select>



                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=>setViewCate(e.target.value)}>
                            
                            <option value=''>Category</option>
                            { category.map((data) =>(
                                    <option key={data.id} value={data.cate}>{ data.cate }</option>
                            ))}
                          </select>

                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=>srtSubject(e.target.value)}>
                          <option value=''>Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>


                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=> getAssessmentData(e.target.value)}>
                          <option value=''>Subject</option>
                           
                            {  searchSubj === null ? <option value='' disabled>Not available</option> :
                            
                          searchSubj &&  searchSubj.map((data) =>(
                                  <option key={data.id} value={data.subject_id}>{ data.subject+' ('+data.lang+')' }</option>
                            ))}
                          </select>



                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=>setAssessID(e.target.value)}>
                          <option value=''>Assessment</option>
                          { loadAssessData === null ? loadAss :
                             loadAssessData && loadAssessData.map(({assessment,invigilator_id},index)=>{
                                  return (
                                       <option key={index} value={invigilator_id}>{ assessment }</option>
                                  )
                             })
                          }
                           
                          </select>

                               
                          { loader !== false ?
                            <div class="d-flex" style={{marginLeft:10}}>
                                            <div class="spinner-border" role="status"></div>
                                        </div>
                                        : null }

                            </div>
                           
                     


                                      
                      </form>
                   </div>
                   
                    <div className="col-lg-4">
                      <div className="text-lg-right mt-3 mt-lg-0">
                      <button type="button" style={{marginLeft:10}} onClick={()=> viewAllAssessmentCategory()}  className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-eye mr-1" /> View Summary</button>
                     
                      </div>


                   
                    </div>{/* end col*/}
                 
                           
                  </div> {/* end row */}
             
                                 
                </div> {/* end card-box */}
              </div> {/* end col*/}
           
           
            </div>

      

            <div className="row">
              <div className="col-12">
                          { notify === null ? null : notify }

                          { loadAssessment === null ? null :
            <div>
                                     <div className="card">
                                         <div className="card-body">
                                           
                                             { gradeRstatus !== false ?
                                                  viewAssData !== null ?
                                                    <div>
                                                      { showDt()}
                                                       <button type="button" style={{float:'right'}} onClick={()=> setGradeRStatus(false)} className="btn btn-blue waves-effect btn-sm waves-light"> Back to All Assessment</button>
                                                 { HTMLReactParser(viewAssData) }
                                                  </div>

                                                 :
                                                 <div className='alert alert-info text-center'>No data found for this learner</div>
                                                 :
                                                 <div className="table-responsive">
                                 
                                                 <table className="table table-centered table-nowrap table-striped" id="products-datatable">
                                                     <thead>
                                                     <tr>
                                                         <th style={{width: '20px'}}>
                                                         #
                                                         </th>
                                                         <th>Student</th>
                                                         <th>Subject</th>
                                                        
                                                         <th>Location</th>
                                                         <th>Assessment</th>
                                                         <th>Mark</th>
                                                      </tr>
                                                     </thead>
                                                     <tbody>
                                                        { loadAssessment && loadAssessment.map(({student_id,studentName,cate, overall_mark,invigilator_id,subject_name,subject_id},index)=>{
                                                            return(
                                                               <tr>
                                                                 <td>{ index+1 }</td>
                                                                 <td>{ studentName }</td>
                                                                 <td>{ subject }</td>
                                                                
                                                                 <td>{ location }</td>
                                                                 <td>{ cate }</td>
                                                                 <td >
                                                                 <button type="button" className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=>handleData(studentName,subject_name,grade,subject_id,invigilator_id,student_id)}>View Mark</button>
                                                                 </td>
                                                               </tr>
                                                            )
                                                        })}
                                                     </tbody>
                                                 </table>
                                 
                                             </div>
                                            
                                            } 
                                       
                                 
                                         </div> {/* end card-body*/}
                                     </div> {/* end card*/}
                                 </div>
                                        
                          }
            </div>
            </div>

            </div>
            </div>
        </div>
            
        </div>
    )
}

export default AssessmentSummary
