import React,{useEffect, useState} from 'react'
import axios from 'axios'
import $ from 'jquery';

function Uploadquestion() {

    const [loaderSubject, setLoaderSubject] = useState(false);
    const [notifyAdd, setNotifyAdd] = useState(null);
    const [msubject, setMsubject] = useState('');
    const [categoryType, setCategoryType] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [questionEng, setQuestionEng] = useState('');
    const [questionAfr, setQuestionAfr] = useState('');
    const [memoEng, setMemoEng] = useState('');
    const [memoAfr, setMemoAfr] = useState('');

    const [subcate, setSubCate] = useState('')
    const [assessment, setAssessment] = useState('');
    const [subjectSrch, setSubjectSrch] = useState(null);
    const [viewGrade, setViewGrade] = useState('');
    const [allowForm, setAllowForm] = useState(false);
 
    const [subjectName,setSubjectName] = useState();
    const [year, setYear] = useState();
  
    const [langer, setLanger] = useState();
 

    const checkImage =() =>{
       setAllowForm(true)
    }
   
    const category = [
        {cate:'Task',id:2},
       {cate:'Test',id:1},
      {cate:'Assignment',id:3},
      {cate:'Examination',id:4},
      {cate:'Project',id:5},
      {cate:'Practical',id:6}  
];

const ucate = [
  {cater:'Term 1',id:2},
 {cater:'Term 2',id:1},
{cater:'Term 3',id:3},
{cater:'Term 4',id:4},
];

const gradeData = [
   {grade:'R',id:1},
  {grade:'1',id:2},
  {grade:'2',id:3},
  {grade:'3',id:4},
  {grade:'4',id:5},
  {grade:'5',id:6},
  {grade:'6',id:7},
  {grade:'7',id:8},
  {grade:'8',id:9},
  {grade:'9',id:10},
  {grade:'10',id:11},
  {grade:'11',id:12},
  {grade:'12',id:13}   
];


const getDownloadSubject = (subID)=>{
      
    axios.get('/update-user-subject/'+subID,{
      headers:{ 'Content-Type': 'application/json'}
    }).then((res)=>{
          setSubjectName(res.data.gradeData[0].subject);

          
    })
  }


const doSubject = (subID) =>{
    setMsubject(subID);
    getDownloadSubject(subID)
  }

const lookSubjectLag = (lid) =>{
    setLanger(lid)
    axios.get('/admin-get-subjectLang/'+lid+'/'+viewGrade,{
     headers:{ 'Content-Type': 'application/json'}
    }).then((res)=>{
           if(res.data.subData === false){
               setSubjectSrch(null)
           }else
           {
            setSubjectSrch(res.data.subData);
           }
    })
    
  }




  const createDownload = (e) =>{
    e.preventDefault();
     
             
        
    if(viewGrade === '' || msubject === '' || categoryType===''
    || dueDate==='' || subcate==='' || assessment===''){
      setNotifyAdd(`<div className='alert alert-info text-center'>Please check if some form input are missing</div>`);
    }
    else{ 
            setNotifyAdd(null)
                  setLoaderSubject(true);
  
                  let questions = document.getElementById('quest-eng').files[0];

      setNotifyAdd(null)
      setLoaderSubject(true);
      const config = {

        headers: {
  
          'content-type': 'multipart/form-data',
  
        },
  
      };
     let formData = new FormData();
     formData.append("year",year);
     formData.append("grade",viewGrade);
     formData.append("subjectid",msubject);
     formData.append("category",categoryType);
     formData.append("dueDate",dueDate);
     formData.append("file",questions);
     formData.append("subcate",subcate);
     formData.append("assessment",assessment);
     formData.append("subject",subjectName);
     formData.append("lang",langer);
     axios.post('/questupl',formData,config)
     .then((response)=>{
      
            setLoaderSubject(false);
                if(response.data.status === false){
                  setNotifyAdd(<div className='alert alert-info text-center'>Unable to process your upload at this moment</div>);
                }else
                {
                  setNotifyAdd(<div className='alert alert-success text-center'>{ response.data.status }</div>);
                  document.getElementById('clearmyform').reset();
                }
      
              console.log(response);
     }).catch((err)=>{
          console.log(err);
     })

    }
       
  }
  
   
  return (
    <div id="wrapper">
    <div className="content-page">
    <div className="content">

      
        <div className="container-fluid">

         
            <div className="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">Invigilator Upload</li>
                                <li class="breadcrumb-item">Upload</li>
                               
                            </ol>
                        </div>
                        <h4 class="page-title">Invigilator Upload</h4>
                    </div>
                </div>
            </div>

            <div className="row">
            <div className="col-lg-3">
                </div>
            <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-3 header-title">Invigilator Upload</h4>
            <form id='clearmyform' onSubmit={createDownload} enctype="multipart/form-data">
            <div className="modal-body p-4">

              { notifyAdd !== null ? notifyAdd : null }



              <div className="row">
            

              <div className="col-md-8">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Upload Your English or Africans Question</label>
                <input type="file" id="quest-eng" class="form-control-file" onChange={()=>checkImage()}
                 accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                   
                 />
              </div>
            </div>


            

            <div className="col-md-6">
             
            </div>
            </div>


            { allowForm === false ? null : 
         
           <div className='row'>
           <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Assessment </label>
                <input type='text' className="form-control" id="field-7" placeholder="Assessment" onChange={(e)=> setAssessment(e.target.value)}  />
              </div>
            </div>



          <div className="col-md-6">
              <div className="form-group no-margin">
              <label htmlFor="field-3" className="control-label">Year</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setYear(e.target.value)} >
                            <option selected>Year</option>
                            <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                          </select>
              </div>
          




          </div>

                
        

       

              
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Upload Type</label>
                <select className="custom-select " id="status-select" onChange={(e) => setCategoryType(e.target.value) } >
                            <option selected>Upload Category</option>
                            { category.map((data) =>(
                                    <option key={data.id} value={data.cate}>{ data.cate }</option>
                            ))}
                          </select>
              </div>
            </div>

            
            <div className="col-md-6">
              <div className="form-group no-margin">
              <label htmlFor="field-3" className="control-label">Select Current Term</label>
                <select className="custom-select " id="status-select" onChange={(e)=> setSubCate(e.target.value)} >
                            <option selected>Select</option>
                            { 
                              
                             ucate.map((data) =>(
                                    <option key={data.id} value={data.cater}>{ data.cater }</option>
                            ))}
                          </select>
              </div>
            </div>


            
          




         
          <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Select Grade</label>
                <select className="custom-select " id="status-select" onChange={(e) => setViewGrade(e.target.value) } >
                            <option selected>Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>
              </div>
            </div>


            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="field-3" className="control-label">Language of Instruct</label>
                <select className="custom-select " id="status-select" onChange={(e) => lookSubjectLag(e.target.value) } >
                            <option value="">Select Language</option>
                              <option value="Afrikaans">Afrikaans</option>
                              <option value="English">English</option>
                          </select>
              </div>
            </div>




            <div className="col-md-6">
              <div className="form-group no-margin">
              <label htmlFor="field-3" className="control-label">Select Subject</label>
                <select className="custom-select " id="status-select" onChange={(e)=> doSubject(e.target.value)} >
                            <option selected>Subject</option>
                            {  subjectSrch === null ? <option value='' disabled>Not available</option> :
                            
                            subjectSrch &&  subjectSrch.map((data) =>(
                                    <option key={data.id} value={data.subject_id}>{ data.subject }</option>
                              ))}
                          </select>
              </div>
            </div>



            <div className="col-md-6">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Due Date</label>
                <input type='date' className="form-control" id="field-7" placeholder="Due Date" onChange={(e)=> setDueDate(e.target.value)}  />
              </div>
            </div>

            </div>
           

                            }
    
          

        
         
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
          { loaderSubject === false ?
          <button type="submit" className="btn btn-info waves-effect waves-light">Submit</button>
          :
          <button class="btn btn-primary" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                        Please wait...
                                                    </button> 
}
        </div>
        </form>
        
          </div> {/* end card-body*/}
        </div> {/* end card*/}
      </div>

      <div className="col-lg-3">
                </div>
      {/* end col */}

                </div>


            </div>
     </div>
     </div>
     </div>
           
  )
}

export default Uploadquestion