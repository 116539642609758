import axios from 'axios'
import React,{useState, useEffect} from 'react'
import MaterialTable from '@material-table/core'

function PaidInvoice() {
    const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    
            const [idata, setIdata] = useState(null);
            const [notify, setNotify] = useState(null)

            const [loadInvoice, setLoadInvoice] = useState();
            const [invoiceID, setInvoiceId] = useState();
            const [amount, setAmount] = useState();
            const [billyCycle, setBillCycle] = useState();
            const [paymentStatus, setPaymentStatus] = useState();
            const [yearApply, setYearApply] = useState();
            const [parentName, setParentName] = useState();
            const [studentName, setStudentName] = useState();
            const [parentAddress, setParentAddress] = useState();
            const [parentEmail, setParentEmail] = useState();
            const [parentPhone, setParentPhone] = useState();
            const [date_created, setDateCreated] = useState();
            const [studentGrade, setStudentGrade] = useState();
            

            const [status, setStatus] = useState(false);
            

        const handleInvoice = (invliceID,amount,billcycle,payStatus,yearApply,userid,studentID,dateCreate)=>{
                            setInvoiceId(invliceID);
                            setAmount(amount);
                            setBillCycle(billcycle);
                            setPaymentStatus(payStatus);
                            setYearApply(yearApply);
                            setStatus(true)
                            setDateCreated(dateCreate)
                            getParentStudent(userid,studentID);
        }


        const getParentStudent = (userid,studentID) => {
                  axios.get('/admin-getstudent-parent/'+userid+'/'+studentID,{
                    headers: { 'Content-Type': 'application/json'}
                  }).then((res)=>{
                        setParentName(res.data.parentdetails[0].fnames);
                        setStudentName(res.data.studentDetails[0].firstname+" "+res.data.studentDetails[0].lastname);
                        setParentAddress(res.data.parentdetails[0].address);
                        setParentEmail(res.data.parentdetails[0].email);
                        setParentPhone(res.data.parentdetails[0].phone);
                        setStudentGrade(res.data.studentDetails[0].grade)
                  });     
        }

        const handleBacktoInvoice = () =>{
            setStatus(false)
        }
      
        

        useEffect(()=>{
              axios.get('/admin-student-invoice/Paid',{
                headers: { 'Content-Type': 'application/json'}
              }).then((res)=>{
                    if(res.data.invoiceData === false){
                            setNotify(<div className="alert alert-info text-center">No data available</div>);
                             setIdata(null);
                    }else
                    {
                            setIdata(res.data.invoiceData)
                    }
              })
        })
    return (
        <div id="wrapper">
        <div className="content-page">
    <div className="content">
    <div className="container-fluid">

    <div>
        {/* start page title */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">FastExcellence</li>
                 
                  <li className="breadcrumb-item active">Invoice</li>
                </ol>
              </div>
              <h4 className="page-title">Paid Invoice</h4>
            </div>
          </div>
        </div>     
        {/* end page title */} 
        { status === false ?
        <div className="row">
          <div className="col-12">
             
                { notify === null ? null : notify }
                  { idata === null ? null : 
              
            
                <MaterialTable
            title="All Paid Invoice"
            columns={[
              { title: 'Invoice ID', field: 'invoice_id' },
              { title: 'Billing Cycle', field: 'bill_cycle' },
              { title: 'Payment Status', field: 'payment_status' },
              { title: 'Duration', field: 'applying_year' },
            

              
            ]}
            data= {
              idata
            }


            actions={[
              {
                icon:'visibility',
                tooltip: 'View',
                onClick: (event, rowData) => handleInvoice(rowData.invoice_id,rowData.amount,rowData.bill_cycle,rowData.payment_status,rowData.applying_year,rowData.userid,rowData.student_id,rowData.date_created)
              },
             
]}
options={{
  actionsColumnIndex: -1




}}


/>
               
                  
                  }
               
          
       
         
          </div> {/* end col */}
        </div>
        :


        <div className="row">
        <div className="col-12">
          <div className="card-box">
            {/* Logo & title */}
            <div className="clearfix">
              <div className="float-left">
                <div className="auth-logo">
                  <div className="logo logo-dark">
                    <span className="logo-lg">
                      <img src="../assets/images/eq4.png" alt="" height={40} />
                    </span>
                  </div>
                  <div className="logo logo-light">
                    <span className="logo-lg">
                      <img src="../assets/images/eq4.png" alt="" height={40} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="float-right">
                <h4 className="m-0 d-print-none">Invoice</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mt-3">
                  <p><b>Invoice to { parentName }</b></p>
                 
                </div>
              </div>{/* end col */}
              <div className="col-md-4 offset-md-2">
                <div className="mt-3 float-right">
                  <p className="m-b-10"><strong>Order Date : </strong> <span className="float-right"> &nbsp;&nbsp;&nbsp;&nbsp; { date_created }</span></p>
                  <p className="m-b-10"><strong>Order Status : </strong> <span className="float-right"> <span className="badge badge-success">{ paymentStatus } </span></span></p>
                  <p className="m-b-10"><strong>Order No. : </strong> <span className="float-right"> { invoiceID } </span></p>
                </div>
              </div>{/* end col */}
            </div>
            {/* end row */}
            <div className="row mt-3">
              <div className="col-sm-6">
                <h6>Billing Address</h6>
                <address>
                   { parentAddress }<br></br>
                  <abbr title="Phone">P:</abbr> { parentPhone }
                </address>
              </div> {/* end col */}
              <div className="col-sm-6">
              <h6>Student Details</h6>
              <p>Name: { studentName }</p>
              <p>Grade: { studentGrade }</p>
              </div> {/* end col */}
            </div> 
            {/* end row */}
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table mt-4 table-centered">
                    <thead>
                      <tr><th>Year Applying</th>
                        <th>Biiling Cycle</th>
                        <th style={{width: '10%'}}></th>
                        <th style={{width: '10%'}}>Fee</th>
                        <th style={{width: '10%'}} className="text-right">Total</th>
                      </tr></thead>
                    <tbody>
                      <tr>
                        <td>{ yearApply } </td>
                        <td>
                         { billyCycle }
                        </td>
                        <td></td>
                        <td>{ convertCurrency(parseInt(amount)) }</td>
                        <td className="text-right">{ convertCurrency(parseInt(amount)) }</td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div> {/* end table-responsive */}
              </div> {/* end col */}
            </div>
            {/* end row */}
            <div className="row">
              <div className="col-sm-6">
                <div className="clearfix pt-5">
               
                </div>
              </div> {/* end col */}
              <div className="col-sm-6">
                <div className="float-right">
                  <p><b>Sub-total:</b> &nbsp;&nbsp; <span className="float-right">{ convertCurrency(parseInt(amount)) }</span></p>
                  
                  <h3>{ convertCurrency(parseInt(amount)) }</h3>
                </div>
                <div className="clearfix" />
              </div> {/* end col */}
            </div>
            {/* end row */}
            <div className="mt-4 mb-1">
              <div className="text-right d-print-none">
              <div className="btn btn-primary waves-effect waves-light" onClick={()=>handleBacktoInvoice()}> Back to Invioce</div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="javascript:window.print()" className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-printer mr-1" /> Print</a>
               
              </div>
            </div>
          </div> {/* end card-box */}
        </div> {/* end col */}
      </div>

         }
       
       {/* end row */}
      </div>


        </div>
        </div>
        </div>   
        </div>
    )
}

export default PaidInvoice
