import axios from 'axios'
import React, { useState, useEffect} from 'react'
import Login from './Login'
import Register from './Register'


function AuthAdmin({checkAuth}) {
    axios.defaults.withCredentials = true;
    const [utype, setUtype] = useState(false);
    const siteName = 'FastExcellence';


    useEffect(()=>{
            axios.get('/admin-authenticate-check',{
                headers: { 'Content-Type': 'application/json'}
            }).then((response)=>{
                        if(response.data.allowAdmin==='Login'){
                                    setUtype(true);
                        }else
                        {
                            setUtype(false);
                        }
            })
    },[])

    const checkMyLog = (authcheck) => {
                    setUtype(authcheck)
    }

    return (
        <div>
            { utype === false ? 
              <Register siteFoot={siteName} checkAdminLog={checkMyLog} />
              :
               <Login siteFoot={siteName} checkAdminLog={checkAuth} />
                }
        </div>
    )
}

export default AuthAdmin
