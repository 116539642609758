import React,{useState, useEffect} from 'react'
import axios from 'axios'
import MaterialTable from '@material-table/core'



function GenerateInvoice() {

    const [status, setStatus] = useState(null);
    const [parentData, setParentData] = useState();
    const [parentID, setParentID] = useState();
    const [viewInvoice, setInvoice] = useState(null);
    const [noticeInvoice, setNoticeInvloce] = useState(null);
    const [invoiceStatus, setInvoiceStatus] = useState(false);
    const [parentName, setParentName] = useState();
    const [studentName, setStudentName] = useState();
    const [studGrade, setStudGrade] = useState();
    const [studLang, setStudLang] = useState();
    const [studDatas, setStudDatas] = useState(null);
    const [notifyAdmin, setNotifyAdmin] = useState(null);
    const [mGrade,setMGrade] = useState();
    const [studID, setStudID] = useState();
    const [mprice, setMPrice] = useState();
    const [yPrice, setYprice] = useState();
    const [gradeRPrice, setGradeRPrice] = useState();
    const [openPrice, setOpenPrice] = useState(false);
    const [generalPrice, setGeneralPrice] = useState('');
    const [prevBill, setPrevBill] = useState('');
    const [packageN, setPackageN] = useState();
    const [billCyle, setBillCycle] = useState();
    const [selectBill, setSelectBill] = useState('');
    const [wallet, setWallet] = useState('');
    const [openWallet, setOpenWallet] = useState(false);
    const [gentFee, setGentFee] = useState('');
    const [newPackageName, setNewPackageName] = useState();
    const [operateType, setOperateType] = useState('');
    const [packID, setPackID] = useState();
    const [year, setYear] = useState();
    const [previewInvoice,setPreviewInvoice] = useState(false);

    const [invoiceID, setInvoiceId] = useState();
    const [amount, setAmount] = useState();

    const [paymentStatus, setPaymentStatus] = useState();
    const [yearApply, setYearApply] = useState();
    
 
    const [parentAddress, setParentAddress] = useState();
    const [parentEmail, setParentEmail] = useState();
    const [parentPhone, setParentPhone] = useState();
    const [date_created, setDateCreated] = useState();
    const [studentGrade, setStudentGrade] = useState();
    const [editPreview, setEditPreview] = useState(false);



    const convertCurrency = (num) =>{
        return 'R' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }


      const handleNav = () =>{
        setPreviewInvoice(false);
        setInvoiceStatus(false)
      }

    const userInvoice = (pid,parentName,wallet) =>{
            setParentName(parentName);
            setParentID(pid);
           
              if(wallet){
                setWallet(wallet)
              }else{
                setWallet(0)
              }
        setInvoiceStatus(true)
        axios.get('/get-admin-parent-invoice/'+pid,{
            headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
                if(res.data.invoiceData === false){
                        setNoticeInvloce(<div className='alert alert-info text-center'>This user does not have invoice </div>)
                        setInvoice(null)
                }else{
                          setNoticeInvloce(null)
                        setInvoice(res.data.invoiceData);

                }

                if(res.data.studData === false){
                        setStudDatas(null)
                }else{
                        setStudDatas(res.data.studData);

                }
        })

    }

    const createInvoice = () =>{
          
            let mdebit;

            if(generalPrice === '' || selectBill === '' || prevBill==='' || gentFee === ''){
                setNotifyAdmin(<div className='alert alert-info text-center'>Some options are not selected. Kindly select the next available options</div>)
            }else{
                  
                  
                if(operateType === 'debit'){
                               let gprice = parseInt(generalPrice);
                                let uwallet = parseInt(wallet);
                      if(gprice > uwallet){
                          setNotifyAdmin(<div className='alert alert-info text-center'>Unable to debit this user due to insufficient fund</div>)
                      }else{
                        mdebit = uwallet - gprice;
                            setWallet(mdebit)
                        let iData = {billCyle:billCyle,selectBill:selectBill,
                            parentID:parentID,studID:studID,grade:mGrade,
                            newPackageName:newPackageName,generalPrice:generalPrice,balance:mdebit,invoiceOperate:operateType,packID:packID,year:year}
                              alert(year);

                        axios.post('/admin-generateuser-invoice',iData,{
                            headers: { 'Content-Type': 'application/json'}
                        }).then((res)=>{
                                if(res.data.status === false){
                                    setNotifyAdmin(<div className='alert alert-danger text-center'>{res.data.report }</div>)
                                }else{
                                    setNotifyAdmin(<div className='alert alert-success text-center'>{res.data.report }</div>)
                                    userInvoice(parentID,parentName,wallet)
                                }
                        })
                        
                      }
                    
                      
                       
               }else{
                
                let iData = {billCyle:billCyle,selectBill:selectBill,
                    parentID:parentID,studID:studID,grade:mGrade,
                    newPackageName:newPackageName,generalPrice:generalPrice,balance:'none',invoiceOperate:operateType,packID:packID,year:year}
                  
                   
                axios.post('/admin-generateuser-invoice',iData,{
                    headers: { 'Content-Type': 'application/json'}
                }).then((res)=>{

                    if(res.data.status === false){
                        setNotifyAdmin(<div className='alert alert-danger text-center'>{res.data.report }</div>)
                    }else{
                        setNotifyAdmin(<div className='alert alert-success text-center'>{res.data.report }</div>);

                        userInvoice(parentID,parentName,wallet)
                    }
        
                })

                     
               }
            }
       
     


            
    }

    const removeInvoice = (invoiceID) =>{
          axios.post('/remove-invoice-data',{id:invoiceID},{
            headers: { 'Content-Type': 'application/json'}
          }).then((res)=>{
                setNoticeInvloce(<div className='alert alert-info text-center'>{res.data.istatus}</div>)
                userInvoice(parentID,parentName,wallet)
          })
    }

  


    const handleInvoice = (invoice_id,amount,bill_cycle,payment_status,applying_year,userid,student_id,date_created) => {

        setInvoiceId(invoice_id);
        setAmount(amount);
        setBillCycle(bill_cycle);
        setPaymentStatus(payment_status);
        setYearApply(applying_year);
        setPreviewInvoice(true)
        setDateCreated(date_created)
        getParentStudent(userid,student_id);


    }

    const getParentStudent = (userid,studentID) => {
        axios.get('/admin-getstudent-parent/'+userid+'/'+studentID,{
          headers: { 'Content-Type': 'application/json'}
        }).then((res)=>{
              setParentName(res.data.parentdetails[0].fnames);
              setStudentName(res.data.studentDetails[0].firstname+" "+res.data.studentDetails[0].lastname);
              setParentAddress(res.data.parentdetails[0].address);
              setParentEmail(res.data.parentdetails[0].email);
              setParentPhone(res.data.parentdetails[0].phone);
              setStudentGrade(res.data.studentDetails[0].grade)
        });     
}


    const generatePay = (studID,grade,money,packageN,bill) => {
                setBillCycle(bill)
                setMGrade(grade);
                setStudID(studID);
                setPrevBill(money)
                setPackageN(packageN)
            axios.get('/get-price-learner-grade/'+grade,{
                headers: { 'Content-Type': 'application/json'}
            }).then((res)=>{
                  setOpenPrice(true)
                 if(grade === 'R'){
                     setGradeRPrice(res.data.priceData);
                 }else{
                    setMPrice(res.data.priceData[0].term);
                    setYprice(res.data.priceData[0].year);
                 }
            })
    }


    const gradeRprice = () =>{
            return(
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Package Name</th>
                            <th>Package Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        { gradeRPrice && gradeRPrice.map(({r_package,price,pid},index)=>{
                              return (
                                  <tr key={index}>
                                      <td><input type='radio' name='price' onClick={()=> calculateOverall(price,r_package,pid)} value={price} /></td>
                                      <td>{r_package}</td>
                                      <td>{price}</td>
                                  </tr>
                              )
                        })}
                        <tr>

                        </tr>
                    </tbody>
                </table>
            )
    }

    const calculateOverall = (price,packName,pid) =>{
        setGentFee('ueueue')
        setPackID(pid)
        let fee = parseInt(price);
        let prev = parseInt(prevBill);
                    if(mGrade === 'R'){
                        setSelectBill('none')
                        setBillCycle('Year')
                        setNewPackageName(packName)
                           if(packName === packageN){
                              setGeneralPrice(prevBill)
                           }else
                           {
                                
                               axios.get('/packge-data-grade-r/'+packageN,{
                                headers: { 'Content-Type': 'application/json'}
                               }).then((res)=>{
                                        let pri = parseInt(res.data.packResult[0].price);
                                         let mtot = prev - pri
                                          let total = mtot + fee;
                                          setGeneralPrice(total)
                               })

                           }
                            

                    }else{

                        setNewPackageName('none');
                        let mp = parseInt(mprice);
                        let yp = parseInt(yPrice);
                        let gh, mk;

                        if(billCyle === 'Monthly'){

                               gh = prev - mp;         

                        }else{

                                gh = prev - yp;
                        }

                        if(fee === mp){
                             mk = fee + gh;
                            
                            setSelectBill('Monthly');
                        }else{
                            mk = fee + gh;
                            setSelectBill('Year');
                        }

                        setGeneralPrice(mk)
                      
                        

                    }
              

                
    }

    const operationStatus = (invoice) =>{
                if(invoice === ''){
                    
                }else{
                      setOperateType(invoice)
                   
                    switch(invoice){
                          case 'debit':
                            setNotifyAdmin(null)
                            setOpenWallet(true)
                              break;
                          case 'allow-pay':
                            setOpenWallet(false)
                            setNotifyAdmin(<div className='alert alert-info text-center'>Student account will be disable due to change of billing &amp; will be activated after payment has been made by { parentName }</div>)
                
                               break;
                    }

                }
               
            
    }




    const gradeHprice = () =>{
        return(
            <table class="table mb-0">
                <tbody>
                    <tr>
                        
                        <th>Term</th>
                        <td>{mprice}</td>
                        <td><input type='radio' name='price' value={mprice} onClick={()=> calculateOverall(mprice,'none')} /></td>
                    </tr>

                    <tr>
                        <th>Year</th>
                        <td>{yPrice}</td>
                        <td><input type='radio' name='price' value={yPrice} onClick={()=> calculateOverall(yPrice,'none')} /></td>
                    </tr>
                </tbody>
            </table>
        )
    }

        
     useEffect(()=>{
          axios.get('/register-parent',{
            headers: { 'Content-Type': 'application/json'}
          })
          .then((response)=>{
               if(response.data.tutorDetails === false){
                    setStatus(<div className="alert alert-info text-center">No Parent Found</div>);
               }else{
                   console.log(response.data.tutorDetails)
                      setParentData(response.data.tutorDetails);
               }
          })
     },[])

     const handleBacktoInvoice = () =>{
          setPreviewInvoice(false)
     }

     const invoicePreview = () =>{
         return(
            <div className="col-12">
            <div className="card-box" id='invoicePages'>
               
              {/* Logo & title */}
              <div className="clearfix">
                <div className="float-left">
                <div className="auth-logo">
                  <div className="logo logo-dark">
                    <span className="logo-lg">
                      <img src="../assets/images/eq4.png" alt="" height={40} />
                    </span>
                  </div>
                  <div className="logo logo-light">
                    <span className="logo-lg">
                      <img src="../assets/images/eq4.png" alt="" height={40} />
                    </span>
                  </div>
                </div>
                </div>
                <div className="float-right">
                  <h4 className="m-0 d-print-none">Invoice</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mt-3">
                    <p><b>Invoice to { parentName }</b></p>

                    <h4>Banking Details</h4>
                              <table className='table'>
                                 <tbody>
                                   <tr>
                                     <td><strong>Bank Name</strong></td>
                                     <td><strong>FNB</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Account Holder</strong></td>
                                     <td><strong>FastExcellence</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Account Number</strong></td>
                                     <td><strong>62 891 239 511</strong></td>
                                   </tr>
                                   <tr>
                                     <td><strong>Reference</strong></td>
                                     <td><strong>Learners Full name and Grade</strong></td>
                                   </tr>
                                 </tbody>
                                </table>
                                    
                   
                  </div>
                </div>{/* end col */}
                <div className="col-md-4 offset-md-2">
                  <div className="mt-3 float-right">
                    <p className="m-b-10"><strong>Order Date : </strong> <span className="float-right"> &nbsp;&nbsp;&nbsp;&nbsp; { date_created }</span></p>
                    <p className="m-b-10"><strong>Order Status : </strong> <span className="float-right">  { paymentStatus === 'Paid' ? <span className="badge badge-success" style={{fontSize:16}}>{ paymentStatus } </span> : <span className="badge badge-danger" style={{fontSize:16}}>{ paymentStatus } </span>}
                    
                    </span></p>
                    <p className="m-b-10"><strong>Order No. : </strong> <span className="float-right"> { invoiceID } </span></p>
                  </div>
                </div>{/* end col */}
              </div>
              {/* end row */}
              <div className="row mt-3">
                <div className="col-sm-6">
                  <h6>Billing Address</h6>
                  <address>
                     { parentAddress }<br></br>
                    <abbr title="Phone">P:</abbr> { parentPhone }
                  </address>
                </div> {/* end col */}
                <div className="col-sm-6">
                <h6>Student Details</h6>
                <p>Name: { studentName }</p>
                <p>Grade: { studentGrade }</p>
                </div> {/* end col */}
              </div> 
              {/* end row */}
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table mt-4 table-centered">
                      <thead>
                        <tr><th>Year Applying</th>
                          <th>Biiling Cycle</th>
                          <th style={{width: '10%'}}></th>
                          <th style={{width: '10%'}}>Fee</th>
                          <th style={{width: '10%'}} className="text-right">Total</th>
                        </tr></thead>
                      <tbody>
                        <tr>
                          <td>{ yearApply } </td>
                          <td>
                           { billCyle }
                          </td>
                          <td></td>
                          <td>{ convertCurrency(parseInt(amount)) }</td>
                          <td className="text-right">{ convertCurrency(parseInt(amount)) }</td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div> 
              </div>
              
             
              <div className="row">
                <div className="col-sm-6">
                  <div className="clearfix pt-5">
                  
                  </div>
                </div> 
                <div className="col-sm-6">
                  <div className="float-right">
                    <p><b>Sub-totals:</b> &nbsp;&nbsp; <span className="float-right">{ convertCurrency(parseInt(amount)) }</span></p>
                    
                    <h3>{ convertCurrency(parseInt(amount)) }</h3>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
            
             
              <div className="mt-4 mb-1">
                <div className="text-right d-print-none">
                <div className="btn btn-primary waves-effect waves-light" onClick={()=>handleBacktoInvoice()}> Back to Invioce</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                
                             
                  <a href="javascript:window.print()" className="btn btn-primary waves-effect waves-light"><i className="mdi mdi-printer mr-1" /> Print</a>
                 
                </div>
              </div>
            </div> {/* end card-box */}
          </div>
         )
     }
    return (
        <div id="wrapper">
            <div className="content-page">
        <div className="content">
          {/* Start Content*/}
          <div className="container-fluid" >
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Finance</li>
                     
                      <li className="breadcrumb-item active">Generate Invoice</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Generate Invoice</h4>
                </div>
              </div>
            </div>     
            {/* end page title */} 
            <div className="row">
              <div className="col-12">
             
                   
                
                      {
                      invoiceStatus !== false ?

                        <div>
                            <h4 className='text-uppercase' style={{textDecoration:'underline'}}>{ parentName } Invoice </h4>
                            {noticeInvoice === null ? null : noticeInvoice}
                            <div> 
                             
                              { invoiceStatus !== false ? <button onClick={()=> handleNav()} type="button" style={{float:'left'}} className="btn btn-blue waves-effect btn-sm waves-light"> Back to All users</button>
                            : null }
                            
                                { previewInvoice !== false ? null :
                            <button type="button" style={{float:'right'}} className="btn btn-blue waves-effect btn-sm waves-light" data-toggle="modal" data-target="#centermodal"> Generate Invoice</button>
                                }
                            <br></br><br></br>

                             <div className="modal fade" id="centermodal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">New Invoice for { parentName }</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
         
            <div className="modal-body">
            <div className='row'>
                <div className='col-lg-12'>
                               
                                <h4>Select Learner</h4>
                                <div class="table-responsive">

                                    { studDatas === null ? <div className='alert alert-info text-center'></div>
                                    :
                                        <table class="table mb-0">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Learners Name</th>
                                                <th>Grade</th>
                                                <th>Gender</th>
                                                <th>Money Previous Paid</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { studDatas && studDatas.map(({firstname,lastname,gender,grade,lang_intruct,student_id,money_to_pay,user_package,bill_cycle},index)=>{
                                                 return(
                                                <tr key={index}>
                                            <th scope="row"><input type='radio' name='studName' value={3} onClick={()=> generatePay(student_id,grade,money_to_pay,user_package,bill_cycle)} /></th>
                                                <td>{firstname+' '+lastname }</td>
                                                <td>{ grade }</td>
                                                <td>{ gender }</td>
                                                <td>{ money_to_pay }</td>
                                            </tr>
                                                 )
                                            })}
                                            
                                           
                                            </tbody>
                                        </table>
                                        }
                                    </div>
                    </div>
                     
                    <hr></hr>
                    { openPrice !== false ? 
                    <div className='col-lg-12'>
                          <h4>Select Price</h4>
                           <div class="table-responsive">
                                 { mGrade === 'R' ? gradeRprice() : gradeHprice() }

                               </div>


                                  
           

<div className="form-group">
  
  <label htmlFor="username">Total Amount To pay. (Price include number of subject cost)</label>
  <input className="form-control" type="number" id="username" onChange={(e)=>setGeneralPrice(e.target.value)} placeholder="" value={generalPrice}/>
 <small style={{color:'red'}}>Note: If you're generating invoice for a user, you can adjust the amount to fit the user requirement</small>
</div>

 { openWallet !== false ?
 <div>
     <br></br>
<table class="table mb-0">
    <thead>
    <tr class="table-info">
            <th>Wallet Balance</th>
            <td>{ convertCurrency(parseInt(wallet)) }</td>
        </tr>
    </thead>
    </table>
    <br></br>
 </div>
 : null
}

<div className="form-group">
  <label htmlFor="password"> Year Applying</label>
  <select className="form-control" type="password" id="password" placeholder="Enter your password" onChange={(e)=> setYear(e.target.value)}>
                            <option value=''>Year</option>
                             <option value={2020}>2020</option>
                             <option value={2021}>2021</option>
                             <option value={2022}>2022</option>
                             <option value={2023}>2023</option>
                             <option value={2024}>2024</option>
                             <option value={2025}>2025</option>
                             <option value={2026}>2026</option>
                             <option value={2027}>2027</option>
                             <option value={2028}>2028</option>
                             <option value={2029}>2029</option>
                             <option value={2030}>2030</option>
                             <option value={2031}>2031</option>
   
      </select>
</div>

<div className="form-group">
  <label htmlFor="password">Generating Invoice to</label>
  <select className="form-control" id="password" placeholder="Enter your password" onChange={(e)=> operationStatus(e.target.value)}>
      <option value=''>Select Option</option>
      <option value='debit'>Debit { parentName } from his/her wallet</option>
      <option value='allow-pay'>Allow { parentName } to pay for this invoice</option>
      <option value='already-paid'>{ parentName } has been debited already but no invoice</option>
    
      </select>
</div>



<div className="form-group text-center">
  <button className="btn btn-primary" onClick={()=>createInvoice()} >Generate</button>
</div>
<br></br>

{ notifyAdmin === null ? null : notifyAdmin}
                        </div>
                        : null
                
                }
                                        

             </div>
           


           

           
            </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}
                             </div>
                            

                            {viewInvoice === null ? null :

                            previewInvoice !== false ? 
                            invoicePreview()

                            :
                             
                          viewInvoice &&  <MaterialTable
                            title= "All Invoice"
                            columns={[
                              { title: 'Invoice ID', field: 'invoice_id' },
                              { title: 'Amount (R)', field: 'amount' },
                              { title: 'Year Applying', field: 'applying_year' },
                              { title: 'Payment Status', field: 'payment_status' },
                              { title: 'Date', field: 'date_created' },
                            ]}
                            data= {
                                viewInvoice
                            }
                
                
                            actions={[
                              {
                                icon:'visibility',
                                tooltip: 'View Invoice',
                                onClick: (event, rowData) => handleInvoice(rowData.invoice_id,rowData.amount,rowData.bill_cycle,rowData.payment_status,rowData.applying_year,rowData.userid,rowData.student_id,rowData.date_created)
                              },
                             
                              rowData => ({
                                icon: 'delete',
                                tooltip: 'Delete Invoice',
                                onClick: (event, rowData) => removeInvoice(rowData.invoice_id),
                               
                              })

                            ]}
                            options={{
                              actionsColumnIndex: -1
                            }}
                
                
                            />
                           

                            }

                        </div>


                      :
                      status !== null ? status : 
              
            parentData &&  <MaterialTable
            title="Select the user you want to generate invoice"
            columns={[
              { title: 'Names', field: 'fnames' },
              { title: 'Email', field: 'email' },
              { title: 'Phone', field: 'phone' },
              { title: 'ID Number', field: 'id_number' },
            ]}
            data= {
                parentData
            }


            actions={[
              {
                icon: 'library_add',
                tooltip: 'View User',
                onClick: (event, rowData) => userInvoice(rowData.userid,rowData.fnames,rowData.wallet)
              }
            ]}
            options={{
              actionsColumnIndex: -1
            }}


            />
                

}
                 
                    
                    {/*pagin*/}
                
              </div> {/* end col */}
            </div>
            {/* end row */}
          </div> {/* container */}
        </div> 
        </div>
            </div>
    )
}

export default GenerateInvoice
