import React, { useState} from 'react';
import axios from 'axios';
import download from 'downloadjs'

function UploadAssessment() {
    const [loader, setLoader] = useState(false);
    const [viewSubject, setViewSubject] = useState('');
    const [searchSubj, setSearchSubj] = useState(null);
    const [viewCate, setViewCate] = useState('');
    const [grade, setGrade] = useState('');
    const [assessment, setAssessment] = useState();
    const [notify, setNotify] = useState(null);
    const [status, setStatus] = useState(false);
    const [studentName, setStudentName] = useState();
    const [centreNames,setCentreName] = useState();
    const [subject, setSubject] = useState();
    const [year, setYears] = useState('');
    const [term, setTerm] = useState('');
    const [checkStatus, setCheckStatus] = useState();
    const [assessID, setAssessID] = useState();
    const [studentN, setStudentN] = useState();
    const [checkUpload, setCheckUpload] = useState();

  

    const ucate = [
      {cater:'Term 1',id:2},
     {cater:'Term 2',id:1},
    {cater:'Term 3',id:3},
    {cater:'Term 4',id:4},
    ];

    const gradeData = [
       {grade:'R',id:1},
      {grade:'1',id:3},
      {grade:'2',id:4},
      {grade:'3',id:5},
      {grade:'4',id:6},
      {grade:'5',id:7},
      {grade:'6',id:8},
      {grade:'7',id:9},
      {grade:'8',id:10},
      {grade:'9',id:11},
      {grade:'10',id:12},
      {grade:'11',id:13},
      {grade:'12',id:14}   
];

const category = [
    {cate:'Task',id:2},
   {cate:'Test',id:1},
  {cate:'Assignment',id:3},
  {cate:'Examination',id:4},
  {cate:'Project',id:5},
  {cate:'Practical',id:6}  
];


const srtSubject = (gradID) =>{
                setGrade(gradID);
axios.get('/get-subject-cate/'+gradID,{
     headers:{ 'Content-Type': 'application/json'}
}).then((response)=>{
       
      if(response.data.subjectData === 'not allow'){
          
              setSearchSubj(null) 
      }else
      {
            setSearchSubj(response.data.subjectData);
      }
})
}

const getcentreName = (centID) =>{
    axios.get('/centre-update-data/'+centID,{
     headers:{ 'Content-Type': 'application/json'}
    }).then((response)=>{
        setCentreName(response.data.tutorData[0].centreName);
        return response.data.tutorData[0].centreName;
            
    })
}


const viewUserAssessment = () =>{
      if(viewCate === '' || viewSubject === '' || term === '' || grade==='' || year===''){
        setNotify(<div className="alert alert-info text-center">Please select all categories before proceeding</div>)
        setStatus(false);
      }else{
            setLoader(true);
        axios.post('/get-users-assessment',{
             grade:grade,
             cate:viewCate,
             subjectID:viewSubject,
             term:term,
             year:year
        },{
            headers:{ 'Content-Type': 'application/json'}
        }
        ).then((response)=>{
                setTimeout(()=>{
                    setLoader(false);
                        if(response.data.assessmentData === 'empty'){
                                setNotify(<div className="alert alert-info text-center">No Assessment Found</div>)
                                 setStatus(false);
                        }else
                        {
                                setNotify(null);
                                setStatus(true);
                                setAssessment(response.data.assessmentData);
                                setSubject(response.data.subject);

                        }
                },2000);
        });
      }
}


const downloadData = (studName,downloadId) =>{
     
  axios.post('/view-download-question',{
    downloadID:downloadId
},{
  responseType: 'blob'
}

).then((response)=>{
  const content = response.headers['content-type'];
  download(response.data, studName+'.pdf', content)
  
  
})
             
 }

    return (
        <div id="wrapper">
    
    <div class="content-page">
                <div class="content">
         
        <div class="container-fluid">
                        
             {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><a href="javascript: void(0);">UBold</a></li>
                <li className="breadcrumb-item"><a href="javascript: void(0);">CRM</a></li>
                <li className="breadcrumb-item active">Customers</li>
              </ol>
            </div>
            <h4 className="page-title">Upload  &#38; Assessment view</h4>
          </div>
        </div>
      </div>     
      {/* end page title */}    

      <div className="row">
              <div className="col-12">
                <div className="card-box">
                  <div className="row">
                    <div className="col-lg-10">
                      <form className="form-inline">
                       
                        <div className="form-group mx-sm-3 ">

                        <select className="custom-select" id="status-select" onChange={(e)=>setViewCate(e.target.value)}>
                            <option value=''> Category</option>
                            { category.map((data) =>(
                                    <option key={data.id} value={data.cate}>{ data.cate }</option>
                            ))}
                          </select>



                        <select className="custom-select" id="status-select" style={{marginLeft:20}}  onChange={(e)=>setTerm(e.target.value)}>
                            <option value=''> Term</option>
                            { ucate.map((data) =>(
                                    <option key={data.id} value={data.cater}>{ data.cater }</option>
                            ))}
                          </select>

                        <select className="custom-select " id="status-select" style={{marginLeft:20}}   onChange={(e)=>setYears(e.target.value)}>
                          <option value=''>Year</option>
                             
                          <option value={2020}>2020</option>
                          <option value={2021}>2021</option>
                          <option value={2022}>2022</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                          <option value={2031}>2031</option>
                          </select>

                          
                          <select className="custom-select " id="status-select" style={{marginLeft:20}}  onChange={(e)=>srtSubject(e.target.value)}>
                            <option value=''>Grade</option>
                            { gradeData.map((data) =>(
                                    <option key={data.id} value={data.grade}>Grade { data.grade }</option>
                            ))}
                          </select>

                          <select className="custom-select " id="status-select" style={{marginLeft:20}} onChange={(e)=> setViewSubject(e.target.value)}>
                          <option value=''>Subject</option>
                           
                            {  searchSubj === null ? <option value='' disabled>Not available</option> :
                            
                          searchSubj &&  searchSubj.map((data) =>(
                                  <option key={data.id} value={data.subject_id}>{ data.subject+' ('+data.lang+')' }</option>
                            ))}
                          </select>

                          <button type="button" style={{marginLeft:10}} className="btn btn-primary waves-effect waves-light" onClick={()=>viewUserAssessment()}><i className="mdi mdi-eye mr-1" /> View Assessment</button>
                          { loader !== false ?
                            <div class="d-flex" style={{marginLeft:10}}>
                                            <div class="spinner-border" role="status"></div>
                                        </div>
                                        : null }
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-2">
                      <div className="text-lg-right mt-3 mt-lg-0">
                        
                        
                      </div>


                   
                    </div>{/* end col*/}
                  </div> {/* end row */}
                </div> {/* end card-box */}
              </div> {/* end col*/}
            </div>


       <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
               
               
              </div>

                { notify === null ? null : notify }
                 {status === false ? null : 
              <div className="table-responsive">
                <table className="table table-centered table-nowrap table-striped" id="products-datatable">
                  <thead>
                    <tr>
                      
                      <th>Leaners</th>
                    
                      <th>Upload</th>
                      <th>Upload date</th>
                      <th>Assessment</th>
                      <th style={{width: '85px'}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                     { assessment && assessment.map(({studentName,grade,tasktype,assess_upload_id,date_upload,upload,subject_name},index)=>{
                            return (
                                <tr key={index}>

                                <td className="table-user">
                                
                                  { studentName}
                                </td>
                              
                                <td>
                                 { upload === '' ?  <span className="badge bg-soft-danger text-danger">None</span> :  
                                 <span className="badge bg-soft-success text-success">Available</span> }
                                </td>
                                <td>
                                 { date_upload }
                                </td>
                                <td>
                                <td>Grade: {grade} - {subject_name } - {tasktype}</td>
                                </td>
                                <td>
                               
                                <div className="btn btn-blue waves-effect btn-sm waves-light" onClick={()=> downloadData(studentName,assess_upload_id)} >View Assessment </div>
                                 
                          

                                </td>
                              </tr>
                           
                            )
                     }
                                
                     )}
                   
                  </tbody>
                </table>
              </div>
                        }
            
           
            </div> {/* end card-body*/}
          </div> {/* end card*/}
        </div> {/* end col */}
      </div> 


      <div className="modal fade" id="uploadAssesment" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">Upload Assessment</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body p-4">

          <div className="row">
            <div className="col-md-12">
              <div className="form-group no-margin">
                <label htmlFor="field-7" className="control-label">Upload Assessment</label><br></br>
                <small style={{color:'red'}}>File Supported Format (PDF, MS WORDS, POWER POINT, EXCEL)</small>
                <input type="file" className="form-control" id="field-7"  />
              </div>
            </div>
          </div>
        
        

        
        
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
          
          <button type="button" className="btn btn-info waves-effect waves-light">Submit</button>
         
        </div>
          </div>{/* /.modal-content */}
        </div>{/* /.modal-dialog */}
      </div>{/* /.modal */}
   
 
        </div>
        </div>
        </div>
        </div>
    )
}

export default UploadAssessment
